import { Grid, Box, Typography, TextField, IconButton, FormControl, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import LoginIcon from '@mui/icons-material/Login';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import randomString from 'random-string';
import UrlParse from 'url-parse';
import { LoadingButton } from '@mui/lab';
import { renderErrorMessage } from '../../../components/ToastNotification';
import { getItem, setItem } from '../../../lib/cookiesManager';
import { joinWebianrCheck } from '../../../services/commonApis';
import jwt from 'jwt-decode';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { validateText } from '../../../utils/common';
import MeetLogoSvg from '../../../assets/svgs/webinarLogo';
import LoaderWithMessage from './LoaderWithMessage'
import { axiosPostCall } from '../../../services/apisCall';
import { makeStyles } from '@mui/styles';
import { FormControlLabel, Checkbox } from '@mui/material';


const useStyles = makeStyles({
    pulse: {
        animation: 'pulse 1.5s infinite',
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(1)',
            opacity: 1,
        },
        '50%': {
            transform: 'scale(1.2)',
            opacity: 0.7,
        },
        '100%': {
            transform: 'scale(1)',
            opacity: 1,
        },
    },
});

export default function JoinWebinar() {
    const [roomId, setRoomIdINput] = useState(randomString({ length: 8 }).toLowerCase());
    const [peerId,] = useState(randomString({ length: 8 }).toLowerCase());
    const [joinLoading, setJoinLoading] = useState(false);
    const [name, setName] = useState(getItem("name") || "");
    const [nameErr, setNameErr] = useState(false);
    const [updateToCookies, setUpdateToCookies] = useState(false);
    const [bId, setbId] = useState("");
    const [e2eKey, setE2eKey] = useState("");
    const [loaderMessage, setLoaderMessage] = useState("");
    const navigate = useNavigate();
    const [intervalId, setIntervalId] = useState(null);
    const [hasJoined, setHasJoined] = useState(false);
    const [micOn, setMicOn] = useState(true);
    const [cameraOn, setCameraOn] = useState(true);
    const [audioDevices, setAudioDevices] = useState([]);
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioOutputDevices, setspeakerDevices] = useState([]);
    const [selectedAudioDevice, setSelectedAudioDevice] = useState('');
    const [selectedVideoDevice, setSelectedVideoDevice] = useState('');
    const [selectedSpeakerDevice, setselectedSpeakerDevice] = useState('');
    const [currentStream, setCurrentStream] = useState(null);
    const classes = useStyles();
    const [hideScreen, setHideScreen] = useState(
        JSON.parse(localStorage.getItem("room"))?.meetJoin || false
    );
    const skipAutoJoin = useRef(false);




    // useEffect(() => {
    //     if (hideScreen) {
    //         joinWebinar();
    //     }
    //     // eslint-disable-next-line
    // }, [hideScreen]);




    const checkStatus = async () => {
        try {

            const response = await axiosPostCall("waitForStart", { roomId });

            if (!response) {
                throw new Error("Empty response received");
            }

            if (response.meetingStatus) {
                const { meetingStatus } = response;
                if (meetingStatus === 'active' && !hasJoined) {
                    try {
                        const { redirectURL, state } = await joinWebianrCheck(roomId, name, peerId);
                        if (redirectURL) {
                            setHasJoined(true);
                            clearInterval(intervalId);
                            navigate(redirectURL, { state });
                        } else {
                            renderErrorMessage("Invalid link!");
                        }
                    } catch (error) {
                        renderErrorMessage("Error joining webinar");
                    }
                }
            } else {
                throw new Error("Missing 'meetingStatus' property in the response");
            }
        } catch (error) {
            throw new Error("Error checking meeting status");

        }
    };

    const joinWebinar = async (e) => {
        if (e) e.preventDefault();
        setJoinLoading(true);

        try {
            if (updateToCookies) setItem("name", name)
            let token = getItem("token");
            let pId = null;
            let userName = name || "";
            if (token) {
                let info = jwt(token);
                pId = info?.email;
                if (!userName && info?.name) userName = info.name;
                setName(userName);
            }

            if (!userName) {
                setJoinLoading(false);
                return renderErrorMessage("Enter your name!");
            }
            if (!validateText(userName)) {
                setJoinLoading(false);
                setNameErr(true);
                return renderErrorMessage("Please enter a valid name!");
            }
            if (!pId) pId = peerId;
            if (bId) pId = bId;
            let { redirectURL, state, message } = await joinWebianrCheck(roomId, userName, pId) || {};
            console.log("API Response:", { redirectURL, state, message });
            if (message) {
                setLoaderMessage(message);
                setJoinLoading(false);
                // setInterval(checkStatus, 5000);
                const id = setInterval(checkStatus, 5000);
                setIntervalId(id);
                return;
            }
            if (redirectURL) {
                if (e2eKey) {
                    state.e2eKey = e2eKey;
                    redirectURL = redirectURL + `&key=${encodeURIComponent(e2eKey.toString())}`
                }

                if (state?.waitingRoom) return navigate("/waiting_room", { state, redirectURL });

                navigate(redirectURL, { state });
                return setJoinLoading(false)
            } else {
                setJoinLoading(false)
                return renderErrorMessage("Invalid link!")
            }

        } catch (error) {
            setJoinLoading(false)
            return renderErrorMessage(error?.message);
        }
    }

    // Change name on join webinar popup.
    const changeName = (value) => {
        setName(value);
        setUpdateToCookies(true)
    }

    // Redirect to login page.
    // const loginRedirect = () => {
    //     navigate('/login', { state: { peerId, name, roomId } });
    // }

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then((stream) => {
                const videoElement = document.getElementById('videoPreview');
                videoElement.srcObject = stream;
            })
            .catch((err) => console.error("Error accessing media devices:", err));
    }, []);


    // Get available audio and video devices
    /*   useEffect(() => {
          const getDevices = async () => {
              const devices = await navigator.mediaDevices.enumerateDevices();
              const audioDevices = devices.filter((device) => device.kind === 'audioinput');
              const videoDevices = devices.filter((device) => device.kind === 'videoinput');
              const audioOutputDevices = devices.filter((device) => device.kind === 'audiooutput');
  console.log("audioDevices",audioDevices)
  console.log("videoDevices",videoDevices)
  
              setAudioDevices(audioDevices);
              setVideoDevices(videoDevices);
              setspeakerDevices(audioOutputDevices);
  
  
      
      
      
              // Find the default video device (system camera)
              const defaultVideoDevice = videoDevices.find(device => device.deviceId === 'default' || device.label.toLowerCase().includes('integrated') || videoDevices[0]);
              const defaultAudioDevice = audioDevices.find(device => device.deviceId === 'default' || device.label.toLowerCase().includes('default') || audioDevices[0]);
              const defaultAudioOutputDevice = audioOutputDevices.find(device => device.deviceId === 'default' || device.label.toLocaleLowerCase().includes('default') || audioOutputDevices[0])
              if (defaultVideoDevice) {
                  setSelectedVideoDevice(defaultVideoDevice.deviceId);
              }
              if (defaultAudioDevice) {
                  setSelectedAudioDevice(defaultAudioDevice.deviceId);
              }
              if (defaultAudioOutputDevice) {
                  setselectedSpeakerDevice(defaultAudioOutputDevice.deviceId);
              }
          
  
  
          };
  
          getDevices();
    }, []);
   */

    useEffect(() => {
        const getDevices = async () => {
            try {
                // Request user media to ensure permission is granted
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

                // Once permission is granted, enumerate devices
                const devices = await navigator.mediaDevices.enumerateDevices();
                const audioDevices = devices.filter((device) => device.kind === 'audioinput');
                const videoDevices = devices.filter((device) => device.kind === 'videoinput');
                const audioOutputDevices = devices.filter((device) => device.kind === 'audiooutput');


                // Update state with available devices
                setAudioDevices(audioDevices);
                setVideoDevices(videoDevices);
                setspeakerDevices(audioOutputDevices);

                // Find the default video device (system camera)
                const defaultVideoDevice = videoDevices.find(device => device.deviceId === 'default' || device.label.toLowerCase().includes('integrated') || videoDevices[0]);
                const defaultAudioDevice = audioDevices.find(device => device.deviceId === 'default' || device.label.toLowerCase().includes('default') || audioDevices[0]);
                const defaultAudioOutputDevice = audioOutputDevices.find(device => device.deviceId === 'default' || device.label.toLocaleLowerCase().includes('default') || audioOutputDevices[0])
                if (defaultVideoDevice) {
                    setSelectedVideoDevice(defaultVideoDevice.deviceId);
                }
                if (defaultAudioDevice) {
                    setSelectedAudioDevice(defaultAudioDevice.deviceId);
                }
                if (defaultAudioOutputDevice) {
                    setselectedSpeakerDevice(defaultAudioOutputDevice.deviceId);
                }

                // Optionally, you can stop the media stream to free resources
                stream.getTracks().forEach(track => track.stop());
            } catch (error) {
                console.error('Error requesting media devices:', error);
            }
        };

        // Initial device request and enumeration
        getDevices();

        // Add device change listener to handle dynamic updates
        navigator.mediaDevices.addEventListener('devicechange', getDevices);

        // Cleanup the listener on component unmount
        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', getDevices);
        };
    }, []);


    useEffect(() => {
        // Whenever devices change, force a render to reflect the latest devices
        if (audioDevices.length || videoDevices.length || audioOutputDevices.length) {
        }
    }, [audioDevices, videoDevices, audioOutputDevices]);



    useEffect(() => {
        if (selectedVideoDevice) {
            startCamera(); // Ensure camera starts if device changes
        }
        // eslint-disable-next-line
    }, [selectedVideoDevice]);

    useEffect(() => {
        return () => {
            if (currentStream) {
                currentStream.getTracks().forEach(track => track.stop());
            }
        };
    }, [currentStream]);

    // Update the video device without turning the camera on unless it's already on
    useEffect(() => {
        const updateVideoDevice = async () => {
            if (selectedVideoDevice) {
                // Check if camera is on before starting the new video stream
                if (cameraOn) {
                    const newStream = await navigator.mediaDevices.getUserMedia({
                        video: { deviceId: selectedVideoDevice ? { exact: selectedVideoDevice } : undefined },
                        audio: true,
                    });

                    if (currentStream) {
                        currentStream.getTracks().forEach(track => track.stop());
                    }

                    const videoElement = document.getElementById('videoPreview');
                    videoElement.srcObject = newStream;
                    setCurrentStream(newStream);
                } else {
                    // If the camera is off, just update the video device without turning the camera on
                    const videoElement = document.getElementById('videoPreview');
                    const stream = videoElement.srcObject;
                    const videoTrack = stream ? stream.getVideoTracks()[0] : null;

                    if (videoTrack) {
                        const newConstraints = {
                            video: { deviceId: { exact: selectedVideoDevice } },
                        };

                        // Apply the new video device without enabling the camera
                        videoTrack.applyConstraints(newConstraints);
                    }
                }
            }
        };

        updateVideoDevice();
        // eslint-disable-next-line
    }, [selectedVideoDevice, cameraOn]);  // Trigger when video device or camera state changes



    const toggleMic = () => {
        setMicOn((prev) => {
            if (currentStream) {
                console.log("Toggling mic, current state:", prev);

                currentStream.getAudioTracks().forEach(track => track.enabled = !track.enabled);
            }
            return !prev;
        });
    };


    const toggleCamera = () => {
        setCameraOn((prev) => {
            const newState = !prev;
            if (newState) {
                startCamera(); // Start the camera when turning it on
            } else {
                if (currentStream) {
                    currentStream.getVideoTracks().forEach((track) => {
                        track.stop();
                    });
                }
                setCurrentStream(null);
                const videoElement = document.getElementById('videoPreview');
                if (videoElement) {
                    videoElement.srcObject = null;
                }
            }
            return newState;
        });
    };



    useEffect(() => {
        const urlParser = new UrlParse(window.location.href, true);
        const urlRoomId = urlParser?.query?.roomId;
        const urlbId = urlParser?.query?.bId;
        const key = urlParser?.query?.key;
        if (urlbId) setbId(urlbId);
        if (key) setE2eKey(key);
        if (urlRoomId) {

            setRoomIdINput(urlRoomId); // Update `roomId` from URL
            localStorage.setItem("roomId", urlRoomId); // Persist in `localStorage`
        } else {
            setJoinLoading(true);
            return renderErrorMessage("Invalid link!");
        }
    }, []);

    // Bypass the join page check for login user.
    useEffect(() => {
        const urlParser = new UrlParse(window.location.href, true);
        const urlRoomId = urlParser?.query?.roomId;
        if (getItem("sessionToken") && urlRoomId === roomId && hideScreen) {
            joinWebinar();
        }
        // eslint-disable-next-line
    }, [roomId]);


    const startCamera = async () => {
        if (!cameraOn) return;  // Only start the camera if it's turned on
        try {
            const newStream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: selectedVideoDevice ? { exact: selectedVideoDevice } : undefined },
                audio: true,
            });

            const videoElement = document.getElementById('videoPreview');
            videoElement.srcObject = newStream;
            setCurrentStream(newStream);
        } catch (error) {
            console.error("Error accessing the camera:", error);
        }
    };


    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);

    // Handle checkbox changes
    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        skipAutoJoin.current = true;
        setHideScreen(isChecked);
        localStorage.setItem(
            "room",
            JSON.stringify({ ...JSON.parse(localStorage.getItem("room")), meetJoin: isChecked })
        );

    };


    return (


        <Grid item={true} xs={12} sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'background.default',
            height: '100vh',
            boxShadow: '0px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
        }}>
            <ToastContainer />
            <Box sx={{
                backgroundColor: 'card.main',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                // height: loaderMessage ? '560px' : '423px',
                height: loaderMessage ? '560px' : '660px',
                width: '923px',
                flexDirection: 'column',
                borderRadius: '10px'
            }}>


                <MeetLogoSvg width='auto' />

                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '25px',
                    color: "grey.70",
                    mb: 1,
                    mt: 2
                }}>
                    Join Meeting
                </Typography>
                <Typography sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: 'grey.600',
                    mb: 2
                }}>
                    Please enter details and join the meeting
                </Typography>



                <Box sx={{
                    background: 'rgba(220, 160, 0, 0.1)',
                    borderRadius: '20px',
                    paddingRight: '10px'
                }}>
                    <Typography sx={{
                        color: '#DCA000',
                        fontSize: '12px',
                        ml: 1
                        // mb: 2
                    }}> <IconButton sx={{ '&:hover': { backgroundColor: "transparent", } }}>
                            <WarningAmberIcon sx={{ color: '#DCA000' }} />
                        </IconButton>Note: Login required to join as a host.</Typography>
                </Box>

                {loaderMessage && <LoaderWithMessage message={loaderMessage} />}

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    width: '100%',
                    padding: '14px',
                    justifyContent: 'space-around',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 'calc(100% / 2)',
                        flexDirection: 'column',
                        // mb: 4,
                        mt: 2
                    }}>

                        <Box sx={{
                            width: '450px',
                            height: '250px',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            backgroundColor: '#000',
                            position: 'relative'
                        }}>

                            <video id="videoPreview" autoPlay muted style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}>

                            </video>

                            {/* Camera Off message */}
                            {!cameraOn && (
                                <Box sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: 'white',
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                    padding: '10px',
                                    borderRadius: '5px',
                                }}>
                                    Camera is off
                                </Box>
                            )}
                            {/* Mic On Icon (Bottom-left corner) */}
                            {/* Mic On with Pulse Effect */}
                            {micOn && (
                                <Box sx={{
                                    position: 'absolute',
                                    bottom: 10,
                                    left: 10,
                                    color: 'white',
                                    fontSize: '30px',
                                    animation: micOn ? 'pulse 1.5s infinite' : 'none',  // Apply pulse animation when mic is on
                                }}>
                                    <MicIcon />
                                </Box>
                            )}

                            {/* </Box> */}
                            <Box sx={{
                                position: 'absolute',
                                bottom: 10,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                            }}>

                                <IconButton onClick={toggleMic}
                                    sx={{
                                        color: micOn ? 'grey.200' : 'error.100',
                                        backgroundColor: micOn ? 'transprent' : 'error.main',
                                        border: (theme) => `1px solid ${micOn ? theme.palette.grey[400] : theme.palette.error.main}`,
                                        "&:hover": {
                                            color: micOn ? 'grey.200' : 'error.100',
                                            backgroundColor: micOn ? 'transprent' : 'error.main',
                                            border: (theme) => `1px solid ${micOn ? theme.palette.grey[400] : theme.palette.error.main}`
                                        }
                                    }}
                                    className={micOn ? classes.pulse : ""}>
                                    {micOn ? <MicIcon /> : <MicOffIcon />}
                                </IconButton>

                                <IconButton
                                    onClick={toggleCamera}
                                    sx={{
                                        color: cameraOn ? 'grey.200' : 'error.100',
                                        backgroundColor: cameraOn ? 'transprent' : 'error.main',
                                        border: (theme) => `1px solid ${cameraOn ? theme.palette.grey[400] : theme.palette.error.main}`,
                                        "&:hover": {
                                            color: cameraOn ? 'grey.200' : 'error.100',
                                            backgroundColor: cameraOn ? 'transprent' : 'error.main',
                                            border: (theme) => `1px solid ${cameraOn ? theme.palette.grey[400] : theme.palette.error.main}`
                                        }
                                    }}
                                >
                                    {cameraOn ? <VideocamIcon /> : <VideocamOffIcon />}
                                </IconButton>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            mt: '8px',
                            gap: '10px',
                            width: '100%'
                        }}>
                            {/* Device Select */}
                            <FormControl>
                                {/* <InputLabel>Audio Device</InputLabel> */}
                                <Select
                                    value={selectedAudioDevice}
                                    onChange={(e) => setSelectedAudioDevice(e.target.value)}
                                    sx={{
                                        border: 'none !important',
                                        borderColor: 'none !important',
                                        backgroundColor: 'transparent',
                                        padding: '0px !important',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderWidth: '0px'
                                        },
                                    }}
                                    renderValue={(value) => {
                                        return (
                                            <Box sx={{
                                                display: "flex", gap: 1, alignItems: 'center', maxWidth: '200px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden'
                                            }}>
                                                <MicIcon sx={{ color: 'grey.600', width: '20px', height: '20px' }} />
                                                {audioDevices.find(device => device.deviceId === value)?.label}
                                            </Box>
                                        );
                                    }}
                                >
                                    {audioDevices.map((device) => (
                                        <MenuItem key={device.deviceId} value={device.deviceId}>
                                            {device.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                {/* <InputLabel>Audio Device</InputLabel> */}
                                <Select
                                    value={selectedSpeakerDevice}
                                    onChange={(e) => setselectedSpeakerDevice(e.target.value)}
                                    sx={{
                                        border: 'none !important',
                                        borderColor: 'none !important',
                                        backgroundColor: 'transparent',
                                        padding: '0px !important',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderWidth: '0px'
                                        },
                                    }}
                                    renderValue={(value) => {
                                        return (
                                            <Box sx={{
                                                display: "flex", gap: 1, alignItems: 'center', maxWidth: '200px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden'
                                            }}>
                                                <MicIcon sx={{ color: 'grey.600', width: '20px', height: '20px' }} />
                                                {audioOutputDevices.find(device => device.deviceId === value)?.label}
                                            </Box>
                                        );
                                    }}
                                >
                                    {audioOutputDevices.map((device) => (
                                        <MenuItem key={device.deviceId} value={device.deviceId}>
                                            {device.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>


                            <FormControl>
                                {/* <InputLabel>Video Device</InputLabel> */}
                                <Select
                                    value={selectedVideoDevice}
                                    onChange={(e) => setSelectedVideoDevice(e.target.value)}
                                    sx={{
                                        border: 'none !important',
                                        borderColor: 'none !important',
                                        backgroundColor: 'transparent',
                                        padding: '0px !important',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderWidth: '0px'
                                        },
                                    }}
                                    renderValue={(value, label) => {
                                        return (
                                            <Box sx={{
                                                display: "flex", gap: 1, alignItems: 'center', maxWidth: '200px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}>
                                                <VideocamIcon sx={{ color: 'grey.600', width: '20px', height: '20px' }} />
                                                {videoDevices.find(device => device.deviceId === value)?.label}
                                            </Box>
                                        );
                                    }}
                                >
                                    {videoDevices.map((device) => (
                                        <MenuItem key={device.deviceId} value={device.deviceId}>
                                            {device.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>


                        </Box>



                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <TextField
                            sx={{
                                width: '100%',
                                mt: 1,
                                backgroundColor: 'grey.1000',
                                borderRadius: '10px',
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: 'grey.800',
                                    }
                                },
                                "& .MuiFormLabel-root": {
                                    "&.Mui-focused": {
                                        color: 'grey.600',
                                    }
                                }
                            }}
                            inputProps={{ sx: { color: "grey.400" } }}
                            label="Name"
                            focused
                            className="inputRounded"
                            id="filled-hidden-label-small"
                            placeholder="Enter your name "
                            value={name}
                            onChange={(e) => changeName(e.target.value)}
                            onClick={() => setNameErr(false)}
                            error={nameErr ? true : false}
                        />
                        {/* Checkbox */}
                        <FormControlLabel
                            control={<Checkbox checked={hideScreen} onChange={handleCheckboxChange} />}
                            label="Don't show this screen again"
                        />
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            mb: 2
                        }}>
                            <LoadingButton
                                loading={joinLoading ? true : null}
                                color='primary'
                                loadingPosition="center"
                                startIcon={<LoginIcon />}
                                variant="contained"
                                id={peerId}
                                onClick={(e) => joinWebinar(e)}
                                sx={{
                                    // ml: 1,
                                    borderRadius: '10px',
                                    width: '100%',
                                    textTransform: 'none'
                                }}
                            >
                                Join Now
                            </LoadingButton>
                        </Box>
                    </Box>

                </Box >




                {/* {!getItem("sessionToken") && <Link onClick={() => loginRedirect()}> Check here to Login &amp; Join Meeting</Link>} */}
            </Box >

        </Grid >
    )
}
