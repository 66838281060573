import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import randomString from 'random-string';
import { Grid, Box, Typography, IconButton, Modal, Divider, TextField, ListItemButton, List, Popover, ListItemText, SvgIcon } from '@mui/material';
// import AddBoxIcon from '@mui/icons-material/AddBox';
// import EventNoteIcon from '@mui/icons-material/EventNote';
// import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment'
// import SideBar from '../../../layouts/sideBar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { renderErrorMessage } from '../../../components/ToastNotification';
import { joinWebianrCheck } from '../../../services/commonApis'
import UrlParse from 'url-parse';
import LoadingButton from '@mui/lab/LoadingButton';
import { getItem, removeBulkItems, setItem } from '../../../lib/cookiesManager';
import jwt from 'jwt-decode';
import { configFile } from "../../../config";
import { validateText, getMicAndCamPermission } from '../../../utils/common';
import { axiosGetCall, axiosPostCall } from '../../../services/apisCall';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { styled } from '@mui/material/styles';
import { ColorModeContext } from "../../../context/ThemeContext";
import CalendarLineIcon from '../../../assets/svgs/icons/calendar-line';
import Button from '@mui/material/Button';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import EditIcon from '@mui/icons-material/Edit';
import VideoCallSharpIcon from '@mui/icons-material/VideoCallSharp';
import Tooltip from '@mui/material/Tooltip';


const MainSection = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.card.main,
    boxShadow: 24,
    borderRadius: '10px',
    padding: '25px',
}));

const InputOuterBox = styled('div')(() => ({
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    fontSize: '15px',
    fontWeight: 400,
    color: theme.palette.grey[400]
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    borderRadius: '8px',
    backgroundColor: theme.palette.grey[800],
    width: '371px'
}))

export default function Dashboard() {
    const colorMode = useContext(ColorModeContext);
    const { imagesUrl, getProtooUrl, webinarRole, consoleUrl } = configFile;
    const [peerId,] = useState(randomString({ length: 8 }).toLowerCase());
    const [time, setTime] = useState(moment(new Date()).format('hh:mm A'));
    const [join, setJoin] = useState(false);
    const [joinWebinarUrl, setJoinWebinarUrl] = useState("");
    const [homeWebinars, setHomeWebinar] = useState([]);
    const [loadingWList, setLoadingWList] = useState(true)
    const [startWLoading, setStartWLoading] = useState(false)
    // const [reStartWLoading, setReStartWLoading] = useState(false);
    const [startRoomId, setStartRoomId] = useState("")
    const [deleteRoomId, setDeleteRoomId] = useState("");
    const [deleteWLoading, setDeleteWLoading] = useState(false)
    const [joinLoading, setJoinLoading] = useState(false);
    const [name, setName] = useState(getItem("name") || "")
    const [updateToCookies, setUpdateToCookies] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [runQuickMeet, setRunQuickMeet] = useState(false);
    const [data, setData] = useState(false);
    const [loadingStates, setLoadingStates] = React.useState({}); // Object to track loading states by roomId


    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickMoreOption = (event, data) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setData(data)
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const monthsStr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Handle join webinar model submit button click.
    const joinWebinar = async (e) => {
        e.preventDefault();
        setJoinLoading(true)
        if (!joinWebinarUrl) {
            setJoinLoading(false)
            return renderErrorMessage("Enter join meeting Link first!");
        }
        if (!name) {
            setJoinLoading(false)
            return renderErrorMessage("Enter your name!");
        }
        if (!validateText(name)) {
            setNameError(true)
            setJoinLoading(false)
            return renderErrorMessage("Only Letters and Numbers are allowed!");
        }
        try {
            if (updateToCookies) setItem("name", name)
            const urlParser = new UrlParse(joinWebinarUrl, true);
            // let pId = urlParser.query.peerId;
            const rId = urlParser.query.roomId;
            const e2eKey = urlParser.query?.key || "";
            let token = getItem("token");
            let pId = null;
            if (token) {
                let info = jwt(token);
                pId = info?.email;
            }
            if (!pId) pId = peerId;
            if (!rId) {
                setJoinLoading(false)
                return renderErrorMessage("Invalid link!");
            }
            if (!pId) pId = peerId;
            let { redirectURL, state } = await joinWebianrCheck(rId, name, pId) || {};
            if (redirectURL) {
                if (e2eKey) {
                    redirectURL = redirectURL + `&key=${encodeURIComponent(e2eKey.toString())}`;
                }
                navigate(redirectURL, { state });
                setJoinLoading(false)
            } else {
                setJoinLoading(false)
                return renderErrorMessage("Invalid link!")
            }
        } catch (error) {
            setJoinLoading(false)
            return renderErrorMessage(error?.message)
        }
    }

    // Fetch schedule webinar list.
    const fetchList = async (from = 0, size = 4, type = "all") => {
        try {
            let res = await axiosGetCall("listWebinar", { from, size, type })
            setLoadingWList(false)
            setHomeWebinar(res?.result)
        } catch (error) {
            setApiError(true)
            setLoadingWList(false);
            renderErrorMessage("Unable to fetch scheduled meeting list!")
        }
    }

    // Change name on join webinar popup.
    const changeName = (value) => {
        setName(value);
        setUpdateToCookies(true)
    }

    // For start scheduled webinar.
/*     const startWebinar = async (roomId) => {
        setStartRoomId(roomId)
        setStartWLoading(true)
        try {
            let token = getItem("token")
            let info = jwt(token);
            let pId = info?.email;
            setItem("name", info?.name)
            if (!roomId) {
                setStartWLoading(false)
                return renderErrorMessage("Invalid room Id!");
            }

            if (!pId) {
                setStartWLoading(false)
                return renderErrorMessage("Invalid token!");
            }

            let { redirectURL, state } = await joinWebianrCheck(roomId, name, pId) || {};
            if (redirectURL) {
                navigate(redirectURL, { state });
                return setStartWLoading(false)
            } else {
                setStartWLoading(false)
                return renderErrorMessage("Invalid link!")
            }
        } catch (error) {
            setStartWLoading(false)
            return renderErrorMessage(error?.message)
        }
    }
 */
   
    const startWebinar = async (roomId) => {
        if (!roomId) {
            return renderErrorMessage("Invalid room Id!");
        }
    
        setLoadingStates((prev) => ({ ...prev, [roomId]: true })); 
    
        try {
            setStartRoomId(roomId); 
    
            let token = getItem("token");
            let info = jwt(token);
            let pId = info?.email;
            setItem("name", info?.name);
    
            if (!pId) {
                setLoadingStates((prev) => ({ ...prev, [roomId]: false }));
                return renderErrorMessage("Invalid token!");
            }
    
            let { redirectURL, state } = await joinWebianrCheck(roomId, info?.name, pId) || {};
    
            if (redirectURL) {
                navigate(redirectURL, { state });
            } else {
                renderErrorMessage("Invalid link!");
            }
        } catch (error) {
            renderErrorMessage(error?.message);
        } finally {
            setLoadingStates((prev) => ({ ...prev, [roomId]: false })); 
        }
    };
   
    /*  // For re-start scheduled webinar.
     const reStartWebinar = async (roomId) => {
         setStartRoomId(roomId)
         setReStartWLoading(true)
         try {
             let res = await axiosPostCall("scheduleWebinar", { roomId, status: "inactive" })
             if (res) {
                 await startWebinar(roomId);
                 // renderSuccessMessage("Your webinar has been re-scheduled!");
                 // let updateStatus = homeWebinars.map((item, index) => {
                 //     return item?.roomId === roomId ? { ...item, status: "inactive" } : item;
                 // })
                 // setHomeWebinar(updateStatus)
                 setReStartWLoading(false)
             }
         } catch (error) {
             setReStartWLoading(false)
             return renderErrorMessage(error?.message)
         }
     } */

    // Delete canceled webinar.
    const deleteWebinar = async (roomId) => {
        setDeleteRoomId(roomId);
        setDeleteWLoading(true)
        try {
            await axiosPostCall("cancelWebinar", { roomId, deleted: true })
            setDeleteWLoading(false)
            // Refresh Webinar List on delete webinar
            fetchList();
        } catch (error) {
            setDeleteWLoading(false)
            renderErrorMessage("Unable to delete meeting!");
        }
    }

    const startQuickMeet = async () => {

        setRunQuickMeet(true)
        try {
            const token = getItem("token");
            const info = jwt(token);
            let pId = info?.email;

            setItem("name", info?.name);
            let { title = "", description = "", isHostServer, roomId, record, autoRecord, disableRecordPlayPause, serverDetails = {} } = await axiosPostCall("quickMeeting", pId) || {};
            const role = webinarRole?.produce;
            const wssUrl = getProtooUrl({ roomId, peerId: pId, serverDetails });
            if (isHostServer) getMicAndCamPermission();
            const protooUrl = `${wssUrl}&h=${isHostServer}&fp=""`;
            if (protooUrl) {
                navigate(`/meet?roomId=${roomId}&peerId=${pId}`, { state: { protooUrl, peerId: pId, role, name: info?.name, title, description, meetType: 'meet', record, autoRecord, disableRecordPlayPause } });
                setRunQuickMeet(false)
            } else {
                setRunQuickMeet(false)
                return renderErrorMessage("Unable to start quick meet. Try again!")
            }
        } catch (error) {
            setRunQuickMeet(false)
            return renderErrorMessage(error?.message)

        }
    }

    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (event.origin === consoleUrl) {
                const { session = false, redirectPath, theme = "light" } = event.data;
                if (session) removeBulkItems(["sessionToken", "token", "exp"]);
                if (redirectPath) return window.location.href = redirectPath;
                const checkTheme = getItem("theme");
                if (checkTheme !== theme && !process.env.REACT_APP_DOMAIN_SUBDIRECTORY) {
                    setItem("theme", theme)
                    colorMode.toggleColorMode();
                };
            }
        });
        // let url = fetchData();
        fetchList(0, 4, "all");
        setInterval(() => {
            setTime(moment(new Date()).format('hh:mm A'))
        }, 10000);
        // eslint-disable-next-line
    }, [])

    return (
        <Grid item={true} xs={12} container sx={{
            height: '100vh',
            backgroundColor: 'background.main',
            display: 'flex',
            pt: '64px',
            pl: '100px',
            pr: '40px'
        }}>
            <ToastContainer />

            <Grid item={true} container xs={6} sx={{
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '12px'
            }}>

                {/* <Typography variant="body1" component="div" sx={{
                        fontSize: '24px',
                        fontWeight: 500,
                        lineHeight: "28px",
                        position: 'absolute',
                        top: '80px',
                        left: '120px',
                        color: '#ce165e',
                    }}>
                        Welcome{" "}
                        <Typography variant='inherit' component="span" sx={{
                            color: '#454545',
                        }}>
                            {name}
                        </Typography>
                    </Typography> */}
                <Typography component="h1" sx={{
                    fontSize: '36px',
                    color: 'grey.800',
                    fontWeight: 400,
                    maxWidth: '460px'
                    // lineHeight: "77px",
                }}>
                    Real-Time Communication, No Boundaries
                </Typography>
                <Typography component="h1" sx={{
                    fontSize: '16px',
                    color: 'grey.600',
                    fontWeight: 400,
                    maxWidth: '460px'
                    // lineHeight: "77px",
                }}>
                    Meet, share, and collaborate, no matter where you are or what device you use
                </Typography>

                <Box sx={{
                    display: 'flex',
                    gap: '8px',
                    flexDirection: 'row',
                    mt: '12px',
                    width: '100%',
                }}>

                    <CustomTextField
                        // fullWidth
                        // className="inputRounded"
                        value={joinWebinarUrl}
                        onChange={(e) => setJoinWebinarUrl(e.target.value)}
                        id="filled-hidden-label-small"
                        placeholder="Type or Paste your link here"
                        inputProps={{ sx: { color: "grey.400" }, }}
                    />

                    <LoadingButton
                        loading={joinLoading ? true : null}
                        // color='success'
                        loadingPosition="center"
                        variant="outlined"
                        onClick={(e) => joinWebinar(e)}
                        sx={{
                            color: 'grey.600',
                            backgroundColor: 'grey.200',
                            borderColor: 'grey.300',
                            "&:hover": {
                                backgroundColor: 'grey.300',
                                borderColor: 'grey.300',
                            },
                            textTransform: 'none'
                        }}
                    >
                        Join
                    </LoadingButton>
                </Box>

                <Box sx={{
                    display: 'flex',
                    gap: '8px',
                    flexDirection: 'row',
                    mt: '12px'
                }}>
                    <LoadingButton
                        startIcon={<VideoCallIcon />}
                        sx={{
                            color: 'primary.100',
                            backgroundColor: 'primary.main',
                            p: '10px 14px',
                            "&:hover": {
                                backgroundColor: 'primary.dark'
                            }
                        }}
                        onClick={() => startQuickMeet()}
                        loading={runQuickMeet}
                    >
                        Quick Meeting
                    </LoadingButton>

                    <LoadingButton
                        startIcon={<SvgIcon><CalendarLineIcon color={'currentColor'} /></SvgIcon>}
                        sx={{
                            color: 'grey.600',
                            backgroundColor: 'grey.200',
                            p: '10px 14px',
                            "&:hover": {
                                backgroundColor: 'grey.300'
                            }
                        }}
                        onClick={() => navigate('/schedule_meet')}
                    >
                        Schedule for later
                    </LoadingButton>
                </Box>


                {/* <Typography variant="h1" component="h1" sx={{
                    fontSize: '64px',
                    color: 'grey.300',
                    fontWeight: 700,
                    // lineHeight: "77px",
                }}>
                    {time}
                </Typography>
                <Typography sx={{
                    fontSize: '19px',
                    color: 'grey.400',
                    fontWeight: 500,
                    // lineHeight: "23px",
                }}>
                    {moment().format('dddd')}, {moment().format('MMMM')} {new Date().getDate()}, {moment().year()}
                </Typography> */}

                {/*   <Grid item={true} container sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    display: 'grid-row',
                    mt: 3,
                    width: '70%',
                    gridTemplateRows: '25% 25% 25% 25%',
                    gridTemplateColumns: 'auto auto auto auto',
                    zIndex: 3
                }}>
                    <Box>
                        <Grid item={true} container sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            mt: 3,
                        }}>
                            <Box sx={{
                                width: '16vh',
                                height: '15vh',
                                backgroundColor: 'rgb(64, 67, 81)',
                                borderRadius: '30px',
                                mx: 3,
                                '&:hover': {
                                    backgroundColor: 'rgb(64, 67, 81)',
                                },
                            }}
                                onClick={() => setJoin(true)}
                            >
                                <IconButton sx={{
                                    borderRadius: '20px',
                                    width: '16vh',
                                    height: '15vh',
                                    color: '#fff'
                                    // width: '30%'
                                }}>
                                    <AddBoxIcon sx={{ width: '6vh', height: '6vh' }} />
                                </IconButton>
                            </Box>
                            <Typography sx={{
                                fontWeight: 'bold,',
                                fontSize: '19px',
                                color: 'grey.400',
                                // mx: 4,
                                // px: 1,
                                py: 2,
                            }}>
                                JOIN
                            </Typography>
                        </Grid>
                    </Box>
                    <Box >
                        <Grid item={true} container sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            mt: 3,

                        }}>
                            <Box sx={{
                                width: '16vh',
                                height: '15vh',
                                backgroundColor: 'rgb(64, 67, 81)',
                                borderRadius: '30px',
                                mx: 3,
                                '&:hover': {
                                    backgroundColor: 'rgb(64, 67, 81)',
                                },
                            }}
                                onClick={() => navigate('/schedule_meet')}
                            >
                                <IconButton sx={{
                                    borderRadius: '20px',
                                    width: '16vh',
                                    height: '15vh',
                                    color: '#fff'
                                    // width: '30%'
                                }}>
                                    <EventNoteIcon sx={{ width: '6vh', height: '6vh' }} />
                                </IconButton>
                            </Box>
                            <Typography sx={{
                                fontWeight: 'bold,',
                                fontSize: '19px',
                                color: 'grey.400',
                                // px: 1,
                                py: 2,
                            }}>
                                SCHEDULE
                            </Typography>
                        </Grid>
                    </Box>

                    <Box >
                        <Grid item={true} container id="quickMeeting" sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            mt: 3,

                        }}>
                            <Box sx={{
                                width: '16vh',
                                height: '15vh',
                                backgroundColor: '#029930',
                                borderRadius: '30px',
                                mx: 3,
                                '&:hover': {
                                    backgroundColor: '#029930',
                                },
                            }}
                                onClick={() => startQuickMeet()}
                                id='quickMeetBtn'
                            >
                                {runQuickMeet ?
                                    <Box sx={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        mt: 'calc(50% - 12px)'
                                    }}>
                                        <img src={imagesUrl?.loading} alt='loading...' width={'20%'} />
                                    </Box>
                                    : <IconButton sx={{
                                        borderRadius: '20px',
                                        width: '16vh',
                                        height: '15vh',
                                        color: '#fff'
                                        // width: '30%'
                                    }}>
                                        <VideoCallIcon sx={{ width: '6vh', height: '6vh' }} />
                                    </IconButton>}
                            </Box>
                            <Typography sx={{
                                fontWeight: 'bold,',
                                fontSize: '19px',
                                color: 'grey.400',
                                // px: 1,
                                py: 2,
                            }}>
                                QUICK MEETING
                            </Typography>
                        </Grid>
                    </Box>
                </Grid> */}

            </Grid>

            <Grid item={true} xs={6} sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <List sx={{
                    borderRadius: '8px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    '&:last-child': {
                        borderBottom: 'none'
                    },
                    width: '100%',
                    height: 'auto'
                }}>
                    {homeWebinars.length > 0 && homeWebinars.map((data, index) => (
                        <ListItemButton key={index} sx={{
                            borderTopLeftRadius: '8px',
                            borderBottomLeftRadius: '8px',
                            borderBottom: '1px solid',
                            borderColor: 'grey.300',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '28px',
                            alignItems: 'center',
                            padding: '20px',
                            backgroundColor: !index ? 'primary.light' : 'grey.0',
                            '&:hover': {
                                backgroundColor: !index ? 'primary.light' : 'grey.0',
                            },
                            mr: !index ? '-41px' : "",
                            ml: !index ? '-20px' : '',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <Typography sx={{ fontSize: '12px', color: !index ? 'primary.200' : 'grey.600', fontWeight: 400 }}>
                                    {new Date(data?.startDate).getDate()}
                                </Typography>
                                <Typography sx={{ fontSize: '12px', color: !index ? 'primary.200' : 'grey.600', fontWeight: 400 }}>
                                    {monthsStr[new Date(data?.startDate).getMonth()]}
                                </Typography>
                            </Box>


                            <Divider orientation="vertical" flexItem sx={{ backgroundColor: !index ? 'primary.400' : 'grey.300' }} />

                            <Typography sx={{ fontSize: '16px', color: !index ? 'primary.100' : 'grey.500', fontWeight: 500 }}>
                                {/* {`${moment(data?.startDate).format("hh:mm A")} to ${moment(data?.endDate).format("hh:mm A")}`} */}
                                {`${moment(data?.startDate).format("hh:mm A")}`}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: !index ? 'primary.100' : 'grey.800', fontWeight: 500, flexGrow: 1, minWidth: '200px' }}>
                                {data?.title.length > 20 ? `${data?.title.slice(0, 20)}...` : data?.title}
                            </Typography>

                            {/*  <Box>
                                <IconButton
                                    sx={{ color: !index ? 'primary.100' : 'grey.800', }}
                                    aria-describedby={id}
                                    type="button"
                                    onClick={(e) => handleClickMoreOption(e, data)}
                                >
                                    <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                        <More2LineIcon color={'currentColor'} />
                                    </SvgIcon>
                                </IconButton>

                            </Box> */}
                            {/* Buttons */}

                            <Box sx={{
                                display: 'flex',
                                gap: '8px',
                                borderColor: 'grey.300',
                                padding: '8px',

                            }}>
                                {/* Start Button */}

                                {data?.endDate >= +new Date() && !data?.canceled && (
                                    <>
                                <LoadingButton
                                            variant="contained"
                                            size="small"
                                            loading={!!loadingStates[data?.roomId]} // Check loading state for the specific roomId

                                            sx={{
                                                borderRadius: '6px',
                                                borderColor: 'grey.400',
                                                backgroundColor: 'grey.300',
                                                color: !index ? 'primary.main' : 'grey.800',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    backgroundColor: 'grey.300',
                                                    color: !index ? 'primary.main' : 'grey.800',
                                                    boxShadow: 'none',
                                                },
                                            }}
                                            startIcon={<VideoCallSharpIcon />}
                                            onClick={() => startWebinar(data?.roomId)}

                                        >
                                            Start
                                            </LoadingButton>

                                        {/* Edit Button */}
                                        {!data?.quickMeet && (
                                            <Tooltip title="Edit" arrow>

                                                <IconButton
                                                    variant="contained"
                                                    color="default"
                                                    size="small"
                                                    sx={{
                                                        borderRadius: '6px',
                                                        border: '1px solid',
                                                        borderColor: 'grey.400',
                                                        color: !index ? 'grey.300' : 'grey.600'
                                                    }}
                                                    onClick={() => navigate(`/schedule_meet`, { state: { edit: true, roomId: data?.roomId } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>

                                        )}
                                    </>
                                )}

                                {/* Details Button */}
                                <Tooltip title="Details" arrow>

                                    <IconButton
                                        color="default"
                                        size="small"
                                        sx={{
                                            borderRadius: '6px',
                                            border: '1px solid',
                                            borderColor: 'grey.400',
                                            color: !index ? 'grey.300' : 'grey.600'

                                        }}
                                        onClick={() => navigate(`/view_meet_details`, { state: { edit: true, roomId: data?.roomId } })}
                                    >
                                        <MoreHorizSharpIcon />
                                    </IconButton>
                                </Tooltip>

                            </Box>


                        </ListItemButton>
                    ))}

                    <Popover id={id} open={open} anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={() => setAnchorEl(null)}
                    >
                        <List sx={{ border: '1px solid', borderColor: 'grey.300', bgcolor: 'card.main', borderRadius: '8px' }}>
                            {(data?.endDate >= +new Date() && !data?.canceled) && <>
                                <ListItemButton
                                    loading={startWLoading && startRoomId === data?.roomId ? true : null}
                                    onClick={() => startWebinar(data?.roomId)}
                                >
                                    <ListItemText primary="Start" />
                                </ListItemButton>
                                {!data?.quickMeet && <ListItemButton onClick={() => navigate(`/schedule_meet`, { state: { edit: true, roomId: data?.roomId } })}>
                                    <ListItemText primary="Edit" />
                                </ListItemButton>}

                            </>
                            }

                            <ListItemButton onClick={() => navigate(`/view_meet_details`, { state: { roomId: data?.roomId } })}>
                                <ListItemText primary="View Details" />
                            </ListItemButton>
                        </List>
                    </Popover>


                    {/*   <ListItemButton sx={{
                        borderBottom: '1px solid',
                        borderColor: 'grey.300',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '28px',
                        alignItems: 'center',
                        padding: '20px',
                    }}>
                        <Typography sx={{ fontSize: '12px', color: 'grey.600', fontWeight: 400 }}>
                            Today
                        </Typography>

                        <Divider orientation="vertical" flexItem />

                        <Typography sx={{ fontSize: '16px', color: 'grey.500', fontWeight: 500 }}>
                            8: 30 AM
                        </Typography>
                        <Typography sx={{ fontSize: '16px', color: 'grey.800', fontWeight: 500, flexGrow: 1, minWidth: '200px' }}>
                            Title
                        </Typography>

                        <Box>
                            <IconButton>
                                <AddBoxIcon />
                            </IconButton>
                        </Box>


                    </ListItemButton> */}



                    {/* show loader when data loading. */}
                    {loadingWList && <Box sx={{
                        // position: 'absolute',
                        // top: '50%',
                        // transform: 'translate(-50 %, -50 %)',
                        // webkitTransform: 'translate(-50%, -50%)',
                        // moztransform: 'translate(-50%, -50%)',
                        // oTransform: 'translate(-50%, -50%)',
                        // msTransform: 'translate(-50%, -50%)',
                        // width: '100%'

                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <img src={imagesUrl?.loading} alt='loading...' />
                    </Box>}
                </List>
            </Grid>



            {/* <Box sx={{
                position: 'absolute',
                bottom: '0px',
                left: 0,
                width: "60px",
                height: '100vh',
                overflow: 'hidden',
                margin: '0px',
                padding: '0px'
            }}>
                <iframe
                    title='console'
                    src={!checkIframe() ? `${consoleUrl}/spinner?k=meet&s=true&spinner=${true}` : `${consoleUrl}/spinner?k=meet&s=true`}
                    // allow="camera *;microphone *"
                    style={{
                        top: " 0",
                        left: "0",
                        bottom: "0",
                        right: "0",
                        width: "100%",
                        height: "100%",
                        border: '0px'
                    }}
                />

            </Box> */}

            {/* Join webinar modal */}
            <Modal
                open={join}
                onClose={() => setJoin(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MainSection>

                    <IconButton id="modal-modal-title" onClick={() => setJoin(false)} sx={{
                        display: 'flex',
                        position: 'absolute',
                        justifyContent: 'right',
                        alignItems: 'right',
                        right: 15,
                        top: 10
                    }}>
                        <CloseIcon />
                    </IconButton>

                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ color: 'grey.300' }}
                    >
                        Join Your Meeting
                    </Typography>

                    <Divider id="modal-modal-title" sx={{ mt: 3 }} />

                    <InputOuterBox>
                        <InputLabel id="modal-modal-description" >
                            Meeting Link
                        </InputLabel>

                        <CustomTextField
                            className="inputRounded"
                            value={joinWebinarUrl}
                            onChange={(e) => setJoinWebinarUrl(e.target.value)}
                            id="filled-hidden-label-small"
                            placeholder="Type or Paste your link here"
                            inputProps={{ sx: { color: "grey.400" } }}
                        />
                    </InputOuterBox>

                    <InputOuterBox>
                        <InputLabel>
                            Name
                        </InputLabel>

                        <CustomTextField
                            className="inputRounded"
                            id="filled-hidden-label-small"
                            placeholder="Enter your name "
                            value={name}
                            onChange={(e) => changeName(e.target.value)}
                            onClick={() => setNameError(false)}
                            error={nameError ? true : false}
                            inputProps={{ sx: { color: "grey.400" } }}
                        />
                    </InputOuterBox>


                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '20px'
                    }}>
                        <LoadingButton
                            loading={joinLoading ? true : null}
                            color='success'
                            loadingPosition="center"
                            variant="outlined"
                            onClick={(e) => joinWebinar(e)}
                            sx={{
                                borderRadius: '10px',
                                width: '100%',
                                textTransform: 'none'
                            }}
                        >
                            Join Now
                        </LoadingButton>

                    </Box>
                </MainSection>
            </Modal>
        </Grid >
        // </Grid >
    )
}