// For websocket base and dynamic url
export function getProtooUrl({ roomId, peerId }) {
  const hostname = window.location.hostname;
  return `wss://${hostname}/meet-media/?roomId=${roomId}&peerId=${peerId}`;
}

export function aesConfig() {
  return {
    key: "dsfsdfsd@@$$7876gfgffbdfgd@@33##",
    splitKey: ".@#a61.",
  };
}

export function iceServers() {
  const iceServers = [
    // { urls: "turn:turn-webinar.sarv.com?transport=udp", username: "testname", credential: "testpass" },
    // { urls: "stun:turn.alertsijon.in:3478?transport=udp" },
    // { urls: "turn:turn.alertsijon.in:3478?transport=udp", username:"testname", credential: "testpass",  auth: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiI2M2ZkOWE0NDMzMDkzN2MxZDFlNmZlOWEiLCJwZWVySWQiOiJhbnNoLnZAc2Fydi5jb20iLCJpYXQiOjE2Nzc1NjQ0OTksImV4cCI6MTY3NzU4Njk5OX0.v2vfydaZEeJvkZc-m6v4VB0IHbqoJFop6byDjFsQw38" },
    // { urls: "turn:turn.alertsijon.in?transport=udp", username:"testname", credential: "testpass" },
  ];
  return iceServers;
}

export function iceServersIP() {
  return ""
}

// All apis base url
export function getApiUrl() {
  return `https://${window.location.hostname}/meet-api/`;
}

// sse event receive base url
export function sseEventUrl() {
  return `https://${window.location.hostname}/meet-api/events`;
}

// Get api endpoints dynamically
export function endPoints(title) {
  let version = "v1/";
  let routes = {
    fetchAttendees: `${version}room/fetchAttendees`,
    pinScreen: `${version}room_action/pinScreen`,
    joinRoom: `${version}room/joinroom`,
    endCall: `${version}room/endcall`,
    chat: `${version}room_action/chat`,
    loginWithGoogle: `${version}auth/login_with_google`,
    scheduleWebinar: `${version}webinar/schedule_webinar`,
    listWebinar: `${version}webinar/list`,
    detailsWebinar: `${version}webinar/fetch_webinar`,
    cancelWebinar: `${version}webinar/cancel`,
    feedback: `${version}webinar/feedback`,
    fetchChat: `${version}room_action/fetchChatMessages`,
    reportWebinar: `${version}webinar/report`,
    renewToken: `${version}auth/renewToken`,
    manageMedia: `${version}room/manageMedia`,
    kickPeer: `${version}room/kickPeer`,
    promoteAttendee: `${version}room/promoteAttendee`,
    fetchKickedPeers: `${version}room/fetchKickedPeers`,
    allowKickedPeer: `${version}room/allowKickedPeer`,
    quickMeeting: `${version}meet/quickMeeting`,
    waitingRoomPeerActions: `${version}room/waitingRoomPeerActions`,
    startRecording: `${version}room/startRecording`,
    stopRecording: `${version}room/stopRecording`,
    userGuide: `${version}auth/userGuide`,
    waitForStart: `${version}room/roomStatus`,

    joinCall: `${version}call/joinCall`,
    callStatus: `${version}call/callStatus`,
    sendOtp: `${version}call/sendOtp`,
  };

  let baseUrl = getApiUrl();
  let routeEndPoint = Object.entries(routes).find((item) => item[0] === title);
  return `${baseUrl}${routeEndPoint[1]}`;
}

// Congig or some params for google oauth2.
export const google_config = {
  // gClientID: "912740304686-g0eve7ad5797400kvglvdt650hhe5o2g.apps.googleusercontent.com",
  gClientID: "260883182254-ocms377kdscmpnve27d65n0mglfo2m2o.apps.googleusercontent.com"
  // gClientID:
  //   "722824660365-7858n44d8mjlpmhfl53ugfss4drpjevc.apps.googleusercontent.com",
};

export const webinarRole = {
  produce: "produce",
  consumer: "consumer",
};

export const logoUrl = {
  logoMain: "./images/wave-logo-grey.png",
  logoMobile: "./images/logoMobile.svg"
};
const baseUrl = `https://${window.location.hostname}/meet`;
export const imagesUrl = {
  feedbackPopup: `${baseUrl}/images/thankyou.svg`,
  profilePage: `${baseUrl}/images/profilePage.svg`,
  noInternet: `${baseUrl}/images/noInternet.svg`,
  loading: `${baseUrl}/images/loading.svg`,
  noScheduleWebinar: `${baseUrl}/images/noScheduleWebinar.svg`,
  noMoreReports: `${baseUrl}/images/noMoreReports.svg`,
  loginPageUser: `${baseUrl}/images/authShow.svg`,
  micAndVideoBlock: `${baseUrl}/images/micAndVideoBlock.svg`,
  micAndVideoBlockMobile: `${baseUrl}/images/micAndVideoBlockMobile.svg`,
  waitingRoom: `${baseUrl}/images/waitingRoom.svg`,

  whiteboardPng: `${baseUrl}/images/whiteboard.png`,

  signup: `${baseUrl}/images/HELP&FAQ/web/signup.png`,
  login: `${baseUrl}/images/HELP&FAQ/web/login.png`,
  forgotPassword: `${baseUrl}/images/HELP&FAQ/web/forgotPassword.png`,
  joinMeeting: `${baseUrl}/images/HELP&FAQ/web/joinMeeting.png`,
  quickMeeting: `${baseUrl}/images/HELP&FAQ/web/quickMeeting.png`,
  chat: `${baseUrl}/images/HELP&FAQ/web/chat.png`,
  invitePeopleMeeting: `${baseUrl}/images/HELP&FAQ/web/invitePeopleMeeting.png`,
  meetingControls: `${baseUrl}/images/HELP&FAQ/web/meetingControls.png`,
  scheduleMeetings: `${baseUrl}/images/HELP&FAQ/web/scheduleMeetings.png`,
  shareScreen: `${baseUrl}/images/HELP&FAQ/web/shareScreen.png`,
  waitingRoomFaq: `${baseUrl}/images/HELP&FAQ/web/waitingRoom.png`,
  recordMeeting1: `${baseUrl}/images/HELP&FAQ/web/recordMeeting1.png`,
  recordMeeting2: `${baseUrl}/images/HELP&FAQ/web/recordMeeting2.png`,
  addorRemoveparticipantsWaitingRoom: `${baseUrl}/images/HELP&FAQ/web/addorRemoveparticipantsWaitingRoom.png`,
  addorRemoveparticipantsWaitingRoom2: `${baseUrl}/images/HELP&FAQ/web/addorRemoveparticipantsWaitingRoom2.png`,
  checkAllMeeting: `${baseUrl}/images/HELP&FAQ/web/checkAllMeeting.png`,
  enableOrDisablePermissions: `${baseUrl}/images/HELP&FAQ/web/enableOrDisablePermissions.png`,
  whiteboardToolBar: `${baseUrl}/images/HELP&FAQ/web/whiteboardToolBar.png`,
  Whiteboard: `${baseUrl}/images/HELP&FAQ/web/Whiteboard.png`,
  whiteboardView: `${baseUrl}/images/HELP&FAQ/web/whiteboardView.png`,
  virtualBackgroundOption: `${baseUrl}/images/HELP&FAQ/web/virtualBackgroundOption.png`,
  howtosetvirtualbackground: `${baseUrl}/images/HELP&FAQ/web/howtosetvirtualbackground.png`,
  howToChat2: `${baseUrl}/images/HELP&FAQ/web/howToChat2.png`,
  downloadOrSaveRecordMeeting: `${baseUrl}/images/HELP&FAQ/web/downloadOrSaveRecordMeeting.png`,
  shareYourScreen: `${baseUrl}/images/HELP&FAQ/web/shareYourScreen.png`,
  welcome: `${baseUrl}/images/HELP&FAQ/web/welcome.png`,
  scheduleMeetings2: `${baseUrl}/images/HELP&FAQ/web/scheduleMeetings2.png`,

  virtualBackground1: `${baseUrl}/images/virtualBackground1.png`,
  virtualBackground2: `${baseUrl}/images/virtualBackground2.png`,
  virtualBackground3: `${baseUrl}/images/virtualBackground3.png`,
  virtualBackground4: `${baseUrl}/images/virtualBackground4.png`,
  virtualBackground5: `${baseUrl}/images/virtualBackground5.png`,
  virtualBackground6: `${baseUrl}/images/virtualBackground6.png`,
  virtualBackground7: `${baseUrl}/images/virtualBackground7.png`,
  virtualBackground8: `${baseUrl}/images/virtualBackground8.png`,
  virtualBackground9: `${baseUrl}/images/virtualBackground9.png`,
  virtualBackground10: `${baseUrl}/images/virtualBackground10.png`,
  virtualBackground11: `${baseUrl}/images/virtualBackground11.png`,
  virtualBackground12: `${baseUrl}/images/virtualBackground12.png`,
  virtualBackground13: `${baseUrl}/images/virtualBackground13.png`,
  virtualBackground14: `${baseUrl}/images/virtualBackground14.png`,
  virtualBackground15: `${baseUrl}/images/virtualBackground15.png`,
  virtualBackground16: `${baseUrl}/images/virtualBackground16.png`,
  virtualBackground17: `${baseUrl}/images/virtualBackground17.png`,

  blurBackground: `${baseUrl}/images/blurBackground.png`,
  visualEffectIcon: `${baseUrl}/images/visualEffects.svg`,
  sarvLogo: `${baseUrl}/images/sarv_logo.png`,
  bellLogo: `${baseUrl}/images/BellLogo.png`,
  up112Logo: `${baseUrl}/images/UP112logo.png`,
  e2eValidScreen: `${baseUrl}/images/e2eValidScreen.png`,
  e2eInValidScreen: `${baseUrl}/images/e2eInvalidScreen.png`,

  thumbnail1: `${baseUrl}/images/thumbnail1.webp`,
  thumbnail2: `${baseUrl}/images/thumbnail2.webp`,
  thumbnail3: `${baseUrl}/images/thumbnail3.webp`,
  thumbnail4: `${baseUrl}/images/thumbnail4.webp`,
  thumbnail5: `${baseUrl}/images/thumbnail5.webp`,
  thumbnail6: `${baseUrl}/images/thumbnail6.webp`,
  thumbnail7: `${baseUrl}/images/thumbnail7.webp`,
  thumbnail8: `${baseUrl}/images/thumbnail8.webp`,
  thumbnail9: `${baseUrl}/images/thumbnail9.webp`,
  thumbnail10: `${baseUrl}/images/thumbnail10.webp`,
  thumbnail11: `${baseUrl}/images/thumbnail11.webp`,
  thumbnail12: `${baseUrl}/images/thumbnail12.webp`,
  thumbnail13: `${baseUrl}/images/thumbnail13.webp`,
  thumbnail14: `${baseUrl}/images/thumbnail14.webp`,
  thumbnail15: `${baseUrl}/images/thumbnail15.webp`,
  thumbnail16: `${baseUrl}/images/thumbnail16.webp`,
  thumbnail17: `${baseUrl}/images/thumbnail17.webp`,
};


export const renewTokenTime = +new Date(+new Date() + 2 * 60 * 60 * 1000);
export const removeMicOnWarningTime = 30 * 1000;
export const showMicOnWarningTime = 2 * 60 * 1000;

export const roomAutoClose = {
  showModalTime: 300000, // 5min 5*60*1000
  autoCloseTime: 60 // 1 min
}

export const SANTRY = true;
export const NETWORK_IMAGE = `https://${window.location.hostname}/meet/images/wave-logo-grey.png`;

export const audioPath = {
  join: "./audio/join.mp3",
};

export const consoleUrl = `https://${window.location.hostname}`;
export const consoleApiUrl = `https://${window.location.hostname}/console-api`;
export const consoleApiRoutes = {
  branding: '/v1/branding/brandingDetails'
}