import axios from 'axios';
import { getItem, removeItem } from './cookiesManager';
import { configFile } from "../config";
import { createRedirectPath } from '../utils/common';

const { getApiUrl } = configFile;
// const token = localStorage.getItem('jwt');
const token = getItem('sessionToken');
const axiosIntance = axios.create({
  baseURL: getApiUrl(),
  headers: {
    "session-token": token ?? ""
  }
});

axiosIntance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const status = error?.response?.status;
  if (status === 713) {
    if (!process.env.REACT_APP_DOMAIN_SUBDIRECTORY) {
      removeItem("secureToken");
      // removeItem("exp");
      // removeItem("token");
    }
    alert("Your account has been logged in on another device!");
    window.location.href = createRedirectPath("/login");
  }
  return Promise.reject(error);
});

export default axiosIntance;