import React, { useEffect } from "react";
import { Outlet, Route, } from "react-router-dom";


import PrivateRoute from "./hoc"
import { getCurrentPath } from "../utils/common";

import LoginWithGoogle from "../pages/WebUI/GoogleLogin";
function RedirectToGoogle() {


    useEffect(() => {
        window.location.href = getCurrentPath();
    }, []);


    return null;
}


export default function AuthRouter() {
    return (
        <>
            <Route
                element={
                    <>
                        <PrivateRoute>
                            <Outlet />
                        </PrivateRoute>
                    </>
                }
            >
                <Route
                    path="/"
                    element={
                        <>
                            <Outlet />{" "}
                        </>
                    }
                >
                    {/* <Route path="/" element={<Navigate to={`/u/${0}`} replace />} /> */}
                    <Route path="/" element={<RedirectToGoogle />} />

                </Route>
            </Route >
            <Route path="/login" element={<LoginWithGoogle />} />
        </>
    );
}
