import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import { createRedirectPath } from "../utils/common";
// import virtualBackground1 from "./v2.png"

let selfieSegmentation = null;
let video = null;
let canvas = null;
let context = null;
let animationFrameId = null;
let mediaStream = null;
let worker = null;
let dynamicImage = null;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


function getVirtualBgScreen(stream, videoDimension, vb, imageUrl) {

    try {
        mediaStream = stream;
        video = document.createElement("video");
        canvas = document.createElement("canvas");
        dynamicImage = document.createElement('img');

        dynamicImage.src = imageUrl;
        context = canvas.getContext("2d");
        video.width = videoDimension.width.ideal
        video.height = videoDimension.height.ideal
        video.autoplay = true;
        // Conditionally apply Safari-specific attributes
        if (isSafari) {
            console.log("Safari detected, applying specific fixes.");
            video.playsInline = true; // Prevents background tab pause
            video.muted = true;       // Prevents Safari from pausing playback
            video.addEventListener("pause", () => {
                if (document.visibilityState === "hidden") {
                    video.play(); // Resume playback when hidden
                }
            });
        }

        canvas.width = video.width;
        canvas.height = video.height;

        video.srcObject = stream;

        const onResults = (results) => {
            context.save();
            clearCanvas();

            context.globalCompositeOperation = "copy";
            context.filter = "none";

            if (vb !== "off") {
                // context.filter = `blur(10px)`;
                drawSegmentationMask(results.segmentationMask);
                context.globalCompositeOperation = "source-in";
                // context.filter = "none";
                context.filter = "brightness(1) contrast(1.2)";
            }

            context.drawImage(results.image, 0, 0, canvas.width, canvas.height);

            if (vb === "on") {
                blurBackground(dynamicImage, 0);
            }

            if (vb === "blur") {
                blurBackground(results.image, 20);
            }

            context.restore();
        }

        video.addEventListener('play', () => {
            cancelAnimationFrame(animationFrameId);
            if (!selfieSegmentation) {
                selfieSegmentation = new SelfieSegmentation({
                    locateFile: (file) =>
                        `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
                });

                selfieSegmentation.setOptions({
                    modelSelection: 0,selfieMode:true,modelComplexity:1,smoothLandmarks: true,
                    enableSegmentation: false,
                    smoothSegmentation: true,
                    minDetectionConfidence: 0.5,
                    minTrackingConfidence: 0.5,
                    effect: 'background',
                });

                selfieSegmentation.onResults(onResults);
                sendToMediaPipe();
            }
        });

        document.addEventListener("visibilitychange", visiblityChange);

        const outputStream = canvas.captureStream(30);
        return outputStream;

    } catch (error) {
        console.log(error);
    }
}

/* const sendToMediaPipe = async () => {
    cancelAnimationFrame(animationFrameId);
    if (!video?.height) {
        console.log(video?.height);
    } else {
        // console.log("IS ANIMATION FRAME!")
        await selfieSegmentation?.send({ image: video });
    }

    animationFrameId = requestAnimationFrame(sendToMediaPipe);
};

const sendToMediaPipeWorker = async () => {
    cancelAnimationFrame(animationFrameId);
    if (!video?.height) {
        console.log(video?.height);
    } else {
        console.log("IS WORKER FRAME!-----------")
        await selfieSegmentation?.send({ image: video });
        console.log("working in browser------------")
    }
}; */


const sendToMediaPipe = async () => {
    cancelAnimationFrame(animationFrameId); 
    if (!video?.height) return;

    await selfieSegmentation?.send({ image: video });

    if (isSafari) {
        if (document.visibilityState === "visible") {
            animationFrameId = requestAnimationFrame(sendToMediaPipe);
        } else {
            setTimeout(sendToMediaPipe, 100); // Fallback for Safari when tab is inactive
        }
    } else {
        animationFrameId = requestAnimationFrame(sendToMediaPipe);
    }
};

const sendToMediaPipeWorker = async () => {
    cancelAnimationFrame(animationFrameId); 
    if (!video?.height) return;

    await selfieSegmentation?.send({ image: video });

    if (isSafari) {
        if (worker) {
            worker.postMessage({ message: "start" });
        }
    } else {
        animationFrameId = requestAnimationFrame(sendToMediaPipeWorker);
    }
};
const visiblityChange = () => {
    if (document.visibilityState === "visible") {
        if (worker) {
            console.log("WORKER TERMINATED!")
            worker.terminate()
        }
        if (video?.width) {
            sendToMediaPipe()
        }
    } else {
        if (video?.width) {
            console.log("WORKER CONNECTED!")
            worker = new Worker(new URL(`${window.location.origin}${createRedirectPath('/js/virtualBackgroundWorker.js')}`), { name: 'virtual background worker' });
            worker.postMessage({ message: "start" })
            worker.onmessage = (event) => {
                if (event?.data?.message) {
                    sendToMediaPipeWorker();
                    worker.postMessage({ message: "start" })
                }
            }
        }
    }
}

function clearCanvas() {
    context.clearRect(0, 0, canvas.width, canvas.height);
}

function drawSegmentationMask(segmentation) {
    context.globalCompositeOperation = "copy";

    context.drawImage(segmentation, 0, 0, canvas.width, canvas.height);
}

function blurBackground(image, blurAmount) {
    context.globalCompositeOperation = "destination-over";
    context.filter = `blur(${blurAmount}px)`;
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
}

function endVirtualSegmentation() {
    try {
        if (mediaStream) {
            const tracks = mediaStream.getTracks();

            tracks.forEach(track => {
                track.stop();
            });
            mediaStream = null;
        }
        document.removeEventListener("visibilitychange", visiblityChange);

        // Optionally, if you want to remove all references to the stream
        console.log("WORKER TERMINATED!")
        if (worker) worker.terminate();
        video = null;
        canvas = null;
        context = null;
        cancelAnimationFrame(animationFrameId);
        if (selfieSegmentation) {
            selfieSegmentation.close();
            selfieSegmentation = null;
        }
    } catch (error) {
        console.log(error)
    }
}

// new optimize

/* function getVirtualBgScreen(stream, videoDimension, vb, imageUrl) {
    console.log("stream", stream);
    console.log("videoDimension", videoDimension);
    console.log("vb", vb);
    console.log("imageUrl", imageUrl);

    try {
        video = document.createElement("video");
        const foregroundCanvas = document.createElement("canvas");
        const backgroundCanvas = document.createElement("canvas");
        const outputCanvas = document.createElement("canvas");

        const fgCtx = foregroundCanvas.getContext("2d");
        const bgCtx = backgroundCanvas.getContext("2d");
        const outCtx = outputCanvas.getContext("2d");

        const dynamicImage = new Image();
        dynamicImage.src = imageUrl;

        // Ensure image is loaded
        dynamicImage.onload = () => console.log("Background image loaded");
        dynamicImage.onerror = () => console.error("Failed to load background image");

        video.width = videoDimension.width.ideal
        video.height = videoDimension.height.ideal
        video.autoplay = true;
        video.srcObject = stream;

        const selfieSegmentation = new SelfieSegmentation({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
        });
        selfieSegmentation.setOptions({ modelSelection: 1, });

        selfieSegmentation.onResults((results) => {
            if (!results.image || !results.segmentationMask) return;

            // Foreground processing

            fgCtx.clearRect(0, 0, foregroundCanvas.width, foregroundCanvas.height);

            bgCtx.clearRect(0, 0, backgroundCanvas.width, backgroundCanvas.height);

            // Foreground processing
            fgCtx.save();
            fgCtx.drawImage(results.segmentationMask, 0, 0, foregroundCanvas.width, foregroundCanvas.height);
            fgCtx.globalCompositeOperation = "source-in";
            fgCtx.globalAlpha = 1;

            fgCtx.drawImage(results.image, 0, 0, foregroundCanvas.width, foregroundCanvas.height);
            fgCtx.restore();

            // Optional: Apply brightness/contrast adjustment to the foreground
            //   fgCtx.filter = "none"; // Disable any shadow-causing filter

            fgCtx.filter = "brightness(1) contrast(0.9)";
            fgCtx.drawImage(foregroundCanvas, 0, 0);

            // Background processing
            if (vb === "blur") {
                bgCtx.filter = "blur(15px)";
                bgCtx.drawImage(results.image, 0, 0, backgroundCanvas.width, backgroundCanvas.height);
            } else if (vb === "on" && dynamicImage.complete) {
                bgCtx.drawImage(dynamicImage, 0, 0, backgroundCanvas.width, backgroundCanvas.height);
            }

            // Merge layers
            outCtx.clearRect(0, 0, outputCanvas.width, outputCanvas.height);
            outCtx.filter = "brightness(1)";

            outCtx.drawImage(backgroundCanvas, 0, 0);
            outCtx.drawImage(foregroundCanvas, 0, 0);
        });

        video.addEventListener("loadedmetadata", () => {
            if (video.videoWidth && video.videoHeight) {
                foregroundCanvas.width = backgroundCanvas.width = outputCanvas.width = video.videoWidth;
                foregroundCanvas.height = backgroundCanvas.height = outputCanvas.height = video.videoHeight;

                async function processFrame() {
                    await selfieSegmentation.send({ image: video });
                    requestAnimationFrame(processFrame);
                }
                processFrame();
            } else {
                console.error("Video dimensions are not ready");
            }
        });

        document.addEventListener("visibilitychange", visiblityChange);

        return outputCanvas.captureStream(30);
    } catch (error) {
        console.error(error);
    }
}





async function sendToMediaPipe() {
    cancelAnimationFrame(animationFrameId);
    if (!video?.videoHeight) {
        console.log("Video height not ready.");
    } else {
        await selfieSegmentation?.send({ image: video });
    }

    animationFrameId = requestAnimationFrame(sendToMediaPipe);
}

// const visiblityChange = () => {
//     if (document.visibilityState === "visible") {
//         sendToMediaPipe();
//     } else {
//         cancelAnimationFrame(animationFrameId);
//     }
// };

const visiblityChange = () => {
    if (document.visibilityState === "visible") {
        if (worker) {
            console.log("WORKER TERMINATED!")
            worker.terminate()
        }
        if (video?.width) {
            sendToMediaPipe()
        }
    } else {
        console.log("nlsdkfsdkl", video?.width)
        if (video?.width) {
            console.log("WORKER CONNECTED!")
            worker = new Worker(new URL(`${window.location.origin}${createRedirectPath('/js/virtualBackgroundWorker.js')}`), { name: 'virtual background worker' });
            worker.postMessage({ message: "start" })
            worker.onmessage = (event) => {
                if (event?.data?.message) {
                    sendToMediaPipeWorker();
                    worker.postMessage({ message: "start" })
                }
            }
        }
    }
}

const sendToMediaPipeWorker = async () => {
    cancelAnimationFrame(animationFrameId);
    if (!video?.height) {
        console.log(video?.height);
    } else {
        // console.log("IS WORKER FRAME!")
        await selfieSegmentation?.send({ image: video });
    }
}; */

export {
    getVirtualBgScreen,
    endVirtualSegmentation,
}
