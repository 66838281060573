import React, { useEffect, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Grid, Typography, Box, Link, Modal } from "@mui/material";
import { loginWithGoogle } from '../../../services/commonApis';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { renderErrorMessage, renderSuccessMessage } from '../../../components/ToastNotification';
import { getItem } from '../../../lib/cookiesManager';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import { joinWebianrCheck } from '../../../services/commonApis';
import { configFile } from '../../../config';
import { getConosleEnv, meetCode } from '../../../config/Enums';
import { createRedirectPath } from '../../../utils/common';

export default function LoginWithGoogle() {
    const { google_config, imagesUrl, consoleUrl } = configFile;
    const navigate = useNavigate();
    const location = useLocation();
    const [loginLoading, setLoginLoading] = useState(false)
    const [showLogin, setShowLogin] = useState(false);

    const consoleEnv = getConosleEnv();

    const hendleLoginSuccess = async ({ credential }) => {
        try {
            setLoginLoading(true);
            let res = await loginWithGoogle(credential);
            if (res) {
                renderSuccessMessage("Login successfully!");
                if (location?.state) {
                    let { peerId, roomId } = location?.state;
                    try {
                        let token = getItem("token");
                        let pId = null;
                        let name = ""
                        if (token) {
                            let info = jwt(token);
                            pId = info?.email;
                            name = info?.name
                        }
                        if (!pId) pId = peerId;

                        let { redirectURL, state } = await joinWebianrCheck(roomId, name, pId) || {};
                        if (redirectURL) {
                            navigate(redirectURL, { state });
                            return setLoginLoading(false);
                        } else {
                            window.location.href = createRedirectPath('')
                            setLoginLoading(false);
                            return renderErrorMessage("Invalid link!");
                        }
                    } catch (error) {
                        window.location.href = createRedirectPath('')
                        setLoginLoading(false);
                        return renderErrorMessage(error?.message)
                    }
                }

                window.location.href = createRedirectPath('')
                setLoginLoading(false);
                // navigate('/')
            }
        } catch (error) {
            setLoginLoading(false);
            renderErrorMessage(error?.message || "Internel server error!")
        }
    }

    useEffect(() => {
        if (consoleEnv.includes(process.env.REACT_APP_ENV)) return window.location.href = `${consoleUrl}/login?k=${meetCode}`;
        setShowLogin(true);
        let checkToken = getItem("sessionToken")
        if (checkToken) {
            let exp = getItem("exp");
            if (exp <= +new Date()) {
                return;
            }
            window.location.href = '/'
            // navigate("/")
        }
        return;
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {!showLogin ? <></> : <Grid container item={true} xs={12} sx={{
                backgroundColor: '#FFFFFF'
            }}>
                <ToastContainer />
                <Grid container item={true} xs={4} sx={{
                    backgroundColor: '#FFFFFF',
                    backgroundImage: 'url(./images/auth.svg)',
                    backgroundSize: 'cover',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100vh'
                }}>
                    <Typography variant="h5" sx={{
                        fontSize: '24px',
                        lineHeight: "30px",
                        mt: '60px',
                        ml: '30px',
                        top: 1,
                        left: 1,
                        position: 'absolute',
                        color: '#FFFFFF',
                        fontWeight: 700
                    }}>
                        Meeting
                    </Typography>
                    <Grid container sx={{
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        height: '100%'
                    }}>
                        <Box>
                            <Typography variant="h5" sx={{
                                fontSize: '56px',
                                lineHeight: "78px",
                                // mt: '70px',
                                ml: '10px',
                                fontWeight: 600,
                                color: '#FFFFFF',
                            }}>
                                Start your
                                journey with us
                            </Typography>
                            <Typography variant="h5" sx={{
                                fontSize: '16px',
                                lineHeight: "20px",
                                // mt: '22px',
                                ml: '10px',
                                color: 'rgba(255, 255, 255, 0.63)',
                                fontWeight: 500
                            }}>
                                This is an all-in-one platform to host immersive events and build real connections, online.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container sx={{
                        // position: 'relative',
                        position: 'relative',
                        right: "0px",
                        bottom: "0px",
                        // height: "100%"
                    }}>
                        <img src="./images/authShow.svg" alt="move"/*  style={{ height: '20vh', width: '20vh' }} */
                            style={{
                                position: 'absolute',
                                right: "30px",
                                bottom: "0px",
                                height: '30vh',
                                width: '30vh'
                            }} />
                    </Grid>
                </Grid>
                <Grid container item={true} xs={8} sx={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <Typography variant="h5" sx={{
                        fontWeight: 700,
                        fontSize: '40px',
                        lineHeight: "58px",
                        color: '#454545'
                    }}>
                        Sign In
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '70%',
                        height: '50%',
                        backgroundSize: 'cover',
                    }}>
                        <img src='./images/googleLoginAvtar.svg' alt='login' style={{
                            width: '100%',
                            height: '100%'
                        }} />
                    </Box>
                    <Box>
                        <GoogleOAuthProvider clientId={google_config.gClientID}>
                            <GoogleLogin
                                onSuccess={credentialResponse => hendleLoginSuccess(credentialResponse)}
                                onError={(err) => {
                                    console.log('Login Failed', err);
                                    renderErrorMessage(err?.message || "Login Failed!")
                                }}
                                type={"standard"}
                                theme={"outline"}
                                size={"large"}
                                width={"550px"}
                                text={"signin_with"}
                            // auto_select={false}
                            // shape={"circle"}
                            // text={"Sign up with Google"}
                            // useOneTap
                            />
                        </GoogleOAuthProvider>
                    </Box>

                    <Box sx={{
                        // py: 1,
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        bottom: 0,
                        position: 'absolute',
                        flexDirection: 'row'
                    }}>
                        <Typography sx={{
                            fontWeight: 500,
                            fontSize: "11px",
                            lineHeight: "13px",
                            backgroundColor: '#CCCCCC',
                            py: 1,
                            px: 2,
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px'
                        }}>
                            © 2022-{new Date().getFullYear()}. Proudly Powered By <Link>Sarv</Link>
                        </Typography>
                    </Box>

                </Grid>

                {/* Login loading modal */}
                <Modal
                    open={loginLoading}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        outline: 'none',
                    }}>
                        <img src={imagesUrl.loading} alt='Loading...' />
                    </Box>
                </Modal>
            </Grid>}

        </>
    )
}
