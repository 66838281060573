import jsCookie from 'js-cookie';
// import PropTypes from 'prop-types';

const USER_COOKIE = 'webinar.user';
const DEVICES_COOKIE = 'webinar.devices';

export function getUser() {
    return jsCookie.get(USER_COOKIE);
}

export function setUser({ displayName }) {
    jsCookie.set(USER_COOKIE, { displayName }, { path: '/', sameSite: 'none', secure: true });
}

export function getDevices() {
    return JSON.parse(jsCookie.get(DEVICES_COOKIE) || '{}');
}

export function setDevices({ webcamEnabled = false, audioEnabled = false }) {
    jsCookie.set(DEVICES_COOKIE, JSON.stringify({ webcamEnabled, audioEnabled } || '{}'));
}

/* export function setItem(key, value, exp = 1, path = "/") {
    jsCookie.set(key, value, { expires: exp, path, sameSite: 'none',secure:true })
    return
} */

/* export function getItem(key) {
    if (checkIframe()) {
        const refData = jsCookie.get("consoleRef");
        const data = JSON.parse(refData);
        return data[key];
    }
    return jsCookie.get(key);
} */

/*  export const getItem = (key) => {
    const secureField = ["exp", "sessionToken", "token", "workspace"]
    if (secureField.includes(key)) {
        const currentPath = window.location.pathname.split("/");
        const isIframe = checkIframe();
        const secureToken = isIframe ? jsCookie.get("refSecureToken") : jsCookie.get("secureToken");
        // debugger;
        if (secureToken) {
            const parsedToken = JSON.parse(secureToken);
            if (!isIframe && currentPath && currentPath.length >= 4) {
                const result = parsedToken[currentPath[3]]?.[key];
                return result;
            } else {
                const result = parsedToken[0]?.[key];
                return result;
            }
        } else {
            return jsCookie.get(key);
        }
    } else {
        return jsCookie.get(key);
    }
 } */


export const getItem = (key) => {

    const secureField = ["exp", "sessionToken", "token", "workspace"]

    if (secureField.includes(key)) {

        const currentPath = window.location.pathname.split("/");

        const isIframe = checkIframe();

        const secureToken = isIframe ? jsCookie.get("refSecureToken") : jsCookie.get("secureToken");

        if (secureToken) {

            const parsedToken = JSON.parse(secureToken);

            if (!isIframe && currentPath && currentPath.length >= 4) {

                if (key === "token") {

                    const localToken = localStorage.getItem("token") || [];

                    const parsedLocalToken = JSON.parse(localToken);

                    const currentPos = currentPath[3];

                    return parsedLocalToken[currentPos];

                }

                const result = parsedToken[currentPath[3]]?.[key];

                return result;

            } else {

                const token = localStorage.getItem("token");

                if (!isIframe && key === "token" && token) {

                    // const localToken = localStorage.getItem("token");

                    const parsedLocalToken = JSON.parse(token);

                    return parsedLocalToken[0];

                }

                const result = parsedToken[0]?.[key];

                return result;

            }

        }

        else {

            return jsCookie.get(key);

        }

    } else {

        return jsCookie.get(key);

    }

}


/*  export const setItem = (key, value, exp = 1, path = "/") => {
    const secureField = ["exp", "sessionToken", "token", "workspace"];
   
   
    if (secureField.includes(key)) {
      const currentPath = window.location.pathname.split("/");
      const isIframe = checkIframe();
      const secureToken = isIframe ? jsCookie.get("refSecureToken") : jsCookie.get("secureToken");
      let parsedToken = secureToken ? JSON.parse(secureToken) : {};
   
   
      if (!isIframe && currentPath && currentPath.length >= 4) {
        const pathKey = currentPath[3];
        parsedToken[pathKey] = parsedToken[pathKey] || {};
        parsedToken[pathKey][key] = value;
      } else {
        parsedToken[0] = parsedToken[0] || {};
        parsedToken[0][key] = value;
      }
   
   
      const tokenKey = isIframe ? "refSecureToken" : "secureToken";
      return jsCookie.set(tokenKey, JSON.stringify(parsedToken), {
        expires: exp,
        path,
        sameSite: "none",
        secure: true,
      });
    } else {
      return jsCookie.set(key, value, {
        expires: exp,
        path,
        sameSite: "none",
        secure: true,
      });
    }
   }; */

  export const setItem = (key, value, exp = 1, path = "/") => {
    const secureField = ["exp", "sessionToken", "token", "workspace"];

    if (secureField.includes(key)) {
        const currentPath = window.location.pathname.split("/");
        const isIframe = checkIframe();
        const secureToken = isIframe ? jsCookie.get("refSecureToken") : jsCookie.get("secureToken");
        let parsedToken = secureToken ? JSON.parse(secureToken) : {};

        if (!isIframe && currentPath && currentPath.length >= 4) {
            if (key === "token") {
                const localToken = localStorage.getItem("token");
                const parsedLocalToken = JSON.parse(localToken);
                parsedLocalToken.push(value);
                localStorage.setItem("token", JSON.stringify(parsedLocalToken));
                return;
            }
            const pathKey = currentPath[3];
            parsedToken[pathKey] = parsedToken[pathKey] || {};
            parsedToken[pathKey][key] = value;
        } else {
            if (!isIframe && key === "token") {
                const localToken = localStorage.getItem("token") || JSON.stringify([]);
                const parsedLocalToken = JSON.parse(localToken);
                parsedLocalToken[0] = value;
                localStorage.setItem("token", JSON.stringify(parsedLocalToken));
                return;
            }
            parsedToken[0] = parsedToken[0] || {};
            parsedToken[0][key] = value;
        }
        const tokenKey = isIframe ? "refSecureToken" : "secureToken";
        return jsCookie.set(tokenKey, JSON.stringify(parsedToken), {
            expires: exp,
            path,
            sameSite: "none",
            secure: true,
        });
    } else {
        return jsCookie.set(key, value, {
            expires: exp,
            path,
            sameSite: "none",
            secure: true,
        });
    }
};

export function removeItem(key, path = '/') {
    return jsCookie.remove(key, { path });
}

export function setBulkItems(items) {
    Object.keys(items).forEach(key => {
        setItem(key, items[key]);
    })
}

export function removeBulkItems(items) {
    items.forEach(item => {
        removeItem(item);
    })
}

export function getBulkItems(items) {
    let values = {};
    items.forEach(item => {
        values[item] = getItem(item);
    })
    return values;
}

export function checkIframe() {
    if (window !== window.top) {
        return true;
    }
    return false;
}
/* // Get Single cookie data.
const getItem = (key) => {
    return jsCookie.get(key);
}

getItem.propTypes = {
    key: PropTypes.string.isRequired, // String type key requried
}

// Get multiple cookees data.
const getBulkItems = (keyNamesArray) => {
    return keyNamesArray.map((key) => {
        const value = getItem(key);
        return { key: value };
    });
}

getBulkItems.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    // required array formate which is array of strings i.e ["key1", "key2", ...]
}

// Set single cookie.
const setItem = (key, value, exp = 1, path = "/") => {
    return jsCookie.set(key, value, { expires: exp, path });
}

setItem.propTypes = {
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    exp: PropTypes.number,
    path: PropTypes.string,
    // required array formate which is array of strings i.e ["key1", "key2", ...]
}

// Set multiple cookies. 
const setBulkItems = (keyValArrayOfObjects = []) => {
    return keyValArrayOfObjects.map(({ key, value, exp = 1, path = "/" }) => setItem(key, value, exp, path));
}

setBulkItems.propTypes = {
    keyValArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
}

// Remove particular single cookie.
const removeItem = (key, path = '/') => {
    return jsCookie.remove(key, { path });
}
removeItem.propTypes = {
    key: PropTypes.string.isRequired,
    path: PropTypes.string,
}

// Remove miltiple cookies.
const removeBulkItems = (keyValArrayOfObjects = []) => {
    return keyValArrayOfObjects.map(({ key, path = "/" }) => removeItem(key, path));
}
removeBulkItems.propTypes = {
    keyValArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export {
    getItem,
    getBulkItems,
    setItem,
    setBulkItems,
    removeItem,
    removeBulkItems
} */