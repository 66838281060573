import React from 'react';
import { IconButton, Typography, Modal, Box, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { createRedirectPath } from '../../../../utils/common';

export default function LeaveWebinarModal({ openLeave, setOpenLeave }) {
    return (
        <Modal
            open={openLeave}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: '#222222',
                boxShadow: 24,
                borderRadius: '10px',
                p: 4,
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: '10px',
                }}>
                    <IconButton sx={{
                        color: '#D3232F',
                        bgcolor: "rgba(211, 35, 47, 0.1)",
                        fontSize: '30xp',
                        height: '10vh',
                        width: '10vh',
                        "&:hover": {
                            color: '#D3232F',
                            bgcolor: "rgba(211, 35, 47, 0.1)",
                        }
                    }}>
                        <LocalPhoneOutlinedIcon />
                    </IconButton>

                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#FFFFFF', mt: 2 }}>
                        Are you sure you want to leave
                        meeting?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ color: '#CCCCCC', mt: 2 }}>
                        You will out form room
                    </Typography>

                    <Grid xs={12} item sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3,
                        width: '100%'
                    }}>
                        <LoadingButton
                            loading={null}
                            color='error'
                            loadingPosition="center"
                            variant="contained"
                            onClick={() => window.location.href = createRedirectPath('')}
                            sx={{
                                ml: 1,
                                borderRadius: '10px',
                                width: '100%',
                                textTransform: 'none',
                            }}
                        >
                            Leave
                        </LoadingButton>
                        <LoadingButton
                            loadingPosition="center"
                            onClick={() => setOpenLeave()}
                            sx={{
                                ml: 1,
                                borderRadius: '10px',
                                width: '100%',
                                textTransform: 'none',
                                bgcolor: '#454545',
                                color: '#FFFFFF',
                                '&:hover': {
                                    bgcolor: '#454545',
                                    color: '#FFFFFF'
                                }
                            }}
                        >
                            Cancel
                        </LoadingButton>
                    </Grid>
                </Box>
            </Box>
        </Modal>

    )
}
