import { configFile } from '../config';
// import ErrorMessage from '../utils/ErrorMessage.json';
import PropTypes from 'prop-types';
import axiosIntance from '../lib/axios';
import { prepareMessageFromError } from '../utils/common';
const { endPoints } = configFile;

// create api post call for all post method apis.
const axiosPostCall = async (endPointName, data = {}, completeResponse = false) => {
    try {
        const url = endPoints(endPointName);
        const response = await axiosIntance.post(url, data);

        if (completeResponse) return response;
        return response?.data;
    } catch (error) {
        throw new Error(prepareMessageFromError(error));
    }
}
const axiosPostCallWithStatusCode = async (endPointName, data = {}, completeResponse = false, headers = {}, query) => {
    try {
      const url = endPoints(endPointName);
      const response = await axiosIntance.post(url, data);
   
   
      if (completeResponse) return response;
      return response?.data;
   
   
      /* if (response?.statusText === "OK") {
          return response?.data;
      }
      throw new Error(ErrorMessage?.[4000]); */
    } catch (error) {
      if (error.response) {
        throw error;
      } else {
        // If it's not an Axios error, throw the generic error
        throw new Error(error.message || 'An unknown error occurred');
      }
    }
   }
   
   axiosPostCallWithStatusCode.propTypes = {
    endPointName: PropTypes.string.isRequired, // Api end point url name added in config.
    data: PropTypes.object, // Method body data.
    completeResponse: PropTypes.bool, // Pass true if you want received completed response insted of data.
}
axiosPostCall.propTypes = {
    endPointName: PropTypes.string.isRequired, // Api end point url name added in config.
    data: PropTypes.object, // Method body data.
    completeResponse: PropTypes.bool, // Pass true if you want received completed response insted of data.
}

// create api get call for all get method apis.
const axiosGetCall = async (endPointName, data = {}, completeResponse = false) => {
    try {
        const url = endPoints(endPointName);
        const response = await axiosIntance.get(url, { params: data });

        if (completeResponse) return response;
        return response?.data;
    } catch (error) {    
        throw new Error(prepareMessageFromError(error));
    }
}

axiosGetCall.propTypes = {
    endPointName: PropTypes.string.isRequired, // Api end point url name added in config.
    data: PropTypes.object, // Method body data.
    completeResponse: PropTypes.bool, // Pass true if you want received completed response insted of data.
}


export {
    axiosPostCall,
    axiosGetCall,
    axiosPostCallWithStatusCode
}