import { Grid, Box, Typography, IconButton, Button, Container, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useLocation, useNavigate } from 'react-router-dom';
import { renderErrorMessage } from '../../../components/ToastNotification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { joinWebianrCheck } from '../../../services/commonApis';
import { getItem } from '../../../lib/cookiesManager';
import jwt from 'jwt-decode';
import AppTitleBar from '../../../components/mobile/components/AppTitleBar';
import { axiosGetCall, axiosPostCall } from '../../../services/apisCall';
import { styled } from '@mui/material/styles';
import { createRedirectPath } from '../../../utils/common';

const InputLabel = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  color: theme.palette.grey[400]
}));

export default function MobileViewWebinarDetails() {
  const currentPath = window.location.pathname;
  const userId = currentPath.split('/')[3]; 
  const navigate = useNavigate();
  const location = useLocation();
  const [copySuccess, setCopySuccess] = useState(false);
  const [startWLoading, setStartWLoading] = useState(false);
  const [deleteWLoading, setDeleteWLoading] = useState(false);
  // const [reStartWLoading, setReStartWLoading] = useState(false);
  const [{
    title = "N/A",
    createdAt,
    // createdByName,
    description = "N/A",
    // duration,
    endDate,
    hostIds = [],
    canceled,
    // lastUpdatedAt,
    startDate,
    _id = "N/A",
    quickMeet = false,
    // status,
    // meetType=""
  }, setDetails] = useState([]);

  const formGapSx = { display: 'flex', width: '100%', flexDirection: "column", gap: '5px' }

  const fetchWebinarDetails = async (roomId) => {
    try {
      let res = await axiosGetCall("detailsWebinar", { roomId })
      if (res) setDetails(res?.result)
    } catch (error) {
      renderErrorMessage("Unable to fetch meeting details!")
    }
  }

  const cancelCheckWebinar = async (roomId) => {
    try {
      await axiosPostCall("cancelWebinar", { roomId, canceled: true })
      setDetails({
        title,
        createdAt,
        description,
        endDate,
        hostIds,
        canceled: true,
        startDate,
        _id
      })
      // setLoadingWList(false)
      // setHomeWebinar(res?.result)
    } catch (error) {
      // setLoadingWList(false)
      renderErrorMessage("Unable to cancel meeting!");
    }
  }

  // Delete canceled webinar.
  const deleteWebinar = async (roomId) => {
    setDeleteWLoading(true)
    try {
      await axiosPostCall("cancelWebinar", { roomId, deleted: true })
      setDeleteWLoading(false)
      // Navigate to dashboard on delete webinar
      navigate('/');
    } catch (error) {
      setDeleteWLoading(false)
      renderErrorMessage("Unable to delete meeting!");
    }
  }

  // Stuff to copy text in clip board.
  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false)
      }, 9000);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  // For start scheduled webinar.
  const startWebinar = async (roomId) => {
    setStartWLoading(true)
    try {
      let token = getItem("token")
      let info = jwt(token);
      let pId = info?.email;
      if (!roomId) {
        setStartWLoading(false)
        return renderErrorMessage("Invalid room Id!");
      }

      if (!pId) {
        setStartWLoading(false)
        return renderErrorMessage("Invalid token!");
      }

      let { redirectURL, state } = await joinWebianrCheck(roomId, info?.name, pId) || {};
      if (redirectURL) {
        navigate(redirectURL, { state });
        return setStartWLoading(false)
      } else {
        setStartWLoading(false)
        return renderErrorMessage("Invalid link!")
      }
    } catch (error) {
      setStartWLoading(false)
      return renderErrorMessage(error?.message)
    }
  }
/* 
  // For re-start scheduled webinar.
  const reStartWebinar = async (roomId) => {
    setReStartWLoading(true)
    try {
      let res = await axiosPostCall("scheduleWebinar", { roomId, status: "inactive" })
      if (res) {
        await startWebinar(roomId);
        // renderSuccessMessage("Your webinar has been re-scheduled!");
        // let updateStatus = wList.map((item, index) => {
        //   return item?.roomId === roomId ? { ...item, status: "inactive" } : item;
        // })
        // setWList(updateStatus)
        setReStartWLoading(false)
      }
    } catch (error) {
      setReStartWLoading(false)
      return renderErrorMessage(error?.message)
    }
  } */

  useEffect(() => {
    if (location?.state?.roomId) {
      fetchWebinarDetails(location?.state?.roomId);
      return;
    }
    renderErrorMessage("Internel server error!");

    // navigate('/');
    return;
    // eslint-disable-next-line
  }, [location])
  return (
    <Box className='MobileWraper' sx={{
      overflow: 'hidden',
      width: "100%",
      height: '100vh',
      backgroundColor: 'grey.1000'
    }}>
      <ToastContainer />
      <AppTitleBar title={"Detail of Meeting"} />
      <Container maxWidth="xl" className='DetailsContainer' sx={{
        height: 'calc(100vh - 115px)',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}>
        <Box sx={{ p: 1, gap: '15px', display: 'flex', flexDirection: 'column' }} className='DetailsGlobalBox'>
          <Box sx={{ display: 'flex', gap: '10px', pt: 3 }} className='DetailsBtnGridOuter'>
            {(!canceled && endDate > + new Date()) &&
              <>
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  position: 'fixed',
                  bottom: 0,
                  zIndex: 9999,
                  borderTop: '1px solid #CCCCCC',
                  left: 0,
                  justifyContent: 'center',
                  bgcolor: 'grey.900'
                }}>
                  <Divider />

                  <LoadingButton
                    variant='contained'
                    size='medium'
                    loading={startWLoading ? true : null}
                    loadingPosition="center"
                    onClick={() => startWebinar(location?.state?.roomId)}
                    sx={{
                      textTransform: "none",
                      width: 'calc(100% - 48px)',
                      boxShadow: 'none',
                      m: '10px 0px 10px 0px '
                    }}
                  >
                    Start
                  </LoadingButton>
                </Box>

                {!quickMeet && <Button size='small' variant='outlined' sx={{
                  // mx: 1,
                  textTransform: "none",
                }}
                  endIcon={<BorderColorIcon sx={{ height: '14px' }} />}
                  onClick={() => navigate(`/schedule_meet`, { state: { edit: true, roomId: location?.state?.roomId } })}>
                  Edit
                </Button>}
                <Button size='small' variant='outlined' color='error' sx={{
                  textTransform: "none",
                }} onClick={() => cancelCheckWebinar(location?.state?.roomId)}>
                  Cancel Meeting
                </Button>
              </>
            }
            {canceled &&
              <LoadingButton
                variant='outlined'
                color='error'
                size='small'
                loading={deleteWLoading ? true : null}
                loadingPosition="center"
                onClick={() => deleteWebinar(location?.state?.roomId)}
                sx={{
                  textTransform: "none",
                }}
              >
                Delete
              </LoadingButton>
            }
          </Box>

          <Box sx={{ ...formGapSx }} className='DetailsTitleBox'>
            <InputLabel>
              Title
            </InputLabel>

            <Box sx={{
              borderRadius: '5px',
              border: '1px solid #757575'
            }} className='DetailsTitleDesBox'>
              <Typography sx={{
                px: 2,
                py: 1,
                color: 'grey.400'
              }}>
                {title}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ ...formGapSx }} className='DetailsRoomIdBox'>
            <InputLabel>
              Room ID
            </InputLabel>

            <Box sx={{
              borderRadius: '5px',
              border: '1px solid #757575'
            }} className='DetailsRoomIdDes'>
              <Typography sx={{
                px: 2,
                py: 1,
                color: 'grey.400'
              }}>
                {location?.state?.roomId}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ ...formGapSx }} className='DetailsDesBox'>
            <InputLabel >
              Description
            </InputLabel>

            <Box sx={{
              borderRadius: '5px',
              border: '1px solid #757575'
            }} className='DetailsDes'>
              <Typography sx={{
                px: 2,
                py: 1,
                color: 'grey.400'
              }}>
                {description}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ ...formGapSx }} className='DetailsStartOnBox'>
            <InputLabel >
              Start On
            </InputLabel>

            <Box sx={{
              borderRadius: '5px',
              border: '1px solid #757575'
            }} className='DetailsStartOnDes'>
              <Typography sx={{
                px: 2,
                py: 1,
                color: 'grey.400',
              }}>
                {moment(startDate).format('MMMM')} {new Date(startDate).getDate()}, {moment(startDate).year()} {`${moment(startDate).format("hh:mm a")}`}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ ...formGapSx }} className='DetailsEndOnBox'>
            <InputLabel className='DetailsEndOnBox'>
              End On
            </InputLabel>

            <Box sx={{
              borderRadius: '5px',
              border: '1px solid #757575'
            }} className='DetailsEndDes'>
              <Typography sx={{
                px: 2,
                color: 'grey.400',
                py: 1,
              }}>
                {moment(endDate).format('MMMM')} {new Date(endDate).getDate()}, {moment(endDate).year()} {`${moment(endDate).format("hh:mm a")}`}
              </Typography>
            </Box>
          </Box>

          {/* <Box sx={{ ...formGapSx }} className='meetType'>
            <Typography sx={{
              fontWeight: 500,
              fontSize: '15px',
              lineHeight: '18px',
              color: '#757575'
            }} className='meetTypeTitle'>
              Type
            </Typography>

            <Box sx={{
              borderRadius: '5px',
              color: 'grey.400',
              border: '1px solid #757575'
            }} className='meetTypeDes'>
              <Typography sx={{
                px: 2,
                py: 1
              }}>
                {meetType}
              </Typography>
            </Box>
          </Box> */}

          <Box sx={{ ...formGapSx }} className='DetailsInvitedBox'>
            <InputLabel className='DetailsInvitedHostsBox'>
              Invited Hosts
            </InputLabel>

            <Box sx={{
              borderRadius: '5px'
            }} className='DetailsInviteHostsDes'>
              <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                maxWidth: '100%',
              }}>
                {hostIds.length > 0 && hostIds.map(item => (
                  <div className="tag-item-schedule" key={item} style={{ paddingRight: '14px' }}>
                    {item}
                  </div>
                ))}
              </Box>
            </Box>
          </Box>

          <Box sx={{ ...formGapSx }} className='DetailsInviteLinkBox'>
            <InputLabel className='DetailsInviteLinkBox'>
              Invite Link
            </InputLabel>

            <Grid container item={true} xs={12} sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              border: '1px solid #757575',
            }} className='DetailsInviteLinkDesGridOut'>
              <Grid item={true} xs={10} className='DetailsInviteLinkGrid Inner1'>
                <Typography variant='h5' sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: 'grey.400',
                  px: 2,
                }}>
                  {`${window.location.origin}${createRedirectPath(`/join_meet?roomId=`)}${location?.state?.roomId}`}
                </Typography>
              </Grid>
              <Grid xs={2} item={true} sx={{ display: 'flex', justifyContent: 'end', p: '5px' }} className='DetailsInviteLinkGrid Inner2'>
                <IconButton sx={{
                  color: copySuccess ? '#4BB543' : 'grey.400',
                  '&:hover': {
                    // bgcolor: '#fff'
                  }
                }}
                  onClick={() => copyToClipboard(`${window.location.origin}${createRedirectPath("/join_meet?roomId=")}${location?.state?.roomId}`)}>
                  <ContentCopyIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <Box className='DetailsNoteBox'>
            <Typography sx={{
              fontWeight: 700,
              fontSize: '12px',
              lineHeight: '16px',
              color: '#DCA000',
              paddingRight: '10px'
            }}>
              Note: Login required to join as a host.
            </Typography>
          </Box>
        </Box>

      </Container>
    </Box>
  )
}
