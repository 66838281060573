import protooClient from 'protoo-client';
import React from 'react';
import * as mediasoupClient from 'mediasoup-client';
import Logger from './Logger';
import * as cookiesManager from './cookiesManager';
import * as requestActions from '../redux/actions/requestActions';
import * as stateActions from '../redux/actions/stateActions';
import { sseClose, SSE_connect_events } from './Sse';
import { configFile } from '../config';
import * as pinFocusAction from '../redux/actions/pinFocusAction';
import * as e2e from './e2e';
import checkNetworkStats from "../lib/checkNetworkStats";
import { getVirtualBgScreen, endVirtualSegmentation } from "./virtualBackground";
import { createRedirectPath } from '../utils/common';
import { LOCAL_STORAGE } from '../config/Enums';

const { getProtooUrl,/* iceServers,  iceServersIP */ } = configFile;

const VIDEO_CONSTRAINS = {
	qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
	vga: { width: { ideal: 640 }, height: { ideal: 480 } },
	hd: { width: { ideal: 1280 }, height: { ideal: 720 } }
};

const PC_PROPRIETARY_CONSTRAINTS = { optional: [{ googDscp: true }] };

// Used for simulcast webcam video.
const WEBCAM_SIMULCAST_ENCODINGS =
	[
		{ scaleResolutionDownBy: 4, maxBitrate: 500000 },
		{ scaleResolutionDownBy: 2, maxBitrate: 1000000 },
		{ scaleResolutionDownBy: 1, maxBitrate: 5000000 }
	];

// Used for VP9 webcam video.
const WEBCAM_KSVC_ENCODINGS = [{ scalabilityMode: 'S3T3_KEY' }];

// Used for simulcast screen sharing.
/* const SCREEN_SHARING_SIMULCAST_ENCODINGS = [
	{ dtx: true, maxBitrate: 1500000 },
	{ dtx: true, maxBitrate: 6000000 }
]; */

// Used for VP9 screen sharing.
const SCREEN_SHARING_SVC_ENCODINGS = [{ scalabilityMode: 'S3T3', dtx: true }];

const EXTERNAL_VIDEO_SRC = '../../resources/videos/video-audio-stereo.mp4';

const logger = new Logger('RoomClient');

let store;

export default class RoomClient extends React.PureComponent {
	/**
	 * @param  {Object} data
	 * @param  {Object} data.store - The Redux store.
	 */

	static init(data) {
		store = data.store;
	}

	constructor(
		{
			roomId,
			peerId,
			displayName,
			device,
			handlerName,
			useSimulcast,
			useSharingSimulcast,
			forceTcp,
			produce,
			consume,
			forceH264,
			forceVP9,
			svc,
			datachannel,
			externalVideo,
			e2eKey,
			role,
			protooUrl,
		}
	) {
		super()
		logger.debug(
			'constructor() [roomId:"%s", peerId:"%s", displayName:"%s", device:%s]',
			roomId, peerId, displayName, device.flag);

		this._role = role;
		this._roomId = roomId;
		this._closed = false;
		this._displayName = displayName;
		this._device = device;
		this._peerId = peerId

		// Whether we want to force RTC over TCP.
		// @type {Boolean}
		this._forceTcp = forceTcp;

		// Whether we want to produce audio/video.
		// @type {Boolean}
		this._produce = role === "produce" ? produce : false; //to do manage letter during api integration 

		// Whether we should consume.
		// @type {Boolean}
		this._consume = consume;

		// Whether we want DataChannels.
		// @type {Boolean}
		this._useDataChannel = datachannel;

		// Force H264 codec for sending.
		this._forceH264 = Boolean(forceH264);

		// Force VP9 codec for sending.
		this._forceVP9 = Boolean(forceVP9);

		// External video.
		// @type {HTMLVideoElement}
		this._externalVideo = null;

		// Enabled end-to-end encryption.
		this._e2eKey = e2eKey;

		// MediaStream of the external video.
		// @type {MediaStream}
		this._externalVideoStream = null;

		// Next expected dataChannel test number.
		// @type {Number}
		this._nextDataChannelTestNumber = 0;

		if (externalVideo) {
			this._externalVideo = document.createElement('video');

			this._externalVideo.controls = true;
			this._externalVideo.muted = true;
			this._externalVideo.loop = true;
			this._externalVideo.setAttribute('playsinline', '');
			this._externalVideo.src = EXTERNAL_VIDEO_SRC;

			this._externalVideo.play()
				.catch((error) => logger.warn('externalVideo.play() failed:%o', error));
		}

		// Custom mediasoup-client handler name (to override default browser detection if desired).
		// @type {String}
		this._handlerName = handlerName;

		// Whether simulcast should be used.
		// @type {Boolean}
		this._useSimulcast = useSimulcast;

		// Whether simulcast should be used in desktop sharing.
		// @type {Boolean}
		this._useSharingSimulcast = useSharingSimulcast;

		// Protoo URL.
		// @type {String}
		this._protooUrl = protooUrl || getProtooUrl({ roomId, peerId }); //TODO: remove fn 

		// protoo-client Peer instance.
		// @type {protooClient.Peer}
		this._protoo = null;

		// mediasoup-client Device instance.
		// @type {mediasoupClient.Device}
		this._mediasoupDevice = null;

		// mediasoup Transport for sending.
		// @type {mediasoupClient.Transport}
		this._sendTransport = null;

		// mediasoup Transport for receiving.
		// @type {mediasoupClient.Transport}
		this._recvTransport = null;

		// Local mic mediasoup Producer.
		// @type {mediasoupClient.Producer}
		this._micProducer = null;

		// Local webcam mediasoup Producer.
		// @type {mediasoupClient.Producer}
		this._webcamProducer = null;

		// Local share mediasoup Producer.
		// @type {mediasoupClient.Producer}
		this._shareProducer = null;


		// mediasoup Consumers.
		// @type {Map<String, mediasoupClient.Consumer>}
		this._consumers = new Map();

		// mediasoup DataConsumers.
		// @type {Map<String, mediasoupClient.DataConsumer>}
		this._dataConsumers = new Map();

		// Map of webcam MediaDeviceInfos indexed by deviceId.
		// @type {Map<String, MediaDeviceInfos>}
		this._webcams = new Map();

		// Local Webcam.
		// @type {Object} with:
		// - {MediaDeviceInfo} [device]
		// - {String} [resolution] - 'qvga' / 'vga' / 'hd'.
		this._webcam =
		{
			device: null,
			resolution: 'hd'
		};

		// Local chat DataProducer.
		// @type {mediasoupClient.DataProducer}
		// this.getDataConsumerRemoteStats = null;

		// Local bot DataProducer.
		// @type {mediasoupClient.DataProducer}
		// this._botDataProducer = null;

		// Set custom SVC scalability mode.
		if (svc) {
			WEBCAM_KSVC_ENCODINGS[0].scalabilityMode = `${svc}_KEY`;
			SCREEN_SHARING_SVC_ENCODINGS[0].scalabilityMode = svc;
		}

		if (this._e2eKey && e2e.isSupported()) {
			e2e.setCryptoKey('setCryptoKey', this._e2eKey, true);
		}

		this._ipNPort = {}
		this._networkStatIntervalId = null;
	}

	close() {
		if (this._closed) return;

		this._closed = true;

		logger.debug('@RoomClient.js close()');

		// Close protoo Peer
		this._protoo.close();

		// Close mediasoup Transports.
		if (this._sendTransport)
			this._sendTransport.close();

		if (this._recvTransport)
			this._recvTransport.close();

		store.dispatch(stateActions.setRoomState('closed'));
		if (this._networkStatIntervalId) {
			clearInterval(this._networkStatIntervalId);
			this._networkStatIntervalId = null;
		}
		// let n = "Rakesh kumawat"
		// window.location.href = `/end_meet`
	}

	async join() {
		try {
			const protooTransport = new protooClient.WebSocketTransport(this._protooUrl);

			this._protoo = new protooClient.Peer(protooTransport);

			store.dispatch(stateActions.setRoomState('connecting'));

			this._protoo.on('open', () => this._joinRoom());

			this._protoo.on('failed', () => {
				logger.debug('@RoomClient.js Connection failed1');
				// store.dispatch(requestActions.notify({
				// 	type: 'error',
				// 	text: 'WebSocket connection failed'
				// }));
				sseClose()
			});

			this._protoo.on('disconnected', () => {
				logger.debug('@RoomClient.js ws disconnected');
				// store.dispatch(requestActions.notify({
				// 	type: 'error',
				// 	text: 'Connection lost!'
				// }));

				// Close mediasoup Transports.
				if (this._sendTransport) {
					this._sendTransport.close();
					this._sendTransport = null;
				}

				if (this._recvTransport) {
					this._recvTransport.close();
					this._recvTransport = null;
				}

				sseClose()
				store.dispatch(stateActions.setRoomState('closed'));
				// window.location.href = '/'
			});

			this._protoo.on('close', () => {
				if (this._closed)
					return;

				this.close();
				sseClose()
			});

			this._protoo.on('request', async (request, accept, reject) => {
				logger.debug('proto "request" event [method:%s, data:%o]', request.method, request.data);

				switch (request.method) {
					case 'newConsumer':
						{
							// console.warn("newConsumer", request.data)
							if (!this._consume) {
								reject(403, 'I do not want to consume');

								break;
							}

							const { peerId, producerId, id, kind, rtpParameters, type, appData, producerPaused } = request.data;
							try {
								const consumer = await this._recvTransport.consume({
									id,
									producerId,
									kind,
									rtpParameters,
									appData: { ...appData, peerId } // Trick.
								});

								if (this._e2eKey && e2e.isSupported()) {
									e2e.setupReceiverTransform(consumer.rtpReceiver);
								}

								// Store in the map.
								this._consumers.set(consumer.id, consumer);

								consumer.on('transportclose', () => {
									this._consumers.delete(consumer.id);
								});

								const { spatialLayers, temporalLayers } =
									mediasoupClient.parseScalabilityMode(
										consumer.rtpParameters.encodings[0].scalabilityMode);

								// console.warn("appp data", appData, type)

								store.dispatch(stateActions.addConsumer(
									{
										id: consumer.id,
										type: appData?.share ? "share" : type,
										locallyPaused: false,
										remotelyPaused: producerPaused,
										rtpParameters: consumer.rtpParameters,
										spatialLayers: spatialLayers,
										temporalLayers: temporalLayers,
										preferredSpatialLayer: spatialLayers - 1,
										preferredTemporalLayer: temporalLayers - 1,
										priority: 1,
										codec: consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
										track: consumer.track,
										peerType: appData.peerType,
										// displayName: appData?.displayName
									},
									peerId));

								// We are ready. Answer the protoo request so the server will
								// resume this Consumer (which was paused for now if video).
								accept();

								// If audio-only mode is enabled, pause it.
								if (consumer.kind === 'video' && store.getState().me.audioOnly)
									this._pauseConsumer(consumer);
							}
							catch (error) {
								logger.error('"newConsumer" request failed:%o', error);

								store.dispatch(requestActions.notify({
									type: 'error',
									text: `Error creating a Consumer: ${error}`
								}));

								throw error;
							}

							break;
						}

					default:
						break;

				}
			});

			this._protoo.on('notification', (notification) => {
				// logger.debug(
				// 	'proto "notification" event [method:%s, data:%o]',
				// 	notification.method, notification.data);

				switch (notification.method) {
					case 'producerScore':
						{
							const { producerId, score } = notification.data;

							store.dispatch(stateActions.setProducerScore(producerId, score));

							break;
						}

					case 'newPeer':
						{
							const peer = notification.data;
							logger.debug("newPeer", peer)

							if (peer && peer?.role === "attendee") return null
							store.dispatch(
								stateActions.addPeer(
									{ ...peer, consumers: [], dataConsumers: [] }));

							store.dispatch(
								stateActions.audioNotification(
									{ peerId: peer?.id, type: 'join' }));

							// store.dispatch(requestActions.notify({
							// 	text: `${peer.displayName} has joined the room`
							// }));

							break;
						}

					case 'peerClosed':
						{
							const { peerId } = notification.data;

							store.dispatch(stateActions.removePeer(peerId));

							break;
						}

					case 'peerDisplayNameChanged':
						{
							const { peerId, displayName, oldDisplayName } = notification.data;

							store.dispatch(stateActions.setPeerDisplayName(displayName, peerId));
							store.dispatch(requestActions.notify({ text: `${oldDisplayName} is now ${displayName}` }));

							break;
						}

					case 'downlinkBwe':
						{
							// logger.debug('\'downlinkBwe\' event:%o', notification.data);

							break;
						}

					case 'consumerClosed':
						{
							const { consumerId } = notification.data;
							const consumer = this._consumers.get(consumerId);

							if (!consumer) {
								break;
							}
							consumer.close();
							this._consumers.delete(consumerId);

							const { peerId } = consumer.appData;

							store.dispatch(stateActions.removeConsumerById(consumerId));
							store.dispatch(stateActions.removeConsumer(consumerId, peerId));

							break;
						}

					case 'consumerPaused':
						{
							const { consumerId } = notification.data;
							const consumer = this._consumers.get(consumerId);

							if (!consumer)
								break;

							consumer.pause();

							store.dispatch(stateActions.setConsumerPaused(consumerId, 'remote'));

							break;
						}

					case 'consumerResumed':
						{
							const { consumerId } = notification.data;
							const consumer = this._consumers.get(consumerId);

							if (!consumer)
								break;

							consumer.resume();

							store.dispatch(stateActions.setConsumerResumed(consumerId, 'remote'));

							break;
						}

					case 'consumerLayersChanged':
						{
							const { consumerId, spatialLayer, temporalLayer } = notification.data;
							const consumer = this._consumers.get(consumerId);

							if (!consumer)
								break;

							store.dispatch(stateActions.setConsumerCurrentLayers(consumerId, spatialLayer, temporalLayer));

							break;
						}

					case 'consumerScore':
						{
							const { consumerId, score } = notification.data;

							store.dispatch(stateActions.setConsumerScore(consumerId, score));

							break;
						}

					case 'dataConsumerClosed':
						{
							const { dataConsumerId } = notification.data;
							const dataConsumer = this._dataConsumers.get(dataConsumerId);

							if (!dataConsumer)
								break;

							dataConsumer.close();
							this._dataConsumers.delete(dataConsumerId);

							const { peerId } = dataConsumer.appData;

							store.dispatch(stateActions.removeDataConsumer(dataConsumerId, peerId));

							break;
						}

					case 'activeSpeaker':
						{
							// const { peerId } = notification.data;

							// store.dispatch(stateActions.setRoomActiveSpeaker(peerId));

							break;
						}

					// case 'endCall':
					// 	{
					// 		window.location.href = '/end_meet'
					// 		this.close();

					// 		break;
					// 	}

					case 'pinnedShareScreens':
						{
							logger.debug("pinnedShareScreens message:", notification.data);
							store.dispatch(pinFocusAction.setPinShareScreens(notification.data.pinnedShareScreensValue));
							break;
						}

					case 'roomClosed':
						{
							store.dispatch(stateActions.setWebinarState('closed'));
							this.close();
							break;
						}

					case 'kickPeer':
						{
							window.location.href = createRedirectPath('');
							break;
						}

					case 'totalWatching':
						{
							logger.debug("totalWatching message:", notification.data);
							store.dispatch(stateActions.totalWatching(notification.data));
							break;
						}

					case 'whiteboard':
						{
							logger.debug("whiteboard message:", notification.data);
							store.dispatch(stateActions.updateWhiteboard(notification.data));
							break;
						}

					case 'pdfUrl':
						{
							store.dispatch(stateActions.updatePDFUrl(notification.data));
							break;
						}

					case 'undoRedoWhiteboard':
						{
							logger.debug("undoRedoWhiteboard message:", notification.data);
							store.dispatch(stateActions.undoRedoWhiteboard(notification.data));
							break;
						}

					case 'whiteboardScroll':
						{
							logger.debug("whiteboardScroll message:", notification.data);
							store.dispatch(stateActions.whiteboardScroll(notification.data));
							break;
						}

					case 'toggleWhiteboard':
						{
							logger.debug("toggleWhiteboard message:", notification.data);
							store.dispatch(stateActions.udpateWhitebaordOpenStatus(notification.data?.open));
							break;
						}

					case 'whiteboardAccess':
						{
							logger.debug("whiteboardAccess message:", notification.data);
							const { peerId = "", whiteboardAccess = false } = notification?.data;
							if (this._peerId === peerId) store.dispatch(stateActions.updateWhiteboardAccess({ whiteboardAccess }));
							else store.dispatch(stateActions.setPeerWhiteboardAccess(notification.data));
							break;
						}

					default:
						{
							logger.error(
								'unknown protoo notification.method "%s"', notification.method);
						}
				}
			});

		} catch (error) {
			logger.error('join()', error);
		}
	}

	async enableMic(deviceId = "") {
		let track;
		try {
			logger.debug('enableMic()', deviceId);
			cookiesManager.setDevices({ webcamEnabled: cookiesManager.getDevices().webcamEnabled, audioEnabled: true });

			if (this._micProducer)
				return;

			if (!this._mediasoupDevice.canProduce('audio')) {
				logger.error('enableMic() | cannot produce audio');

				return;
			}

			if (!this._externalVideo) {
				logger.debug('enableMic() | calling getUserMedia()');
				let stream;
				if (deviceId) {
					stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId, } });
				} else {
					stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				}

				logger.debug('enableMic() | stream', stream);

				track = stream.getAudioTracks()[0];
			} else {
				const stream = await this._getExternalVideoStream();

				track = stream.getAudioTracks()[0].clone();
			}

			this._micProducer = await this._sendTransport.produce(
				{
					track,
					codecOptions:
					{
						opusStereo: 1,
						opusDtx: 1
					}
					// NOTE: for testing codec selection.
					// codec : this._mediasoupDevice.rtpCapabilities.codecs
					// 	.find((codec) => codec.mimeType.toLowerCase() === 'audio/pcma')
				});

			if (this._e2eKey && e2e.isSupported()) {
				e2e.setupSenderTransform(this._micProducer.rtpSender);
			}

			store.dispatch(stateActions.addProducer({
				id: this._micProducer.id,
				paused: this._micProducer.paused,
				track: this._micProducer.track,
				rtpParameters: this._micProducer.rtpParameters,
				codec: this._micProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
			}));

			this._micProducer.on('transportclose', () => {
				this._micProducer = null;
			});

			this._micProducer.on('trackended', () => {
				store.dispatch(requestActions.notify({
					type: 'error',
					text: 'Microphone disconnected!'
				}));

				this.disableMic()
					.catch(() => { });
			});
		} catch (error) {
			logger.error('enableMic() | failed:%o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error enabling microphone: ${error}`
			}));

			if (track)
				track.stop();
		}
	}

	async disableMic() {
		try {
			logger.debug('disableMic()');
			cookiesManager.setDevices({ webcamEnabled: cookiesManager.getDevices().webcamEnabled, audioEnabled: false });

			if (!this._micProducer)
				return;

			this._micProducer.close();

			store.dispatch(stateActions.removeProducer(this._micProducer.id));


			await this._protoo.request('closeProducer', { producerId: this._micProducer.id });
		} catch (error) {
			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error closing server-side mic Producer: ${error}`
			}));
		}

		this._micProducer = null;
	}

	async muteMic() {
		try {
			logger.debug('muteMic()');

			this._micProducer.pause();

			await this._protoo.request('pauseProducer', { producerId: this._micProducer.id });

			store.dispatch(stateActions.setProducerPaused(this._micProducer.id));
		} catch (error) {
			logger.error('muteMic() | failed: %o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error pausing server-side mic Producer: ${error}`
			}));
		}
	}

	async unmuteMic() {
		logger.debug('unmuteMic()');

		try {
			if (!this._micProducer) return this.enableMic();
			this._micProducer.resume();
			await this._protoo.request('resumeProducer', { producerId: this._micProducer.id });

			store.dispatch(stateActions.setProducerResumed(this._micProducer.id));
		}
		catch (error) {
			logger.error('unmuteMic() | failed: %o', error, "mic", this._micProducer);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error resuming server-side mic Producer: ${error}`
			}));
		}
	}

	async enableWebcam(vb = "off", imageUrl = "") {
		let track;

		try {
			logger.debug('enableWebcam()', this._webcam, this._mediasoupDevice);

			if (this._webcamProducer)
				return;
			// else if (this._shareProducer)
			// 	await this.disableShare();

			if (!this._mediasoupDevice?.canProduce('video')) {
				logger.error('enableWebcam() | cannot produce video', this._mediasoupDevice, this._protoo);
				store.dispatch(requestActions.notify({
					type: 'error',
					text: 'Unstable network, Please reload tab to reconnect.'
				}));
				return;
			}

			let device;

			store.dispatch(stateActions.setWebcamInProgress(true));


			if (!this._externalVideo) {
				await this._updateWebcams();
				device = this._webcam.device;

				const { resolution } = this._webcam;

				if (!device)
					throw new Error('no webcam devices');

				logger.debug('enableWebcam() | calling getUserMedia()');

				let stream = await navigator.mediaDevices.getUserMedia({
					video: {
						deviceId: { ideal: device.deviceId },
						...VIDEO_CONSTRAINS[resolution]
					}
				});

				if (vb !== "off") stream = getVirtualBgScreen(stream, VIDEO_CONSTRAINS[resolution], vb, imageUrl) || stream;

				track = stream.getVideoTracks()[0];
			} else {
				device = { label: 'external video' };

				const stream = await this._getExternalVideoStream();

				track = stream.getVideoTracks()[0].clone();
			}

			let encodings;
			let codec;
			const codecOptions = { videoGoogleStartBitrate: 1000 };

			if (this._forceH264) {
				codec = this._mediasoupDevice.rtpCapabilities.codecs.find((c) => c.mimeType.toLowerCase() === 'video/h264');

				if (!codec) {
					throw new Error('desired H264 codec+configuration is not supported');
				}
			} else if (this._forceVP9) {
				codec = this._mediasoupDevice.rtpCapabilities.codecs.find((c) => c.mimeType.toLowerCase() === 'video/vp9');

				if (!codec) {
					throw new Error('desired VP9 codec+configuration is not supported');
				}
			}

			if (this._useSimulcast) {
				// If VP9 is the only available video codec then use SVC.
				const firstVideoCodec = this._mediasoupDevice
					.rtpCapabilities
					.codecs
					.find((c) => c.kind === 'video');

				if ((this._forceVP9 && codec) || firstVideoCodec.mimeType.toLowerCase() === 'video/vp9') {
					encodings = WEBCAM_KSVC_ENCODINGS;
				} else {
					encodings = WEBCAM_SIMULCAST_ENCODINGS;
				}
			}

			this._webcamProducer = await this._sendTransport.produce({ track, encodings, codecOptions, codec });

			if (this._e2eKey && e2e.isSupported()) {
				e2e.setupSenderTransform(this._webcamProducer.rtpSender);
			}

			store.dispatch(stateActions.addProducer({
				id: this._webcamProducer.id,
				deviceLabel: device.label,
				type: this._getWebcamType(device),
				paused: this._webcamProducer.paused,
				track: this._webcamProducer.track,
				rtpParameters: this._webcamProducer.rtpParameters,
				codec: this._webcamProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
			}));

			this._webcamProducer.on('transportclose', () => {
				this._webcamProducer = null;
			});

			this._webcamProducer.on('trackended', () => {
				store.dispatch(requestActions.notify({
					type: 'error',
					text: 'Webcam disconnected!'
				}));

				this.disableWebcam()
					.catch(() => { });
			});
		} catch (error) {
			logger.error('enableWebcam() | failed:%o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error enabling webcam: ${error}`
			}));

			if (track)
				track.stop();
		}

		store.dispatch(stateActions.setWebcamInProgress(false));
	}

	async disableWebcam() {
		try {
			logger.debug('disableWebcam()');

			if (!this._webcamProducer) {
				logger.error('disableWebcam() | cannot produce video');
				return;
			}
			this._webcamProducer.close();

			store.dispatch(stateActions.removeProducer(this._webcamProducer.id));

			await this._protoo.request('closeProducer', { producerId: this._webcamProducer.id });
			endVirtualSegmentation();

		} catch (error) {
			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error closing server-side webcam Producer: ${error}`
			}));
		}

		this._webcamProducer = null;
	}

	async updateWebcamStream(vb, imageUrl) {
		try {
			logger.debug("updateWebcamStream()");
			// endVirtualSegmentation();
			if (this._webcamProducer.track) {
				await this.disableWebcam();
				return await this.enableWebcam(vb, imageUrl);
			}
			return;
		}
		catch (error) {
			logger.error('updateWebcamStream() | failed: %o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Could not change Webcam Stream: ${error}`
			}));
		}
	}

	async changeMic(deviceId) {
		logger.debug('changeMic()');
		try {

			if (!this._micProducer?.track) return await this.enableMic(deviceId);
			this._micProducer.track.stop();

			logger.debug('changeMic() | calling getUserMedia()');

			const stream = await navigator.mediaDevices.getUserMedia({
				audio: { deviceId, }
			});

			const track = stream.getAudioTracks()[0];
			// this._micProducer.track

			await this._micProducer.replaceTrack({ track });
			store.dispatch(stateActions.addProducer({
				id: this._micProducer.id,
				paused: this._micProducer.paused,
				track: this._micProducer.track,
				rtpParameters: this._micProducer.rtpParameters,
				codec: this._micProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
			}));

		} catch (error) {
			logger.error('changeMic() | failed: %o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Could not change mic: ${error}`
			}));
		}
	}

	async changeWebcam(deviceId) {
		try {
			logger.debug('changeWebcam()');

			store.dispatch(stateActions.setWebcamInProgress(true));

			await this._updateWebcams();
			// logger.debug('changeWebcam()1', this._webcams);

			const array = Array.from(this._webcams.keys());
			// const len = array.length;
			// const deviceId = this._webcam.device ? this._webcam.device.deviceId : undefined;
			let idx = array.indexOf(deviceId);

			// if (idx < len - 1)
			// 	idx++;
			// else
			// 	idx = 0;

			// logger.debug('changeWebcam()2', array, idx);
			this._webcam.device = this._webcams.get(array[idx]);

			logger.debug('changeWebcam() | new selected webcam [device:%o]', this._webcam.device);

			// Reset video resolution to HD.
			this._webcam.resolution = 'hd';

			if (!this._webcam.device)
				throw new Error('no webcam devices');

			// Closing the current video track before asking for a new one (mobiles do not like
			// having both front/back cameras open at the same time).
			if (!this._webcamProducer?.track) return await this.enableWebcam();
			if (this._webcamProducer.track) {
				await this.disableWebcam();
				return await this.enableWebcam()
			}
			this._webcamProducer.track.stop();

			logger.debug('changeWebcam() | calling getUserMedia()');

			const stream = await navigator.mediaDevices.getUserMedia(
				{
					video:
					{
						deviceId: { exact: this._webcam.device.deviceId },
						...VIDEO_CONSTRAINS[this._webcam.resolution]
					}
				});

			const track = stream.getVideoTracks()[0];

			await this._webcamProducer.replaceTrack({ track });

			store.dispatch(stateActions.setProducerTrack(this._webcamProducer.id, track));
		} catch (error) {
			logger.error('changeWebcam() | failed: %o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Could not change webcam: ${error}`
			}));
		}

		store.dispatch(stateActions.setWebcamInProgress(false));
	}

	async changeWebcamResolution() {
		try {
			logger.debug('changeWebcamResolution()');

			store.dispatch(stateActions.setWebcamInProgress(true));

			switch (this._webcam.resolution) {
				case 'qvga':
					this._webcam.resolution = 'vga';
					break;
				case 'vga':
					this._webcam.resolution = 'hd';
					break;
				case 'hd':
					this._webcam.resolution = 'qvga';
					break;
				default:
					this._webcam.resolution = 'hd';
			}

			logger.debug('changeWebcamResolution() | calling getUserMedia()');

			const stream = await navigator.mediaDevices.getUserMedia(
				{
					video:
					{
						deviceId: { exact: this._webcam.device.deviceId },
						...VIDEO_CONSTRAINS[this._webcam.resolution]
					}
				});

			const track = stream.getVideoTracks()[0];

			await this._webcamProducer.replaceTrack({ track });

			store.dispatch(stateActions.setProducerTrack(this._webcamProducer.id, track));
		}
		catch (error) {
			logger.error('changeWebcamResolution() | failed: %o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Could not change webcam resolution: ${error}`
			}));
		}

		store.dispatch(stateActions.setWebcamInProgress(false));
	}

	async enableShare() {
		let track;
		try {
			logger.debug('enableShare()');

			if (this._shareProducer)
				return;
			// else if (this._webcamProducer)
			// 	await this.disableWebcam();

			if (!this._mediasoupDevice?.canProduce('video')) {
				logger.error('enableShare() | cannot produce video');

				return;
			}

			store.dispatch(
				stateActions.setShareInProgress(true));


			logger.debug('enableShare() | calling getUserMedia()');

			let stream = await navigator.mediaDevices.getDisplayMedia(
				{
					audio: false,
					video:
					{
						// displaySurface: 'monitor',
						logicalSurface: true,
						cursor: true,
						// width: { max: 1920 },
						// height: { max: 1080 },
						width: { ideal: 4096 },
						height: { ideal: 2160 },
						frameRate: { max: 30 },
					}
				});

			// May mean cancelled (in some implementations).
			if (!stream) {
				store.dispatch(stateActions.setShareInProgress(true));

				return;
			}

			track = stream.getVideoTracks()[0];

			// let encodings;
			let codec;
			const codecOptions = { videoGoogleStartBitrate: 1000 };

			if (this._forceH264) {
				codec = this._mediasoupDevice.rtpCapabilities.codecs
					.find((c) => c.mimeType.toLowerCase() === 'video/h264');

				if (!codec) {
					throw new Error('desired H264 codec+configuration is not supported');
				}
			}
			else if (this._forceVP9) {
				codec = this._mediasoupDevice.rtpCapabilities.codecs
					.find((c) => c.mimeType.toLowerCase() === 'video/vp9');
				if (!codec) {
					throw new Error('desired VP9 codec+configuration is not supported');
				}
			}

			// if (this._useSharingSimulcast) {
			// 	// If VP9 is the only available video codec then use SVC.
			// 	const firstVideoCodec = this._mediasoupDevice
			// 		.rtpCapabilities
			// 		.codecs
			// 		.find((c) => c.kind === 'video');

			// 	if ((this._forceVP9 && codec) || firstVideoCodec.mimeType.toLowerCase() === 'video/vp9') {
			// 		encodings = SCREEN_SHARING_SVC_ENCODINGS;
			// 	} else {
			// 		encodings = SCREEN_SHARING_SIMULCAST_ENCODINGS
			// 			.map((encoding) => ({ ...encoding, dtx: true }));
			// 	}
			// }

			this._shareProducer = await this._sendTransport.produce(
				{
					track,
					// encodings, //commented because not working good with entire tab share
					codecOptions,
					codec,
					appData:
					{
						share: true
					}
				});

			if (this._e2eKey && e2e.isSupported()) {
				e2e.setupSenderTransform(this._shareProducer.rtpSender);
			}

			store.dispatch(stateActions.addProducer(
				{
					id: this._shareProducer.id,
					type: 'share',
					paused: this._shareProducer.paused,
					track: this._shareProducer.track,
					rtpParameters: this._shareProducer.rtpParameters,
					codec: this._shareProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
				}));

			this._shareProducer.on('transportclose', () => {
				this._shareProducer = null;
			});

			this._shareProducer.on('trackended', () => {
				// store.dispatch(requestActions.notify({
				// 	type: 'error',
				// 	text: 'Share disconnected!'
				// }));

				this.disableShare().catch(() => { });
			});
		}
		catch (error) {
			logger.error('enableShare() | failed:%o', error);

			if (error.name !== 'NotAllowedError') {
				store.dispatch(requestActions.notify({
					type: 'error',
					text: `Error sharing: ${error}`
				}));
			}

			if (track)
				track.stop();
		}

		store.dispatch(stateActions.setShareInProgress(false));
	}

	async disableShare() {
		try {
			logger.debug('disableShare()');

			if (!this._shareProducer)
				return;

			this._shareProducer.close();

			store.dispatch(stateActions.removeProducer(this._shareProducer.id));


			await this._protoo.request('closeProducer', { producerId: this._shareProducer.id });
		}
		catch (error) {
			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error closing server-side share Producer: ${error}`
			}));
		}

		this._shareProducer = null;
	}

	async enableAudioOnly() {
		try {

			logger.debug('enableAudioOnly()');

			store.dispatch(stateActions.setAudioOnlyInProgress(true));

			this.disableWebcam();

			for (const consumer of this._consumers.values()) {
				if (consumer.kind !== 'video')
					continue;

				this._pauseConsumer(consumer);
			}

			store.dispatch(stateActions.setAudioOnlyState(true));

			store.dispatch(stateActions.setAudioOnlyInProgress(false));
		} catch (error) {
			logger.error('enableAudioOnly()');
		}
	}

	async disableAudioOnly() {
		try {
			logger.debug('disableAudioOnly()');

			store.dispatch(stateActions.setAudioOnlyInProgress(true));

			if (!this._webcamProducer && this._produce && (cookiesManager.getDevices() || {}).webcamEnabled) {
				this.enableWebcam();
			}

			for (const consumer of this._consumers.values()) {
				if (consumer.kind !== 'video')
					continue;

				this._resumeConsumer(consumer);
			}

			store.dispatch(stateActions.setAudioOnlyState(false));

			store.dispatch(stateActions.setAudioOnlyInProgress(false));
		} catch (error) {
			logger.error('disableAudioOnly()');
		}
	}

	async muteAudio() {
		logger.debug('muteAudio()');

		store.dispatch(stateActions.setAudioMutedState(true));
	}

	async unmuteAudio() {
		logger.debug('unmuteAudio()');

		store.dispatch(stateActions.setAudioMutedState(false));
	}

	async restartIce() {
		logger.debug('restartIce()');

		store.dispatch(stateActions.setRestartIceInProgress(true));

		try {
			if (this._sendTransport) {
				const iceParameters = await this._protoo.request('restartIce', { transportId: this._sendTransport.id });

				await this._sendTransport.restartIce({ iceParameters });
			}

			if (this._recvTransport) {
				const iceParameters = await this._protoo.request('restartIce', { transportId: this._recvTransport.id });

				await this._recvTransport.restartIce({ iceParameters });
			}

			store.dispatch(requestActions.notify({
				text: 'ICE restarted'
			}));
		}
		catch (error) {
			logger.error('restartIce() | failed:%o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `ICE restart failed: ${error}`
			}));
		}

		store.dispatch(stateActions.setRestartIceInProgress(false));
	}

	async setMaxSendingSpatialLayer(spatialLayer) {
		logger.debug('setMaxSendingSpatialLayer() [spatialLayer:%s]', spatialLayer);

		try {
			if (this._webcamProducer)
				await this._webcamProducer.setMaxSpatialLayer(spatialLayer);
			else if (this._shareProducer)
				await this._shareProducer.setMaxSpatialLayer(spatialLayer);
		}
		catch (error) {
			logger.error('setMaxSendingSpatialLayer() | failed:%o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error setting max sending video spatial layer: ${error}`
			}));
		}
	}

	async setConsumerPreferredLayers(consumerId, spatialLayer, temporalLayer) {
		logger.debug('setConsumerPreferredLayers() [consumerId:%s, spatialLayer:%s, temporalLayer:%s]', consumerId, spatialLayer, temporalLayer);

		try {
			await this._protoo.request('setConsumerPreferredLayers', { consumerId, spatialLayer, temporalLayer });

			store.dispatch(stateActions.setConsumerPreferredLayers(consumerId, spatialLayer, temporalLayer));
		}
		catch (error) {
			logger.error('setConsumerPreferredLayers() | failed:%o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error setting Consumer preferred layers: ${error}`
			}));
		}
	}

	async setConsumerPriority(consumerId, priority) {
		logger.debug('setConsumerPriority() [consumerId:%s, priority:%d]', consumerId, priority);

		try {
			await this._protoo.request('setConsumerPriority', { consumerId, priority });

			store.dispatch(stateActions.setConsumerPriority(consumerId, priority));
		} catch (error) {
			logger.error('setConsumerPriority() | failed:%o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error setting Consumer priority: ${error}`
			}));
		}
	}

	async requestConsumerKeyFrame(consumerId) {
		logger.debug('requestConsumerKeyFrame() [consumerId:%s]', consumerId);

		try {
			await this._protoo.request('requestConsumerKeyFrame', { consumerId });

			store.dispatch(requestActions.notify({
				text: 'Keyframe requested for video consumer'
			}));
		} catch (error) {
			logger.error('requestConsumerKeyFrame() | failed:%o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error requesting key frame for Consumer: ${error}`
			}));
		}
	}

	async getSendTransportRemoteStats() {
		try {
			logger.debug('getSendTransportRemoteStats()');

			if (!this._sendTransport) return;

			return this._protoo.request('getTransportStats', { transportId: this._sendTransport.id });
		} catch (error) {
			logger.error('getSendTransportRemoteStats()', error)
		}
	}

	async getRecvTransportRemoteStats() {
		try {
			logger.debug('getRecvTransportRemoteStats()');

			if (!this._recvTransport) return;

			return this._protoo.request('getTransportStats', { transportId: this._recvTransport.id });
		} catch (error) {
			logger.error('getRecvTransportRemoteStats()', error);
		}
	}

	async getAudioRemoteStats() {
		try {
			logger.debug('getAudioRemoteStats()');

			if (!this._micProducer) return;

			return this._protoo.request('getProducerStats', { producerId: this._micProducer.id });
		} catch (error) {
			logger.error('getAudioRemoteStats()', error);

		}
	}

	async getVideoRemoteStats() {
		try {
			logger.debug('getVideoRemoteStats()');

			const producer = this._webcamProducer || this._shareProducer;

			if (!producer) return;

			return this._protoo.request('getProducerStats', { producerId: producer.id });
		} catch (error) {
			logger.error('getVideoRemoteStats()', error);
		}
	}

	async getConsumerRemoteStats(consumerId) {
		try {
			logger.debug('getConsumerRemoteStats()');

			const consumer = this._consumers.get(consumerId);

			if (!consumer) return;

			return this._protoo.request('getConsumerStats', { consumerId });
		} catch (error) {
			logger.debug('getConsumerRemoteStats()', error);
		}
	}

	async getSendTransportLocalStats() {
		try {
			logger.debug('getSendTransportLocalStats()');

			if (!this._sendTransport) return;

			return this._sendTransport.getStats();
		} catch (error) {
			logger.error('getSendTransportLocalStats()', error);
		}
	}

	async getRecvTransportLocalStats() {
		try {
			logger.debug('getRecvTransportLocalStats()');

			if (!this._recvTransport) return;

			return this._recvTransport.getStats();
		} catch (error) {
			logger.error('getRecvTransportLocalStats()', error);
		}
	}

	async getAudioLocalStats() {
		try {
			logger.debug('getAudioLocalStats()');

			if (!this._micProducer) return;

			return this._micProducer.getStats();
		} catch (error) {
			logger.error('getAudioLocalStats()', error);
		}
	}

	async getVideoLocalStats() {
		try {
			logger.debug('getVideoLocalStats()');

			const producer = this._webcamProducer || this._shareProducer;

			if (!producer) return;

			return producer.getStats();
		} catch (error) {
			logger.error('getVideoLocalStats()', error);
		}
	}

	async getConsumerLocalStats(consumerId) {
		try {
			const consumer = this._consumers.get(consumerId);

			if (!consumer) return;

			return consumer.getStats();
		} catch (error) {
			logger.error('getConsumerLocalStats()', error);
		}
	}

	async applyNetworkThrottle({ uplink, downlink, rtt, secret }) {
		logger.debug('applyNetworkThrottle() [uplink:%s, downlink:%s, rtt:%s]', uplink, downlink, rtt);

		try {
			await this._protoo.request('applyNetworkThrottle', { uplink, downlink, rtt, secret });
		} catch (error) {
			logger.error('applyNetworkThrottle() | failed:%o', error);

			store.dispatch(requestActions.notify({
				type: 'error',
				text: `Error applying network throttle: ${error}`
			}));
		}
	}

	async resetNetworkThrottle({ silent = false, secret }) {
		logger.debug('resetNetworkThrottle()');

		try {
			await this._protoo.request('resetNetworkThrottle', { secret });
		}
		catch (error) {
			if (!silent) {
				logger.error('resetNetworkThrottle() | failed:%o', error);

				store.dispatch(requestActions.notify({
					type: 'error',
					text: `Error resetting network throttle: ${error}`
				}));
			}
		}
	}

	async _joinRoom() {
		logger.debug('_joinRoom()');

		try {
			this._mediasoupDevice = new mediasoupClient.Device({ handlerName: this._handlerName });

			const routerRtpCapabilities = await this._protoo.request('getRouterRtpCapabilities');

			await this._mediasoupDevice.load({ routerRtpCapabilities });
			let ip = "";
			try {
				const response = await fetch('https://api.ipify.org?format=json');
				const data = await response.json();
				ip = data.ip;
			} catch (error) {
				console.error('Error fetching IP address:', error);
			}

			const ipNPort = await this._protoo.request('getServerIpAndPort', { ip });
			this._ipNPort = ipNPort
			if (!this._ipNPort.ip || !this._ipNPort?.port /* || !this._ipNPort?.lbIp */) return window.location.href = createRedirectPath('')

			// Create mediasoup Transport for sending (unless we don't want to produce).
			if (this._produce) {

				// NOTE: Stuff to play remote audios due to browsers' new autoplay policy.
				//
				// Just get access to the mic and DO NOT close the mic track for a while.
				// Super hack!
				try {
					// {
					const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
					const audioTrack = stream.getAudioTracks()[0];

					audioTrack.enabled = false;

					setTimeout(() => audioTrack.stop(), 120000);

					// }
				} catch (error) {
					// TODO: show info pop up for microphone
					logger.debug("Mic permission error", error);
					if (error?.message === "Permission denied") store.dispatch(stateActions.setMicAndCamPermissions());
				}
				const transportInfo = await this._protoo.request(
					'createWebRtcTransport',
					{
						forceTcp: this._forceTcp,
						producing: true,
						consuming: false,
						sctpCapabilities: this._useDataChannel
							? this._mediasoupDevice.sctpCapabilities
							: undefined
					});

				const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } = transportInfo;

				iceCandidates[0].ip =  /*  "127.0.0.1" // this._ipNPort?.lbIp// "150.242.12.69"//*//* "dev-webinar-media-s9oy8.sarv.com" // */this._ipNPort?.ip || "0.0.0.0"
				iceCandidates[0].address =  /*  "127.0.0.1" // this._ipNPort?.lbIp// "150.242.12.69"//*//* "dev-webinar-media-s9oy8.sarv.com" // */this._ipNPort?.ip || "0.0.0.0"
				// iceCandidates[0].port = 32766
				logger.debug("Producer after icsCandidates", iceCandidates)

				this._sendTransport = this._mediasoupDevice.createSendTransport({
					id,
					iceParameters,
					iceCandidates,
					dtlsParameters:
					{
						...dtlsParameters,
						// Remote DTLS role. We know it's always 'auto' by default so, if
						// we want, we can force local WebRTC transport to be 'client' by
						// indicating 'server' here and vice-versa.
						role: 'auto'
					},
					sctpParameters,
					iceServers: [],
					proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
					additionalSettings:
						{ encodedInsertableStreams: this._e2eKey && e2e.isSupported() }
				});

				this._sendTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
					this._protoo.request(
						'connectWebRtcTransport',
						{
							transportId: this._sendTransport.id,
							dtlsParameters
						})
						.then(callback)
						.catch(errback);
				});

				this._sendTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
					try {
						const { id } = await this._protoo.request(
							'produce',
							{
								transportId: this._sendTransport.id,
								kind,
								rtpParameters,
								appData
							});

						callback({ id });
					} catch (error) {
						errback(error);
					}
				});

				this._sendTransport.on('producedata', async ({ sctpStreamParameters, label, protocol, appData }, callback, errback) => {
					logger.debug('"producedata" event: [sctpStreamParameters:%o, appData:%o]', sctpStreamParameters, appData);

					try {
						const { id } = await this._protoo.request(
							'produceData',
							{
								transportId: this._sendTransport.id,
								sctpStreamParameters,
								label,
								protocol,
								appData
							});

						callback({ id });
					} catch (error) {
						errback(error);
					}
				});
			}

			// Create mediasoup Transport for receiving (unless we don't want to consume).
			if (this._consume) {
				const transportInfo = await this._protoo.request(
					'createWebRtcTransport',
					{
						forceTcp: this._forceTcp,
						producing: false,
						consuming: true,
						sctpCapabilities: this._useDataChannel
							? this._mediasoupDevice.sctpCapabilities
							: undefined
					});

				const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } = transportInfo;

				iceCandidates[0].ip =  /*  "127.0.0.1" // this._ipNPort?.lbIp// "150.242.12.69"//*//* "dev-webinar-media-s9oy8.sarv.com" // */this._ipNPort?.ip || "0.0.0.0"
				iceCandidates[0].address =  /*  "127.0.0.1" // this._ipNPort?.lbIp// "150.242.12.69"//*//* "dev-webinar-media-s9oy8.sarv.com" // */this._ipNPort?.ip || "0.0.0.0"
				// iceCandidates[0].port = 32766
				logger.debug("consumer icsCandidates", iceCandidates)

				this._recvTransport = this._mediasoupDevice.createRecvTransport({
					id,
					iceParameters,
					iceCandidates,
					dtlsParameters:
					{
						...dtlsParameters,
						// Remote DTLS role. We know it's always 'auto' by default so, if
						// we want, we can force local WebRTC transport to be 'client' by
						// indicating 'server' here and vice-versa.
						role: 'auto'
					},
					sctpParameters,
					iceServers: [],
					additionalSettings:
						{ encodedInsertableStreams: this._e2eKey && e2e.isSupported() }
				});

				this._recvTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
					this._protoo.request(
						'connectWebRtcTransport',
						{
							transportId: this._recvTransport.id,
							dtlsParameters
						})
						.then(callback)
						.catch(errback);
				});
			}

			// Join now into the room.
			// NOTE: Don't send our RTP capabilities if we don't want to consume.
			let { peers, pipePeers, totalWatching, whiteBoardStatus, whiteboardAccess } = await this._protoo.request(
				'join',
				{
					displayName: this._displayName,
					device: this._device,
					rtpCapabilities: this._consume
						? this._mediasoupDevice.rtpCapabilities
						: undefined,
					sctpCapabilities: this._useDataChannel && this._consume
						? this._mediasoupDevice.sctpCapabilities
						: undefined
				});

			peers = [...peers, ...pipePeers];

			store.dispatch(stateActions.totalWatching(totalWatching));
			store.dispatch(stateActions.udpateWhitebaordOpenStatus(whiteBoardStatus));
			store.dispatch(stateActions.updateWhiteboardAccess({ whiteboardAccess }));

			// if (!this._produce && !peers.length) {
			// 	peers = pipePeers
			// }

			store.dispatch(stateActions.setRoomState('connected'));

			// Clean all the existing notifcations.
			store.dispatch(stateActions.removeAllNotifications());

			// Connect with sse for pin focus or other events.
			SSE_connect_events(this._peerId, store, this._roomId);

			// store.dispatch(requestActions.notify(
			// 	{
			// 		text: 'You are in the room!',
			// 		timeout: 3000
			// 	}));

			for (const peer of peers) {
				store.dispatch(
					stateActions.addPeer(
						{ ...peer, consumers: [], dataConsumers: [] }));
			}

			// Enable mic/webcam.
			if (this._produce) {
				// Set our media capabilities.
				store.dispatch(stateActions.setMediaCapabilities(
					{
						canSendMic: this._mediasoupDevice.canProduce('audio'),
						canSendWebcam: this._mediasoupDevice.canProduce('video')
					}));

				// this.enableMic();

				const devicesCookie = cookiesManager.getDevices();

				// Auto enable media if get try in branding from brand settings
				const cookiesBranding = localStorage.getItem(LOCAL_STORAGE?.BRANDING);
				let meetMedia;
				if (cookiesBranding) {
					const { meetMedia: mediaSettings = false } = JSON.parse(cookiesBranding || "{}") || {};
					meetMedia = mediaSettings;
				}

				if (!devicesCookie || devicesCookie.webcamEnabled || this._externalVideo || meetMedia)
					this.enableWebcam();

				if (!devicesCookie || devicesCookie.audioEnabled || meetMedia)
					this.enableMic();

				// commented no use of chat code
				// this._sendTransport.on('connectionstatechange', (connectionState) => {
				// 	if (connectionState === 'connected') {
				// 		// this.enableChatDataProducer();
				// 		// this.enableBotDataProducer();
				// 	}
				// });
			}

			// NOTE: For testing.
			if (window.SHOW_INFO) {
				const { me } = store.getState();
				store.dispatch(stateActions.setRoomStatsPeerId(me.id));
			}

			//ws connection handling
			setInterval(async () => {
				try {
					await this._protoo.request('ping');
				} catch (error) {
					logger.error("ping event response", error)
				}
			}, 30000);

			// Network Stats logging
			this._networkStatIntervalId = setInterval(() => {
				checkNetworkStats(this, stateActions.updateNetworkStrength);
			}, 30000);

			const { id = "", url = "" } = await this._protoo.request('initialPdfUrl');
			if (id && url) {
				store.dispatch(stateActions.updatePDFUrl({ id, url }));
			}
		}
		catch (error) {
			logger.error('_joinRoom() failed:%o', error);

			store.dispatch(requestActions.notify(
				{
					type: 'error',
					text: `Could not join the room: ${error}`
				}));

			this.close();
		}
	}

	async _updateWebcams() {
		try {
			logger.debug('_updateWebcams()');

			// Reset the list.
			this._webcams = new Map();

			logger.debug('_updateWebcams() | calling enumerateDevices()');

			const devices = await navigator.mediaDevices.enumerateDevices();

			for (const device of devices) {
				if (device.kind !== 'videoinput') continue;
				this._webcams.set(device.deviceId, device);
			}

			const array = Array.from(this._webcams.values());
			const len = array.length;
			const currentWebcamId = this._webcam.device ? this._webcam.device.deviceId : undefined;

			logger.debug('_updateWebcams() [webcams:%o]', array);

			if (len === 0)
				this._webcam.device = null;
			else if (!this._webcams.has(currentWebcamId))
				this._webcam.device = array[0];

			store.dispatch(stateActions.setCanChangeWebcam(this._webcams.size > 1));
		} catch (error) {
			logger.error('_updateWebcams()', error);
		}
	}
	// async _updateMics() {
	// 	logger.debug('_updateMics()');

	// 	// Reset the list.
	// 	this._webcams = new Map();

	// 	logger.debug('_updateMics() | calling enumerateDevices()');

	// 	const devices = await navigator.mediaDevices.enumerateDevices();

	// 	for (const device of devices) {
	// 		if (device.kind !== 'videoinput') continue;
	// 		this._webcams.set(device.deviceId, device);
	// 	}

	// 	const array = Array.from(this._webcams.values());
	// 	const len = array.length;
	// 	const currentWebcamId = this._webcam.device ? this._webcam.device.deviceId : undefined;

	// 	logger.debug('_updateMics() [webcams:%o]', array);

	// 	if (len === 0)
	// 		this._webcam.device = null;
	// 	else if (!this._webcams.has(currentWebcamId))
	// 		this._webcam.device = array[0];

	// 	store.dispatch(stateActions.setCanChangeWebcam(this._webcams.size > 1));
	// }

	_getWebcamType(device) {
		try {

			if (/(back|rear)/i.test(device.label)) {
				logger.debug('_getWebcamType() | it seems to be a back camera');

				return 'back';
			} else {
				logger.debug('_getWebcamType() | it seems to be a front camera');

				return 'front';
			}
		} catch (error) {
			logger.error('_getWebcamType() | it seems to be a back camera', error);
		}
	}

	async _pauseConsumer(consumer) {
		if (consumer?.paused) return;

		try {
			await this._protoo.request('pauseConsumer', { consumerId: consumer.id });

			consumer.pause();

			store.dispatch(stateActions.setConsumerPaused(consumer.id, 'local'));
		} catch (error) {
			logger.error('_pauseConsumer() | failed:%o', error);

			store.dispatch(requestActions.notify(
				{
					type: 'error',
					text: `Error pausing Consumer: ${error}`
				}));
		}
	}

	async _resumeConsumer(consumer) {
		if (!consumer.paused) return;

		try {
			await this._protoo.request('resumeConsumer', { consumerId: consumer.id });

			consumer.resume();

			store.dispatch(stateActions.setConsumerResumed(consumer.id, 'local'));
		} catch (error) {
			logger.error('_resumeConsumer() | failed:%o', error);

			store.dispatch(requestActions.notify(
				{
					type: 'error',
					text: `Error resuming Consumer: ${error}`
				}));
		}
	}

	async _getExternalVideoStream() {
		try {

			if (this._externalVideoStream)
				return this._externalVideoStream;

			if (this._externalVideo.readyState < 3) {
				await new Promise((resolve) => (
					this._externalVideo.addEventListener('canplay', resolve)
				));
			}

			if (this._externalVideo.captureStream)
				this._externalVideoStream = this._externalVideo.captureStream();
			else if (this._externalVideo.mozCaptureStream)
				this._externalVideoStream = this._externalVideo.mozCaptureStream();
			else
				throw new Error('video.captureStream() not supported');

			return this._externalVideoStream;
		} catch (error) {
			logger.error("_getExternalVideoStream()", error);
		}
	}

	async enableBotDataProducer() {
		logger.debug("enableBotDataProducer()");

		if (!this._useDataChannel) return;

		// NOTE: Should enable this code but it's useful for testing.
		// if (this._botDataProducer)
		// 	return;

		try {
			// Create chat DataProducer.
			this._botDataProducer = await this._sendTransport.produceData({
				ordered: false,
				maxPacketLifeTime: 2000,
				label: "bot",
				priority: "medium",
				appData: { info: "my-bot-DataProducer" },
			});

			//   store.dispatch(
			// 	stateActions.addDataProducer({
			// 	  id: this._botDataProducer.id,
			// 	  sctpStreamParameters: this._botDataProducer.sctpStreamParameters,
			// 	  label: this._botDataProducer.label,
			// 	  protocol: this._botDataProducer.protocol,
			// 	})
			//   );

			this._botDataProducer.on("transportclose", () => {
				this._botDataProducer = null;
			});

			this._botDataProducer.on("open", () => {
				logger.debug('bot DataProducer "open" event');
			});

			this._botDataProducer.on("close", () => {
				logger.error('bot DataProducer "close" event');

				this._botDataProducer = null;

				// store.dispatch(
				//   requestActions.notify({
				// 	type: "error",
				// 	text: "Bot DataProducer closed",
				//   })
				// );
			});

			this._botDataProducer.on("error", (error) => {
				logger.error('bot DataProducer "error" event:%o', error);

				// store.dispatch(
				//   requestActions.notify({
				// 	type: "error",
				// 	text: `Bot DataProducer error: ${error}`,
				//   })
				// );
			});

			this._botDataProducer.on("bufferedamountlow", () => {
				logger.debug('bot DataProducer "bufferedamountlow" event');
			});
		} catch (error) {
			logger.error("enableBotDataProducer() | failed:%o", error);

			//   store.dispatch(
			// 	requestActions.notify({
			// 	  type: "error",
			// 	  text: `Error enabling bot DataProducer: ${error}`,
			// 	})
			//   );

			throw error;
		}
	}

	async promoteAttendeeToHost() {
		try {
			logger.debug("PromoteAttendeeToHost()");

			this._protoo.request('promotePeer', {})
				.then((res) => logger.debug(res))
				.catch((err) => logger.error("Error on promoteAttendeeToHost promotePeer evnt", err));

			// NOTE: Stuff to play remote audios due to browsers' new autoplay policy.
			//
			// Just get access to the mic and DO NOT close the mic track for a while.
			// Super hack!
			try {
				// {
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				const audioTrack = stream.getAudioTracks()[0];

				audioTrack.enabled = false;

				setTimeout(() => audioTrack.stop(), 120000);

				// }
			} catch (error) {
				logger.debug("Mic permission error", error);
			}

			const transportInfo = await this._protoo.request('createWebRtcTransport',
				{
					forceTcp: this._forceTcp,
					producing: true,
					consuming: false,
					sctpCapabilities: this._useDataChannel ? this._mediasoupDevice.sctpCapabilities : undefined
				});

			const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } = transportInfo;

			iceCandidates[0].ip = this._ipNPort?.ip || "0.0.0.0"
			iceCandidates[0].address = this._ipNPort?.ip || "0.0.0.0"
			logger.debug("Producer after icsCandidates", iceCandidates)

			this._sendTransport = this._mediasoupDevice.createSendTransport({
				id,
				iceParameters,
				iceCandidates,
				dtlsParameters:
				{
					...dtlsParameters,
					// Remote DTLS role. We know it's always 'auto' by default so, if
					// we want, we can force local WebRTC transport to be 'client' by
					// indicating 'server' here and vice-versa.
					role: 'auto'
				},
				sctpParameters,
				iceServers: [],
				proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
				additionalSettings:
					{ encodedInsertableStreams: this._e2eKey && e2e.isSupported() }
			});

			this._sendTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
				this._protoo.request(
					'connectWebRtcTransport',
					{
						transportId: this._sendTransport.id,
						dtlsParameters
					})
					.then(callback)
					.catch(errback);
			});

			this._sendTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
				try {
					const { id } = await this._protoo.request(
						'produce',
						{
							transportId: this._sendTransport.id,
							kind,
							rtpParameters,
							appData: { ...appData }
						});

					callback({ id });
				} catch (error) {
					errback(error);
				}
			});

			this._sendTransport.on('producedata', async ({ sctpStreamParameters, label, protocol, appData }, callback, errback) => {
				logger.debug('"producedata" event: [sctpStreamParameters:%o, appData:%o]', sctpStreamParameters, appData);

				try {
					const { id } = await this._protoo.request(
						'produceData',
						{
							transportId: this._sendTransport.id,
							sctpStreamParameters,
							label,
							protocol,
							appData
						});

					callback({ id });
				} catch (error) {
					errback(error);
				}
			});

			store.dispatch(stateActions.setMediaCapabilities(
				{
					canSendMic: this._mediasoupDevice.canProduce('audio'),
					canSendWebcam: this._mediasoupDevice.canProduce('video')
				}));

		} catch (error) {
			logger.error("PromoteAttendeeToHost()", error);
		}
	}

	async demoteRoleToAttendee() {
		try {
			logger.debug("demoteRoleToAttendee()");

			this._protoo.request('demotePeer', {})
				.then((res) => logger.debug(res))
				.catch((err) => logger.error("Error on demoteRoleToAttendee demotePeer evnt", err));
			this.disableMic();
			this.disableWebcam();
		} catch (error) {
			logger.error("demoteRoleToAttendee()", error);
		}
	}

	async sendWhiteBoardData(data) {
		try {
			logger.debug("sendWhiteBoardData()");

			this._protoo.request('whiteboard', JSON.stringify(data))
				.then((res) => logger.debug(res))
				.catch((err) => logger.error("Error on sendWhiteBoardData evnt", err));
		} catch (error) {
			logger.error("sendWhiteBoardData()", error);
		}
	}

	async updatePDFUrl(data) {
		try {
			logger.debug("updatePDFUrl()");

			this._protoo.request('pdfUrl', data)
				.then((res) => logger.debug(res))
				.catch((err) => logger.error("Error on updatePDFUrl evnt", err));
		} catch (error) {
			logger.error("updatePDFUrl()", error);
		}
	}

	// async updatePDFPage(data) {
	// 	try {
	// 		logger.debug("updatePDFPage()");

	// 		this._protoo.request('pdfPage', data)
	// 			.then((res) => logger.debug(res))
	// 			.catch((err) => logger.error("Error on updatePDFPage evnt", err));
	// 	} catch (error) {
	// 		logger.error("updatePDFPage()", error);
	// 	}
	// }

	async initialWhiteBoardData(json) {
		try {
			logger.debug("sendWhiteBoardData()");
			const jsonToString = JSON.stringify(json)
			const data = await this._protoo.request('initialWhiteboardData', { jsonToString })
			console.log("1", data)
			return data;

		} catch (error) {
			logger.error("sendWhiteBoardData()", error);
			throw new Error("Unable to get data");
		}
	}

	async undoRedoWhiteboard(data) {
		try {
			logger.debug("undoRedoWhiteboard()");
			return await this._protoo.request('undoRedoWhiteboard', data)

		} catch (error) {
			logger.error("undoRedoWhiteboard()", error);
			store.dispatch(requestActions.notify({
				type: 'error',
				text: 'Unable to perform undo/redo operation!'
			}));
		}
	}

	async whiteboardScroll(data) {
		try {
			logger.debug("whiteboardScroll()");
			return await this._protoo.request('whiteboardScroll', data);
		} catch (error) {
			logger.error("whiteboardScroll()", error);
		}
	}

	async toggleWhiteboard(data) {
		try {
			logger.debug("toggleWhiteboard()");
			return await this._protoo.request('toggleWhiteboard', data);
		} catch (error) {
			logger.error("toggleWhiteboard()", error);
		}
	}

	async whiteboardAccess(data) {
		try {
			logger.debug("whiteboardAccess()");
			const { whiteboardAccess } = data;
			const accessType = whiteboardAccess ? "granted" : "revoked";
			await this._protoo.request('whiteboardAccess', data);
			return { err: false, message: `Whiteboard access has been successfully ${accessType}!` }
		} catch (error) {
			logger.error("whiteboardAccess()", error);
			return { err: true, message: "Access to the host cannot be modified!" }
		}
	}
}