import { useEffect } from 'react';
import { axiosPostCallWithStatusCode } from '../services/apisCall';
import Logger from './Logger';
import { decodeToken } from '../utils/common';
const logger = new Logger("accountSwitcher");


const AccountSwitcher = ({ childern }) => {
   const currentAccountId = window.location.pathname.split('/')[2] || '0';
   const switchAccount = async (accountId) => {
       try {
           const token = decodeToken();
           await axiosPostCallWithStatusCode(
               "switchAccount",
               { accountId, parentId: token?.parentUserId, parentSession: token?.parentSession },
           );


       } catch (error) {
           if (error.response && error.response.status === 404) {
               console.warn('Account not found, redirecting to account 0');
               window.location.href = "/u/0"
           } else {
               logger.error('Unexpected error during account switch.', error);
           }
       }
   };


   useEffect(() => {
       if (window.location.path !== "/") {
           switchAccount(currentAccountId);
       }
   }, [currentAccountId]);
   return childern;
};


export default AccountSwitcher;