import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as appPropTypes from './appPropTypes';
import { withRoomContext } from '../../../context/RoomContext';
import Me from './PeerView/Me';
import Peers from './PeerView/Peers';
import Notifications from '../../Notifications';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Grid from '@mui/system/Unstable_Grid';
import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// import MenuIcon from '@mui/icons-material/Menu';

// import * as requestActions from '../../../redux/actions/requestActions';

// import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
// import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

import { styled } from '@mui/material/styles';
import Participants from './Participants/Participants';
import ScreenShare from './ScreenShare/ScreenShare';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { IconButton, Typography, Modal } from '@mui/material';
import TotalWatching from './TotalWatchingAndInfo';
import Chat from './Chat';
import { renderErrorMessage } from '../../ToastNotification';
import SideBar from './SideBar';
import PermissionBlockModal from './Modal/PermissionBlockModal';
import NetworkIssueModal from './Modal/NetworkIssueModal';
import { axiosPostCall } from '../../../services/apisCall';
import { WaitingRoomContext } from '../../../context/waitingRoomContext';
import { VirtualBackgroundContext } from '../../../context/vbContext';
import WaitingRoom from './WaitingRoom';
import Recording from './Recording';
import { RecordContext } from '../../../context/recordContext';
import { WhiteBoardContext } from '../../../context/whiteboard';
import WhiteBoard from './WhiteBoard';
import VirtualBackground from './VirtualBackground/VirtualBackground';
import { createRedirectPath } from '../../../utils/common';

// import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// import ReactOwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { withStyles } from "@material-ui/core/styles"
// import Main from '../../../components/webinar/Main';
// import Chip from '@mui/material/Chip';
// import Box from '@mui/material/Box';
// import { Avatar } from '@mui/material';
// import img01 from '../../images/01.png'
// import Skeleton from '@mui/material/Skeleton';
// import { FixedSizeList as List } from "react-window";
// import InfiniteLoader from "react-window-infinite-loader";
// import clipboardCopy from 'clipboard-copy';

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginRight: 0/* -600 // drawerWidth */,
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginRight: 0// -300,
		}),
	}),
);

class Room extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			styleMainGrid: {
				width: '96.2%',
				height: '100vh',
				color: '#ffffff',
				backgroundColor: '#151515',
				display: 'flex',
				// alignItems: 'center',
				justifyContent: 'center',
				position: 'relative',
				margin: '0px',
				px: '5px',
				py: '5px',
				alignItems: 'stretch',
			},
			orderVideo: 2,
			dimensions: null,
			open: false,
			openChat: false,
			anchorElMore: null,
			openEndAll: false,
			fromPeers: 0,
			toPeers: 9,
			endSuccessLoading: false,
			transformOrigin: { horizontal: 'left', vertical: 'top' },
			anchorOrigin: { horizontal: 'right', vertical: 'top' },
			disableWebCamIcon: false,
			anchorElDevices: false,
			permissionModal: false,
			openWaitingRoomList: false,
			openRecording: false,
			openWhiteBoard: false,
			shareFullScreen: false,
			virtualBackground: false,
		}
	}

	handleDrawerClose = () => {
		this.setState({ open: false })
	};

	handleChatDrawerClose = () => {
		this.setState({ openChat: false })
	};

	handleDrawerOpen = () => {
		this.setState({ open: true, openChat: false, openWaitingRoomList: false, openWhiteBoard: false })
	};

	handleChatDrawerOpen = () => {
		this.setState({ openChat: true, open: false, openWaitingRoomList: false, openWhiteBoard: false })
	};

	handleClick = (event) => {
		this.setState({ anchorElMore: event.currentTarget })
	};
	handleClose = () => {
		this.setState({ anchorElMore: null })
	};

	// fn to set display peers page status for next.
	onNextPeersList = () => {
		this.setState({ fromPeers: this.state.toPeers, toPeers: this.state.toPeers + 9 })
	}

	// fn to set display peers page status for previous.
	onPrevPeersList = () => {
		this.setState({ fromPeers: this.state.fromPeers - 9, toPeers: this.state.toPeers - 9 })
	}

	// fn to end webinar for all peers
	endWebinar = async (e) => {
		e.preventDefault();
		this.setState({ endSuccessLoading: true });
		try {
			const { _roomId: roomId, _peerId: peerId } = this.props.roomClient;
			await axiosPostCall("endCall", { roomId, peerId })
			this.setState({ endSuccessLoading: false })
		} catch (error) {
			this.setState({ endSuccessLoading: false })
			console.error(error.message);
			renderErrorMessage(error.message)
		}
	}

	//fn to handle private chat redirect from panelist to chat direct.
	redirectToPrivateChat = (peerId) => {
		this.setState({ open: false, openChat: true, forceOpenChat: true, chatPeerId: peerId })
	}

	changeOrder = (order, styleMainGrid) => {
		this.setState({ orderVideo: order, styleMainGrid })
	}

	checkUserPermissions = async () => {
		try {
			await navigator.mediaDevices.getUserMedia({ audio: true });
			this.setState({ permissionModal: false })
		} catch (error) {
			this.setState({ permissionModal: true })
		}
	}

	openWaitingRoomlist = () => {
		this.setState({ open: false, openChat: false, openWaitingRoomList: true, openWhiteBoard: false })
	}

	closeWaitingRoomlist = () => {
		this.setState({ openWaitingRoomList: false })
	}

	openWhiteBoard = () => {
		this.setState({ open: false, openChat: false, openWaitingRoomList: false, openWhiteBoard: true })
	}

	closeWhiteBoard = () => {
		this.setState({ openWhiteBoard: false })
	}

	toggleRecording = (status) => {
		this.setState({ openRecording: status })
	}
    
	handleShareFullScreen = (shareFullScreen) => {
        this.setState({ shareFullScreen });
	}

	handleVirtualBackground = (virtualBackground) => {
		this.setState({ virtualBackground });
	}

	render() {
		const {
			roomClient,
			me,
			audioProducer,
			videoProducer,
			screenShare,
			theme,
			share,
			peersLength,
			isNewMessage,
			focus,
			roomState,
			promoteToHost,
		} = this.props;
		const { open, toPeers, openChat, forceOpenChat, chatPeerId, permissionModal, openRecording, openWaitingRoomList, openWhiteBoard, shareFullScreen, virtualBackground } = this.state;
		let micState;

		if (!me.canSendMic)
			micState = 'unsupported';
		// else if (!audioProducer)
		// 	micState = 'unsupported';
		else if (audioProducer && !audioProducer?.paused) {
			micState = 'on';
			// this.setState({ disableWebCamIcon: false })
		} else
			micState = 'off';

		let webcamState;

		if (!me.canSendWebcam)
			webcamState = 'unsupported';
		else if (videoProducer && videoProducer.type !== 'share')
			webcamState = 'on';
		else
			webcamState = 'off';

		let shareState;

		if (screenShare && screenShare.type === 'share')
			shareState = 'on';
		else
			shareState = 'off';

		// let changeWebcamState;

		// if (Boolean(videoProducer) && videoProducer.type !== 'share' && me.canChangeWebcam)
		// 	changeWebcamState = 'on';
		// else
		// 	changeWebcamState = 'unsupported';

		const DrawerHeader = styled('div')(({ theme }) => ({
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
			justifyContent: 'space-between',
			fontWeight: '600',
		}));

		return (
			<div data-component='Room'>
				<Box sx={{ display: 'flex' }}>
					<Main open={open || openChat} className='mainContainer'>
						<Notifications />
						<ToastContainer />
						<Grid container sx={{ bgcolor: '#000' }} ref={el => (this.container = el)}>
							<RecordContext.Provider value={{ toggleRecording: this.toggleRecording, openRecording: this.state.openRecording, record: this.props.record }}>
								<WaitingRoomContext.Provider value={{ openWaitingRoomlist: this.openWaitingRoomlist, closeWaitingRoomlist: this.closeWaitingRoomlist,  WaitingRoom:this.props.WaitingRoom,isWaitingRoom:this.props.isWaitingRoom }}>
									<WhiteBoardContext.Provider value={{ openWhiteBoard: this.openWhiteBoard, closeWhiteBoard: this.closeWhiteBoard }}>
										<VirtualBackgroundContext.Provider value={{ handleVirtualBackground: this.handleVirtualBackground,  }}>
											<SideBar
												roomClient={roomClient}
												micState={micState}
												webcamState={webcamState}
												shareState={shareState}
												screenShare={screenShare}
												openChat={openChat}
												isNewMessage={isNewMessage}
												open={open}
												dimensions={this.state.dimensions}
												changeOrder={this.changeOrder}
												handleDrawerOpen={this.handleDrawerOpen}
												handleDrawerClose={this.handleDrawerClose}
												handleChatDrawerClose={this.handleChatDrawerClose}
												handleChatDrawerOpen={this.handleChatDrawerOpen}
												closeChatTab={() => this.setState({ openChat: false })}
												handleVirtualBackground={this.handleVirtualBackground}
												roomState={roomState}
											/>
										</VirtualBackgroundContext.Provider>
									</WhiteBoardContext.Provider>
								</WaitingRoomContext.Provider>
							</RecordContext.Provider>

							{!permissionModal && <Grid container spacing={2} sx={{ ...this.state.styleMainGrid }} style={{ order: this.state.orderVideo }} id={"parentGridVid"}>
								{/* <Peers dimensions={this.state.dimensions} /> */}
								<RecordContext.Provider value={{ toggleRecording: this.toggleRecording, autoRecord: this.props.autoRecord, disableRecordPlayPause: this.props.disableRecordPlayPause }}>
									{openRecording && <Recording
										toggleRecording={this.toggleRecording}
									/>}
								</RecordContext.Provider>
								{share ?
									<Peers dimensions={this.state.dimensions} share={5} roomId={roomClient._roomId} role={roomClient?._role} shareFullScreen={shareFullScreen}/>
									:
									<>
										<Peers
											dimensions={this.state.dimensions}
											roomId={roomClient._roomId}
											fromPeers={this.state.fromPeers}
											toPeers={this.state.toPeers}
											role={roomClient?._role}
										/>
									</>

								}

								{(roomClient?._role === 'produce' || promoteToHost) &&
									<Me roomId={roomClient._roomId} micState={micState} />
								}

								{share && <ScreenShare roomId={roomClient._roomId} peerId={roomClient._peerId} shareFullScreen={shareFullScreen} handleShareFullScreen={this.handleShareFullScreen} />}

								{/* Show previus and next icon in main screen. */}
								{(peersLength > 9 && !share && !focus) && <>
									<Box sx={{
										position: 'absolute',
										zIndex: 9999999,
										left: 10,
										top: '50%'
									}}>
										{toPeers > 9 && <IconButton onClick={() => this.onPrevPeersList()} sx={{
											left: 0,
											bgcolor: '#454545',
											color: '#FFFFFF',
											'&:hover': {
												bgcolor: '#111111',
											}
										}}>
											<NavigateBeforeIcon />
										</IconButton>}
									</Box>
									<Box sx={{
										position: 'absolute',
										zIndex: 9999999,
										right: 10,
										top: '50%'
									}}>
										{peersLength > toPeers && <IconButton onClick={() => this.onNextPeersList()} sx={{
											right: 0,
											bgcolor: '#454545',
											color: '#FFFFFF',
											'&:hover': {
												bgcolor: '#111111',
											}
										}}>
											<NavigateNextIcon />
										</IconButton>}
									</Box>

								</>}
								{/* Show joined attendee count. */}
								<TotalWatching roomClient={roomClient} />
							</Grid>}
						</Grid>
					</Main>

					{open && <Participants
						open={open}
						drawerWidth={drawerWidth}
						theme={theme}
						handleDrawerClose={this.handleDrawerClose}
						redirectToPrivateChat={this.redirectToPrivateChat}
						// handleChange={this.handleChange}
						DrawerHeader={DrawerHeader}
						roomId={roomClient._roomId}
					/>}

					{openChat && <Chat
						open={openChat}
						drawerWidth={drawerWidth}
						theme={theme}
						handleDrawerClose={this.handleChatDrawerClose}
						// handleChange={this.handleChange}
						DrawerHeader={DrawerHeader}
						roomId={roomClient._roomId}
						peerId={roomClient._peerId}
						displayName={me?.displayName}
						share={shareState === 'on' ? true : false}
						forceOpenChat={forceOpenChat}
						data={chatPeerId}
						updateForceOpenChat={() => this.setState({ forceOpenChat: false })}
					/>}

					{openWaitingRoomList && <WaitingRoom
						open={true}
						drawerWidth={drawerWidth}
						theme={theme}
						handleDrawerClose={this.closeWaitingRoomlist}
						DrawerHeader={DrawerHeader}
					/>}

					{/* {<WhiteBoard
						open={true}
					/>} */}

					{openWhiteBoard && <WhiteBoard
						open={true}
						drawerWidth={drawerWidth}
						theme={theme}
						handleDrawerClose={this.closeWhiteBoard}
						DrawerHeader={DrawerHeader}
						order={this.state.orderVideo}
						styleMainGrid={this.state.styleMainGrid}
					/>}

					{virtualBackground && <VirtualBackground
						open={virtualBackground}
						handleClose={this.handleVirtualBackground}
						webcamState={webcamState}
					/>}
				</Box >

				<PermissionBlockModal
					open={permissionModal}
					handleClose={() => this.setState({ permissionModal: false })}
					selfRole={roomClient._role}
				/>

				{/* Network error modal */}
				<NetworkIssueModal
					networkError={this.props.networkError}
				/>

				{/* room closed modal */}
				<Modal
					open={this.props.webinarState}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: '#FFFFFF',
						boxShadow: 24,
						borderRadius: '10px',
						p: 4,
						"&:focus": {
							outline: "none"
						}
					}}>
						<Box sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							p: '10px',
						}}>
							<Typography variant="h6" component="h6" sx={{ color: '#454545', mt: 2 }}>
								This meeting has been closed
							</Typography>
							<Typography id="modal-modal-description" sx={{ color: '#757575', mt: 2 }}>
								Kindly contact to host or restart.
							</Typography>
							<Grid xs={12} sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								mt: 3,
								width: '100%'
							}}>
								<LoadingButton
									loading={null}
									color='primary'
									loadingPosition="center"
									variant="contained"
									onClick={() => window.location.href = createRedirectPath('')}
									sx={{
										ml: 1,
										borderRadius: '10px',
										width: '60%',
										textTransform: 'none',
									}}
								>
									Go to Home
								</LoadingButton>
							</Grid>
						</Box>
					</Box>
				</Modal>
			</div >

		);
	}

	componentDidMount() {
		const { roomClient } = this.props;

		roomClient.join();
		this.checkUserPermissions()
		this.setState({
			dimensions: {
				width: this.container.offsetWidth,
				height: this.container.offsetHeight,
			},
		});
	}

	componentDidUpdate() {
		const { focus } = this.props;
		const { fromPeers } = this.state;
		const { recordingStatus, openWboard } = this.props;
		if (openWboard) this.openWhiteBoard();
		if (!openWboard) this.closeWhiteBoard();
		if (["recording", "pause"].includes(recordingStatus) && !this.state.openRecording) this.toggleRecording(true);
		if (focus && fromPeers !== 0) { this.setState({ fromPeers: 0, toPeers: 6 }) }
	}
}

Room.propTypes =
{
	roomClient: PropTypes.any.isRequired,
	// room: appPropTypes.Room.isRequired,
	me: appPropTypes.Me.isRequired,
	amActiveSpeaker: PropTypes.bool.isRequired,
	// onRoomLinkCopy: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	const producersArray = Object.values(state.producers);
	const peersArray = Object.values(state.peers);
	const audioProducer = producersArray.find((producer) => producer.track.kind === 'audio');
	const videoProducer = producersArray.find((producer) => producer.track.kind === 'video' && producer?.type !== "share");
	const screenShareProducer = producersArray.find((producer) => producer.track.kind === 'video' && producer?.type === "share");

	const checkShare = Object.values(state.consumers).find(({ type }) => type === "share");
	const checkFocus = peersArray.find(({ isFocused }) => isFocused === true);

	const isNewMessage = state.publicChat?.newMsg ? true : state.privateChat?.newMsg ? true : false;
	const { promoteToHost } = state.media;
	const { status: recordingStatus } = state?.recording;
	const { record, autoRecord, disableRecordPlayPause, waitingRoom, isWaitingRoom } = state?.webinarInfo;
	const { openWboard } = state?.whiteboard;

	return {
		roomState: state.room?.state,
		networkError: state.room?.state === "disconnected" /* || state.room?.state === "closed" */ ? true : false,
		me: state.me,
		amActiveSpeaker: state.me.id === state.room.activeSpeakerId,
		audioProducer: audioProducer,
		videoProducer: videoProducer,
		screenShare: screenShareProducer,
		share: checkShare ? true : screenShareProducer ? true : null,
		focus: checkFocus ? true : false,
		peersLength: peersArray.length || 0,
		isNewMessage,
		webinarState: state.room?.webinarState === "closed" ? true : false,
		promoteToHost,
		isBt: state.webinarInfo?.isBt,
		recordingStatus,
		record,
		autoRecord,
		disableRecordPlayPause,
		openWboard,
		waitingRoom,
		isWaitingRoom
	};
};

const RoomContainer = withRoomContext(connect(
	mapStateToProps,
	/* null,
	null,
	{
		areStatesEqual: (next, prev) => {
			return (
				JSON.stringify(prev.producers) === JSON.stringify(next.producers) &&
				JSON.stringify(prev.peers) === JSON.stringify(next.peers) &&
				JSON.stringify(prev.consumers) === JSON.stringify(next.consumers) &&
				JSON.stringify(prev.publicChat) === JSON.stringify(next.publicChat) &&
				JSON.stringify(prev.privateChat) === JSON.stringify(next.privateChat) &&
				JSON.stringify(prev.media) === JSON.stringify(next.media) &&
				JSON.stringify(prev.recording) === JSON.stringify(next.recording) &&
				JSON.stringify(prev.webinarInfo) === JSON.stringify(next.webinarInfo)

			);
		}
	} */
)(Room));

export default memo(RoomContainer);
