import UrlParse from 'url-parse';
import React, { useEffect, useState ,useRef} from 'react';
import randomString from 'random-string';
import Logger from '../../../lib/Logger';
import deviceInfo from '../../../lib/deviceInfo';
import RoomClient from '../../../lib/RoomClient';
import RoomContext from '../../../context/RoomContext';
import * as stateActions from '../../../redux/actions/stateActions';
import Room from '../../../components/web/room/Room';
import store from '../../../store';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { refreshCookiesOnWebStart } from '../../../utils/common'
import { sseClose } from '../../../lib/Sse';
import { memo } from 'react';
import E2eModal from "../../../components/common/room/Modal/E2eModal";
import { createRedirectPath } from '../../../utils/common';
import { toast } from 'react-toastify';

// import randomName from '../../helper/randomName';
// import * as cookiesManager from '../../helper/cookiesManager';

const logger = new Logger();

let roomClient;
RoomClient.init({ store });

function Webinar() {
	const urlParser = new UrlParse(window.location.href, true);
	const location = useLocation();
	const [e2eModal, setE2eModal] = useState(true);
	const deviceWarningShown = useRef(false);
	const e2eeWarningShown = useRef(false);

	useEffect(() => {
		const handlePopState = (event) => {
				if (event.state) {
						console.debug('Browser back button pressed.');
						// Assuming roomClient.close() and sseClose() are defined somewhere
						roomClient.close();
						sseClose();
						window.location.href = createRedirectPath('');
				}
		};

		window.addEventListener('popstate', handlePopState, false);

		return () => {
				window.removeEventListener('popstate', handlePopState);
				console.debug('Webinar() useEffect component cleanup');
		};
}, []);

	logger.debug('Meeting() [environment:%s]', process.env.NODE_ENV);

	const protooUrl = location?.state?.protooUrl || null; // TODO: REMOVE NULL AND RETURN ERROR.

	const peerId = location?.state?.peerId || randomString({ length: 8 }).toLowerCase(); // TODO: REMOVE OR CONDITION AND SHOW ERROR
	let roomId = urlParser.query.roomId;
	let displayName = location?.state?.name;
	const handler = urlParser.query.handler;
	const useSimulcast = urlParser.query.simulcast !== 'false';
	const useSharingSimulcast = urlParser.query.sharingSimulcast !== 'false';
	const forceTcp = urlParser.query.forceTcp === 'true';
	const produce = urlParser.query.produce !== 'false';
	const consume = urlParser.query.consume !== 'false';
	const forceH264 = urlParser.query.forceH264 === 'true';
	const forceVP9 = urlParser.query.forceVP9 === 'true';
	const svc = urlParser.query.svc;
	const datachannel = urlParser.query.datachannel !== 'false';
	const info = urlParser.query.info === 'true';
	const faceDetection = urlParser.query.faceDetection === 'true';
	const externalVideo = urlParser.query.externalVideo === 'true';
	const throttleSecret = urlParser.query.throttleSecret;
	const isBt = location?.state?.bt;
	const e2ee = location?.state?.e2ee;
	const e2eKey = urlParser.query?.key || "";
	const role = location?.state?.role || 'consumer'; // TODO: remove during api integration
	const theme = useTheme();
	if (!protooUrl) return window.location.href = createRedirectPath('');

	if (info) {
		window.SHOW_INFO = true;
	}

	if (throttleSecret) {
		window.NETWORK_THROTTLE_SECRET = throttleSecret;
	}

	if (!roomId) {
		roomId = randomString({ length: 8 }).toLowerCase();

		urlParser.query.roomId = roomId;
		window.history.pushState('', '', urlParser.toString());
	}

	// Get the effective/shareable Room URL.
	const roomUrlParser = new UrlParse(window.location.href, true);

	for (const key of Object.keys(roomUrlParser.query)) {
		switch (key) {
			case 'roomId':
			case 'handler':
			case 'simulcast':
			case 'sharingSimulcast':
			case 'produce':
			case 'consume':
			case 'forceH264':
			case 'forceVP9':
			case 'forceTcp':
			case 'svc':
			case 'datachannel':
			case 'info':
			case 'faceDetection':
			case 'externalVideo':
			case 'throttleSecret':
			case 'e2eKey':
				break;

			default:
				delete roomUrlParser.query[key];
		}
	}
	delete roomUrlParser.hash;

	const roomUrl = roomUrlParser.toString();

	let displayNameSet;

	if (displayName) {
		displayNameSet = true;
	} else {
		displayNameSet = false;
		window.location.href = createRedirectPath('')
		// displayName = randomName();
	}

	refreshCookiesOnWebStart();

	// Get current device info.
	const device = deviceInfo();
	if (!device?.isSupported && !deviceWarningShown.current) {
		deviceWarningShown.current = true;
		setTimeout(() => {
				toast.warn(device?.isUnknown ? "Your browser is not recognized. Some features may not work properly!" : "Your browser is outdated. Some features may not work properly!", {
						position: "top-right",
						autoClose: 8000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "dark",
				});
		}, 5000);
}
if (e2ee && !device.isChrome && !e2eeWarningShown.current) {
	e2eeWarningShown.current = true;
	setTimeout(() => {
			toast.warn("End-to-end encryption is supported only on Chrome browsers.", {
					position: "top-right",
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
			});
	}, 5000);
}

	store.dispatch(stateActions.setRoomUrl(roomUrl));

	store.dispatch(stateActions.setRoomFaceDetection(faceDetection));

	store.dispatch(stateActions.setMe({ peerId, displayName, displayNameSet, device }));
  const currentPath = window.location.pathname;
  const userId = currentPath.split('/')[3]; 

	store.dispatch(stateActions.webinarInfo({
		title: location?.state?.title,
		description: location?.state?.description,
		url: `${window.location.origin}${createRedirectPath(`/join_meet?roomId=`)}${roomId}`,
		meetType: location?.state?.meetType,
		userId: location?.state?.peerId,
		isBt,
		record: location?.state?.record,
		autoRecord: location?.state?.autoRecord,
		disableRecordPlayPause: location?.state?.disableRecordPlayPause,
		e2ee: location?.state?.e2ee,
		waitingRoom: location?.state?.waitingRoom,
		isWaitingRoom:location?.state?.isWaitingRoom
	}));
     
	if (!e2ee || (e2ee && e2eKey)) {
		roomClient = new RoomClient(
			{
				roomId,
				peerId,
				displayName,
				device,
				handlerName: handler,
				useSimulcast,
				useSharingSimulcast,
				forceTcp,
				produce,
				consume,
				forceH264,
				forceVP9,
				svc,
				datachannel,
				externalVideo,
				e2ee,
				role,
				protooUrl,
				e2eKey
			});

		window.CLIENT = roomClient;
		window.CC = roomClient;
	}

	return (
		<>
			{
				e2ee ?
					<> {e2eKey ? <>
						<RoomContext.Provider value={roomClient}>
							<Room theme={theme} />
						</RoomContext.Provider></> :
						<E2eModal openModal={e2eModal} setOpenModal={setE2eModal}  initial={true}/>}</> :
					<>
						<RoomContext.Provider value={roomClient}>
							<Room theme={theme} />
						</RoomContext.Provider>
					</>
			}
		</>
	);
}

export default memo(Webinar)

// NOTE: Debugging stuff.

window.__sendSdps = function () {
	logger.warn('>>> send transport local SDP offer:');
	logger.warn(roomClient._sendTransport._handler._pc.localDescription.sdp);
	logger.warn('>>> send transport remote SDP answer:');
	logger.warn(roomClient._sendTransport._handler._pc.remoteDescription.sdp);
};

window.__recvSdps = function () {
	logger.warn('>>> recv transport remote SDP offer:');
	logger.warn(roomClient._recvTransport._handler._pc.remoteDescription.sdp);
	logger.warn('>>> recv transport local SDP answer:');
	logger.warn(roomClient._recvTransport._handler._pc.localDescription.sdp);
};

let dataChannelTestInterval = null;

window.__startDataChannelTest = function () {
	let number = 0;

	const buffer = new ArrayBuffer(32);
	const view = new DataView(buffer);

	dataChannelTestInterval = window.setInterval(() => {
		if (window.DP) {
			view.setUint32(0, number++);
			roomClient.sendChatMessage(buffer);
		}
	}, 100);
};

window.__stopDataChannelTest = function () {
	window.clearInterval(dataChannelTestInterval);

	const buffer = new ArrayBuffer(32);
	const view = new DataView(buffer);

	if (window.DP) {
		view.setUint32(0, Math.pow(2, 32) - 1);
		window.DP.send(buffer);
	}
};

window.__testSctp = async function ({ timeout = 100, bot = false } = {}) {
	let dp;

	if (!bot) {
		await window.CLIENT.enableChatDataProducer();

		dp = window.CLIENT._chatDataProducer;
	}
	else {
		await window.CLIENT.enableBotDataProducer();

		dp = window.CLIENT._botDataProducer;
	}

	logger.warn(
		'<<< testSctp: DataProducer created [bot:%s, streamId:%d, readyState:%s]',
		bot ? 'true' : 'false',
		dp.sctpStreamParameters.streamId,
		dp.readyState);

	function send() {
		dp.send(`I am streamId ${dp.sctpStreamParameters.streamId}`);
	}

	if (dp.readyState === 'open') {
		send();
	}
	else {
		dp.on('open', () => {
			logger.warn('<<< testSctp: DataChannel open [streamId:%d]', dp.sctpStreamParameters.streamId);
			send();
		});
	}

	setTimeout(() => window.__testSctp({ timeout, bot }), timeout);
};

setInterval(() => {
	if (window.CLIENT?._sendTransport) {
		window.H1 = window.CLIENT._sendTransport._handler;
		window.PC1 = window.CLIENT._sendTransport._handler._pc;
		window.DP = window.CLIENT._chatDataProducer;
	}
	else {
		delete window.PC1;
		delete window.DP;
	}

	if (window.CLIENT?._recvTransport) {
		window.H2 = window.CLIENT._recvTransport._handler;
		window.PC2 = window.CLIENT._recvTransport._handler._pc;
	}
	else {
		delete window.PC2;
	}
}, 2000);
