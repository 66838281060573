import React, { useEffect, useState } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Grid, Typography, Box, Modal } from "@mui/material";
import { loginWithGoogle } from '../../../services/commonApis';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { renderErrorMessage, renderSuccessMessage } from '../../../components/ToastNotification';
import { getItem } from '../../../lib/cookiesManager';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import { joinWebianrCheck } from '../../../services/commonApis';
import Logger from '../../../lib/Logger';
import { configFile, configJson as config } from '../../../config';
import { useSelector } from 'react-redux';
import { getConosleEnv, meetCode } from '../../../config/Enums';
import { createRedirectPath } from '../../../utils/common';

const logger = new Logger("MobileGoogleLogin");

export default function MobileLoginWithGoogle() {
    const { google_config, imagesUrl, logoUrl, consoleUrl } = configFile;
    const navigate = useNavigate();
    const location = useLocation();
    const [loginLoading, setLoginLoading] = useState(false)
    const [showLogin, setShowLogin] = useState(false);
    const isLandscape = useSelector(state => state.deviceInfo?.isLandscape)

    const consoleEnv = getConosleEnv();

    const hendleLoginSuccess = async ({ credential }) => {
        try {
            setLoginLoading(true);
            let res = await loginWithGoogle(credential);
            if (res) {
                renderSuccessMessage("Login successfully!");
                logger.debug("@GoogleLogin hendleLoginSuccess success:", true)
                if (location?.state) {
                    let { peerId, roomId } = location?.state;
                    try {
                        let token = getItem("token");
                        let pId = null;
                        let name = ""
                        if (token) {
                            let info = jwt(token);
                            pId = info?.email;
                            name = info?.name
                        }
                        if (!pId) pId = peerId;

                        let { redirectURL, state } = await joinWebianrCheck(roomId, name, pId) || {};
                        if (redirectURL) {
                            setLoginLoading(false);
                            logger.debug("@GoogleLogin hendleLoginSuccess redirectURL :", redirectURL);
                            return navigate(redirectURL, { state });
                        } else {
                            setLoginLoading(false);
                            logger.debug("@GoogleLogin hendleLoginSuccess Invalid link else roomId peerId pId:", roomId, peerId, pId)
                            window.location.href = createRedirectPath('')
                            return renderErrorMessage("Invalid link!");
                        }
                    } catch (error) {
                        setLoginLoading(false);
                        logger.debug("@GoogleLogin hendleLoginSuccess catch error:", error)
                        window.location.href = createRedirectPath('')
                        return renderErrorMessage(error?.message)
                    }
                }
                window.location.href = createRedirectPath('')
                setLoginLoading(false);
            }
        } catch (error) {
            setLoginLoading(false);
            logger.error("@GoogleLogin hendleLoginSuccess catch: ", error)
            renderErrorMessage(error?.message || "Internel server error!")
        }
    }

    const loginContentJsx = () => {
        return (
            <>
                <Box sx={{
                    pt: 5,
                }}>
                    <Typography variant='h5' fontWeight={'bold'} textAlign='center' color={'#454545'}>Login</Typography>
                    <Typography textAlign='center' color={'#777777'}>Start your journey with us</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 3,
                }}>
                    <GoogleOAuthProvider clientId={google_config.gClientID}>
                        <GoogleLogin
                            onSuccess={credentialResponse => hendleLoginSuccess(credentialResponse)}
                            onError={(err) => {
                                logger.error('@GoogleLogin onError Login Failed:', err);
                                renderErrorMessage(err?.message || "Login Failed!")
                            }}
                            type={"standard"}
                            theme={"outline"}
                            size={"large"}
                            // width={"550px"}
                            text={"signin_with"}
                        />
                    </GoogleOAuthProvider>
                </Box>
            </>
        )
    }

    useEffect(() => {
        if (consoleEnv.includes(process.env.REACT_APP_ENV)) return window.location.href =`${consoleUrl}/login?k=${meetCode}`;
        setShowLogin(true);
        let checkToken = getItem("sessionToken")
        if (checkToken) {
            let exp = getItem("exp");
            if (exp <= +new Date()) {
                return;
            }
            window.location.href = createRedirectPath('')
            // navigate("/")
        }
        return;
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {!showLogin ? <></> : <Box className='MobileWraper'>
                <ToastContainer />
                <Grid sx={{ height: '100vh' }}>
                    <Box className="ScrollBlock">
                        <Box className="LoginBlock" sx={{
                            background: config.mobileUI.baseColor,
                            borderRadius: '0 0 40px 40px',
                        }}>
                            <Box className='logo' sx={{
                                textAlign: 'center',
                                p: isLandscape ? 2 : 5,
                                pb: isLandscape ? 5 : 20,
                            }}>
                                <img src={logoUrl?.logoMobile} alt="logo" style={{ height: '100px' }} />
                            </Box>
                        </Box>
                        {isLandscape ?
                            <Box className='centerImage' sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 'calc(100% - 160px)'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '50%'
                                }} >
                                    <img src={imagesUrl?.loginPageUser} alt="login" style={{ maxWidth: '100%', width: '50%', height: '100%' }} />
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    width: '50%'
                                }} >
                                    {loginContentJsx()}
                                </Box>
                            </Box>
                            :
                            <Box className='centerImage' sx={{
                                textAlign: 'center',
                                position: 'relative',
                                top: '-140px',
                            }}>
                                <img src={imagesUrl?.loginPageUser} alt="login" style={{ maxWidth: '100%' }} />
                                {loginContentJsx()}
                            </Box>
                        }
                    </Box>
                </Grid>

                {/* Login loading modal */}
                <Modal
                    open={loginLoading}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        outline: 'none',
                    }}>
                        <img src={imagesUrl.loading} alt='Loading...' />
                    </Box>
                </Modal>
            </Box>}
        </>
    )
}
