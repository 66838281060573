import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Logger from '../../../../lib/Logger';
import * as appPropTypes from '../appPropTypes';
import { connect } from 'react-redux';
import Grid from '@mui/system/Unstable_Grid';
import { Box } from '@mui/material';
import { withRoomContext } from '../../../../context/RoomContext';
import hark from 'hark';
import NetworkIssueModal from '../Modal/NetworkIssueModal';
import DisplayName from './DisplayName';
import PeerTopBar from './PeerTopBar';
import UserAvatar from './UserAvatar';
import PinFocus from './PinFocus';
import { axiosPostCall } from '../../../../services/apisCall';
import { renderErrorMessage } from '../../../ToastNotification';


const logger = new Logger('PeerView');

class PeerView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			videoResolutionWidth: null,
			videoResolutionHeight: null,
			videoCanPlay: false,
			videoElemPaused: false,
			maxSpatialLayer: null,
			displayHeight: 0,
			peer_sx: {
				bgcolor: 'text.disabled',
				position: 'relative',
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				height: '100%',
				borderRadius: '10px',
				alignItems: 'stretch',
			},
			networkS: 1,
			networkError: false,
			stream: false
		};

		// Latest received video track.
		// @type {MediaStreamTrack}
		this._audioTrack = null;

		// Latest received video track.
		// @type {MediaStreamTrack}
		this._videoTrack = null;

		// Periodic timer for reading video resolution.
		this._videoResolutionPeriodicTimer = null;

		// requestAnimationFrame for face detection.
		this._faceDetectionRequestAnimationFrame = null;

		this.speakerId = ""
	}

	updateFocus = async (peerId, action, type) => {
		try {
			await axiosPostCall("pinScreen", { roomId: this.props.roomId, peerId, action, type })
		} catch (error) {
			logger.error("@PeerView.jsx updateFocus ", error);
			renderErrorMessage(error.message)
		}
	}

	render() {
		const {
			isMe,
			peer,
			audioMuted,
			videoVisible,
			peerCount,
			audioEnabled,
			checkShare,
			focusedSX,
			sx12,
			focusCount,
			showFocusSide,
			role,
			micState = false,
			isPined,
			isFocused,
			videoScore,
			networkStrength,
		} = this.props;
		const { videoCanPlay, videoElemPaused, networkS, stream } = this.state;


		// // Ensure videoSource is an array or default to an empty array
		// const videoScores = Array.isArray(videoScore)
		// 	? videoScore.map(source => source?.score || 0)
		// 	: [];
		// 	console.log('Video Scores Array:', videoScores);

		// // Get the lowest score, default to Infinity if no scores are available
		// const effectiveVideoScore = videoScores.length ? Math.min(...videoScores) : 0;
		// console.log("effectiveVideoScore", effectiveVideoScore)

		const normalizeVideoScore = (videoScore) => {
			if (Array.isArray(videoScore)) {
				return videoScore.map(source => source?.score || 0);
			}
			if (typeof videoScore === 'object' && videoScore !== null) {
				return [videoScore.score || 0];
			}
			return [];
		};
		const videoScores = normalizeVideoScore(videoScore);
		const effectiveVideoScore = videoScores.length ? Math.min(...videoScores) : 0;

		// console.log('Effective Video Score:', effectiveVideoScore);
		// Set low network message


		if (peer) {
			console.log("isme 1", peer)
			peer.render = true
		}

		let is_me_sx = {
			bgcolor: 'text.disabled',
			position: 'absolute',
			backgroundSize: 'cover',
			height: '150px',
			width: '250px',
			borderRadius: '10px',
			right: '0px',
			bottom: '0px',
		}

		let xs = 12;
		if (peerCount >= 2 && peerCount < 5) {
			xs = 6
		} else if (peerCount >= 5) {
			xs = 4
		} else {
			xs = 12
		}

		let sxAll = {}
		sxAll.padding = '5px';
		sxAll.alignItems = 'stretch'
		sxAll.height = '100%'
		if (!(sx12 || peer?.isFocused)) {
			if (peerCount < 2 && !checkShare) {
				sxAll.height = '100%'
			} else if (peerCount > 2 && peerCount < 7 && !checkShare) {
				sxAll.height = '50%'
			} else if (peerCount > 6 && !checkShare) {
				sxAll.height = '33.34%'
			}
		} else if (sx12) {
			sxAll.height = '22%'
		} else if (!sx12 && peer?.isFocused) {
			if (focusCount < 2) {
				sxAll.height = '100%'
			} else if (focusCount > 2 && focusCount < 7) {
				sxAll.height = '50%'
			} else if (focusCount > 6) {
				sxAll.height = '33.34%'
			}
		}

		let fCountSX = 12;
		if (focusCount < 2) {
			fCountSX = 12
		} else if (focusCount > 1 && focusCount < 5) {
			fCountSX = 6
		} else if (focusCount >= 5) {
			fCountSX = 4
		}

		if (isPined) {
			peer.isPined = true
		}

		if (isFocused) {
			peer.isFocused = true
		}

		return (
			<>
				<Grid xs={(peer?.isFocused || sx12) && !checkShare ? (sx12 ? 12 : fCountSX) : (checkShare ? (isMe ? 12 : 2) : xs)} sx={sxAll} >
					<Box
						sx={isMe ? is_me_sx : ((peer?.isFocused && focusedSX) ? focusedSX : this.state.peer_sx)} className={`video-container ${isMe && "meHover"}`}>
						<video
							ref='videoElem'
							width={isMe ? "250" :  /* (this.state.displayHeight < dimensions?.height) ? '94%' : */ '100%'}
							height={isMe ? '150' :/*  (this.state.displayHeight < dimensions?.height) ? '99%' :  '100%' */ '100%'}
							className={`${(
								(!videoVisible || !videoCanPlay || (!isMe && (effectiveVideoScore < 3)))
							) ? 'hideVideo' : 'show'} is_me_video`}							// className={classnames({
							// 	'is-me': isMe,
							// 	hidden: !videoVisible || !videoCanPlay,
							// 	'network-error': (
							// 		videoVisible && videoMultiLayer && consumerCurrentSpatialLayer === null
							// 	)
							// })}
							autoPlay
							playsInline
							muted
							controls={false}
						/>

						<audio
							ref='audioElem'
							autoPlay
							playsInline
							muted={isMe || audioMuted}
							controls={false}
						/>

						{videoVisible && videoScore < 5 &&
							<UserAvatar isMe={isMe} />
						}
						{((!videoVisible || !videoCanPlay) || (!isMe && (effectiveVideoScore < 3))) &&
							<>
								<UserAvatar isMe={isMe} />
								<div style={{
									position: 'absolute',
									bottom: '10px',
									left: '50%',
									transform: 'translateX(-50%)',
									color: '#fff',
									backgroundColor: 'rgba(0, 0, 0, 0.7)',
									padding: '5px 10px',
									borderRadius: '5px',
									fontSize: '14px',
									textAlign: 'center'
								}}>
									Video is paused due to low network.
								</div>
							</>
						}


						{((!videoVisible || !videoCanPlay) /* && !screenShare */) &&
							<UserAvatar isMe={isMe} />
						}

						{videoElemPaused &&
							<UserAvatar isMe={isMe} />
							// <div className='video-elem-paused' />
						}

						<DisplayName isMe={isMe} displayName={peer?.displayName} />

						{/* Show pined icon and audio status */}
						<PeerTopBar
							isMe={isMe}
							networkS={networkS}
							networkStrength={networkStrength}
							isPined={peer?.isPined}
							audioEnabled={audioEnabled}
							micState={micState}
							stream={stream}
						/>

						{/* Pin and focus icons */}
						{(role !== 'consumer') &&
							<PinFocus
								isMe={isMe}
								isPined={peer?.isPined}
								isFocused={peer?.isFocused}
								id={peer?.id}
								updateFocus={this.updateFocus}
								showFocusSide={showFocusSide}
							/>
						}
					</Box>
				</Grid>

				<NetworkIssueModal
					networkError={this.state.networkError}
				/>
			</>

		);
	}

	componentDidMount() {
		const { audioTrack, videoTrack, speaker } = this.props;

		this._setTracks(audioTrack, videoTrack, speaker);
		// let conn = navigator.connection || navigator.mozConnection || navigator.webkitConnection || null
		window.addEventListener('online', () => this.setState({ networkS: 1/* , networkDownLink: conn?.downLink */ }))
		window.addEventListener('offline', () => this.setState({ networkS: 0, networkError: true/* , networkDownLink: conn?.downLink  */ }))
		// console.log("conn", conn, conn?.downLink, {...conn}, conn?.NetworkInformation)
		// navigator.connection.addEventListener('change', (Event) => {
		// 	console.log(",",Event);
		//   });
	}

	componentWillUnmount() {
		clearInterval(this._videoResolutionPeriodicTimer);
		cancelAnimationFrame(this._faceDetectionRequestAnimationFrame);

		const { videoElem } = this.refs;

		if (videoElem) {
			videoElem.oncanplay = null;
			videoElem.onplay = null;
			videoElem.onpause = null;
		}
	}

	UNSAFE_componentWillUpdate() {
		const {
			isMe,
			audioTrack,
			videoTrack,
			videoRtpParameters,
			speaker,
		} = this.props;
		const { maxSpatialLayer } = this.state;
		// console.log(speaker)
		// window.addEventListener('online', () => this.setState({ networkS: 1 }))
		// window.addEventListener('offline', () => this.setState({ networkS: 0}))


		// const resizeObserver = new ResizeObserver((event) => {
		// 	// let widthParent = event[0].contentBoxSize[0].inlineSize;
		// 	let heightParent = event[0].contentBoxSize[0].blockSize;
		// 	this.setState({ displayHeight: heightParent })
		// });

		// resizeObserver.observe(document.getElementById("parentGridVid"));

		if (isMe && videoRtpParameters && maxSpatialLayer === null) {
			this.setState(
				{
					maxSpatialLayer: videoRtpParameters.encodings.length - 1
				});
		}
		else if (isMe && !videoRtpParameters && maxSpatialLayer !== null) {
			this.setState({ maxSpatialLayer: null });
		}

		this._setTracks(audioTrack, videoTrack, speaker);
	}

	async _setTracks(audioTrack, videoTrack, speakerId) {
		if (this._audioTrack === audioTrack && this._videoTrack === videoTrack && this.speakerId === speakerId?.speaker)
			return;
		if (this._audioTrack === audioTrack && this._videoTrack === videoTrack && this.speakerId !== speakerId?.speaker) {
			if (speakerId?.speaker) {
				logger.debug('audioTrack speaker change root:%o', speakerId?.speaker)
				const { audioElem } = this.refs;
				await audioElem.setSinkId(speakerId?.speaker);
				this.speakerId = speakerId?.speaker;
			}
			return;
		}


		this._audioTrack = audioTrack;
		this._videoTrack = videoTrack;

		this._stopVideoResolution();

		const { audioElem, videoElem } = this.refs;

		if (audioTrack) {
			logger.debug('audioTrack speaker:%o', speakerId)
			const stream = new MediaStream();

			stream.addTrack(audioTrack);
			audioElem.srcObject = stream;

			if (speakerId?.speaker) {
				logger.debug('audioTrack speaker change:%o', speakerId.speaker)
				await audioElem.setSinkId(speakerId.speaker);
				this.speakerId = speakerId?.speaker;
			}

			audioElem.play()
				.catch((error) => logger.warn('audioElem.play() failed:%o', error));

			this.setState({ stream })
			// this._runHark(stream);
		} else {
			audioElem.srcObject = null;
		}

		if (videoTrack) {
			const stream = new MediaStream();

			stream.addTrack(videoTrack);
			videoElem.srcObject = stream;

			videoElem.oncanplay = () => this.setState({ videoCanPlay: true });

			videoElem.onplay = () => {
				this.setState({ videoElemPaused: false });

				audioElem.play()
					.catch((error) => logger.warn('audioElem.play() With video failed:%o', error));
			};

			videoElem.onpause = () => this.setState({ videoElemPaused: true });

			videoElem.play()
				.catch((error) => logger.warn('videoElem.play() failed:%o', error));

			this._startVideoResolution();
		}
		else {
			videoElem.srcObject = null;
		}
	}

	_startVideoResolution() {
		this._videoResolutionPeriodicTimer = setInterval(() => {
			const { videoResolutionWidth, videoResolutionHeight } = this.state;
			const { videoElem } = this.refs;

			if (
				videoElem?.videoWidth !== videoResolutionWidth ||
				videoElem?.videoHeight !== videoResolutionHeight
			) {
				this.setState(
					{
						videoResolutionWidth: videoElem?.videoWidth,
						videoResolutionHeight: videoElem?.videoHeight
					});
			}
		}, 500);
	}

	_stopVideoResolution() {
		clearInterval(this._videoResolutionPeriodicTimer);

		this.setState(
			{
				videoResolutionWidth: null,
				videoResolutionHeight: null
			});
	}

	_runHark(stream) {
		if (!stream.getAudioTracks()[0])
			throw new Error('_runHark() | given stream has no audio track');

		this._hark = hark(stream, { play: false });

		// eslint-disable-next-line no-unused-vars
		this._hark.on('volume_change', (dBs, threshold) => {
			// The exact formula to convert from dBs (-100..0) to linear (0..1) is:
			//   Math.pow(10, dBs / 20)
			// However it does not produce a visually useful output, so let exagerate
			// it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
			// minimize component renderings.
			let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);

			if (audioVolume === 1)
				audioVolume = 0;

			if (audioVolume !== this.state.audioVolume)
				this.setState({ audioVolume });
		});
	}

}

PeerView.propTypes =
{
	isMe: PropTypes.bool,
	peer: PropTypes.oneOfType(
		[appPropTypes.Me, appPropTypes.Peer]).isRequired,
	videoRtpParameters: PropTypes.object,
	consumerCurrentSpatialLayer: PropTypes.number,
	audioTrack: PropTypes.any,
	videoTrack: PropTypes.any,
	audioMuted: PropTypes.bool,
	videoVisible: PropTypes.bool.isRequired,
	videoMultiLayer: PropTypes.bool,
	videoScore: PropTypes.any,
};

const mapStateToProps = (state) => {
	const producersArray = Object.values(state.producers);
	const screenShareProducer = producersArray.find((producer) => producer.track.kind === 'video' && producer?.type === "share");
	const checkShare = Object.values(state.consumers).find(({ type }) => type === "share");
	const { networkStrength } = state.networkStrength;
	return {
		checkShare: checkShare ? true : (screenShareProducer ? true : null),
		networkStrength,
	};
};

const PeerViewContainer = withRoomContext(connect(
	mapStateToProps,
)(PeerView));

export default memo(PeerViewContainer)