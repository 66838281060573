/* import { Menu, MenuItem, Box, Typography, IconButton } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { styled } from '@mui/material/styles';

const RecordingMenuItem = styled(MenuItem)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '230px',
}));

const RecordingBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderBottom: '1px',
    gap: '5px'
}));

const RecordingTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[200],
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
    },
}));

const RecordingMenu = ({ recordings, anchorEl, handleClose }) => (
    <Menu
        anchorEl={anchorEl}
        id="recording-menu"
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        onClick={() => handleClose()}
        PaperProps={{
            elevation: 0,
            sx: {
                backgroundColor: "grey.800",
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                '& .MuiAvatar-root': {
                    width: 230,
                    height: 180,
                },
                mt: 2,
                ml: 1.5,
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    bgcolor: 'grey.800',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                },
                marginTop: 0,
            }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
        {recordings.map(({ etag, size, donwloadLink }, index) => (
            <RecordingMenuItem
                key={etag}
                sx={{
                    borderBottom: recordings.length === index + 1 ? null : '1px solid',
                    borderColor: 'grey.700'
                }}>
                <RecordingBox>
                    <RecordingTypography sx={{ fontWeight: 500 }}>{`Recording ${index + 1}`}</RecordingTypography>
                    <RecordingTypography>{`(${size})`}</RecordingTypography>
                </RecordingBox>
                <IconButton
                    onClick={() => window.location.href = donwloadLink}
                    sx={{
                        backgroundColor: 'grey.600',
                        "&:hover": {
                            backgroundColor: 'grey.600',
                        }
                    }}>
                    <FileDownloadOutlinedIcon sx={{ color: "grey.100" }} />
                </IconButton>
            </RecordingMenuItem>
        ))}
    </Menu >
)

export default RecordingMenu; */



import { useState } from 'react';
import { Menu, MenuItem, Box, Typography, IconButton, Dialog, DialogContent } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { styled } from '@mui/material/styles';

const RecordingMenuItem = styled(MenuItem)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '230px',
}));

const RecordingBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderBottom: '1px',
    gap: '5px'
}));

const RecordingTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[200],
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
    },
}));

const RecordingMenu = ({ recordings, anchorEl, handleClose }) => {
    const [previewLink, setPreviewLink] = useState(null);

    const handlePreview = (link) => {
        setPreviewLink(link);
    };

    const handleClosePreview = () => {
        setPreviewLink(null);
    };

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                id="recording-menu"
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
                onClick={() => handleClose()}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        backgroundColor: "grey.800",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 2,
                        ml: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            bgcolor: 'grey.800',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            top: 0,
                            right: 15,
                            width: 10,
                            height: 10,
                        },
                        marginTop: 0,
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {recordings.map(({ etag, size, donwloadLink }, index) => (
                    <RecordingMenuItem
                        key={etag}
                        sx={{
                            borderBottom: recordings.length === index + 1 ? null : '1px solid',
                            borderColor: 'grey.700'
                        }}>
                        <RecordingBox>
                            <RecordingTypography sx={{ fontWeight: 500 }}>{`Recording ${index + 1}`}</RecordingTypography>
                            <RecordingTypography>{`(${size})`}</RecordingTypography>
                        </RecordingBox>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <IconButton
                                onClick={() => handlePreview(donwloadLink)}
                                sx={{
                                    backgroundColor: 'grey.600',
                                    "&:hover": {
                                        backgroundColor: 'grey.600',
                                    }
                                }}>
                                <PlayCircleOutlineIcon sx={{ color: "grey.100" }} />
                            </IconButton>
                            <IconButton
                    onClick={() => window.location.href = donwloadLink}
                    sx={{
                                    backgroundColor: 'grey.600',
                                    "&:hover": {
                                        backgroundColor: 'grey.600',
                                    }
                                }}>
                                <FileDownloadOutlinedIcon sx={{ color: "grey.100" }} />
                            </IconButton>
                        </Box>
                    </RecordingMenuItem>
                ))}
            </Menu>

            {/* Dialog for Video Preview */}
            <Dialog open={Boolean(previewLink)} onClose={handleClosePreview} maxWidth="md" fullWidth>
                <DialogContent
                sx={{
                    padding: 0, 
                    backgroundColor: 'black', 
                }}>
                    {previewLink && (
                        <video controls width="100%">
                            <source src={previewLink} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default RecordingMenu;

